import {Box, TableCell, TableRow} from "@mui/material";
import moment from "moment";
import {ReactComponent as DeleteIcon} from "../../assets/images/delete.svg";
import {deleteAlert} from "../../services/AlertService";
import {useNavigate} from "react-router-dom";

const AlertCard = ({alert}) => {

  let navigate = useNavigate()

  const handleDeleteAlert = () => {
    deleteAlert(alert.id)
        .then(() => {
          navigate(0);
        })
  }

  return (
      <TableRow
          sx={{"&.MuiTableRow-root" : {
              border: "1px solid #E7E9EE",
              height: "100px",
              backgroundColor: "#F9FAFB",
              cursor: "pointer",
              "&:hover" : {
                backgroundColor: "rgba(185,217,213,0.41)"
              },
              boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06)"
            }}}>
        <TableCell
            onClick={() => navigate(`/app/clients/${alert.traineeId}`)}
            align="right">
          {alert.traineeName}
        </TableCell>
        <TableCell
            onClick={() => navigate(`/app/clients/${alert.traineeId}`)}
            align="center">
          {alert.alert}
        </TableCell>
        <TableCell
            onClick={() => navigate(`/app/clients/${alert.traineeId}`)}
            align="center">
          {moment(moment.unix(alert.createdDate)).format("DD/MM/YY")}
        </TableCell>
        <TableCell align="left">
          <Box onClick={handleDeleteAlert}>
          <DeleteIcon/>
          </Box>
        </TableCell>
      </TableRow>
  )
};

export default AlertCard;
