import {useNavigate, useParams} from "react-router-dom";
import Loader from "../componenets/Loader";
import NoteDialog from "../componenets/client/NoteDialog";
import {Box, Typography} from "@mui/material";
import {ReactComponent as GreenPlusIcon} from "../assets/images/green_plus.svg";
import {ReactComponent as BackIcon} from "../assets/images/backIcon.svg";
import {ReactComponent as ForwordIcon} from "../assets/images/forward.svg";
import {saveClientNote} from "../services/TraineeService";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useEffect, useState} from "react";
import {useMessageContext} from "../contexts/MessageContext";
import {getPlan} from "../services/PlanSerice";
import ClientWorkoutChip from "../componenets/client/ClientWeekChip";
import WorkoutActiveTab from "../componenets/client/WorkoutActiveTab";

const ClientWeekPage = () => {
  const [newNote, setNewNote] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [planState, setPlanState] = useState();

  const {notificationHandler} = useMessageContext();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getPlan(params.userId, params.programId)
        .then(res => setPlanState(prv => {
          console.log(res.data);
          return {
            ...prv,
            plan: res.data,
            blockIdx: Number(params.blockIndex),
            weekIdx: Number(params.weekIndex),
          }
        }))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
  }, [])

  const handleNewNote = (note) => {
    const body = {
      traineeId: params.userId,
      message: note.message
    }
    saveClientNote(body)
        .then(() => setNewNote(false))
        .then(() => notificationHandler.success("פתק נשמר בהצלחה"))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
        })
  }

  const handleForward = () => {
    const curBlock = planState.plan.blocks.sort((a, b) => a.index > b.index ? 1 : -1)
        .at(planState.blockIdx);

    const curWeek = curBlock.weeks
        .sort((a, b) => a.index > b.index ? 1 : -1)
        .at(planState.weekIdx);

    const numOfWeeks = curBlock.weeks.length;
    const nomOfBlocks = planState.plan.blocks.length;

    if (curWeek.index < (numOfWeeks - 1)) {
      setPlanState(prv => {
        return {
          ...prv,
          weekIdx: prv.weekIdx + 1,
        }
      })
    } else if (curBlock.index < (nomOfBlocks -1)) {
      setPlanState(prv => {
        return {
          ...prv,
          blockIdx: prv.blockIdx + 1,
          weekIdx: 0,
        }
      })
    }
  }

  const handleBackwards = () => {
    const curBlock = planState.plan.blocks.sort((a, b) => a.index > b.index ? 1 : -1)
        .at(planState.blockIdx);

    const curWeek = curBlock.weeks
        .sort((a, b) => a.index > b.index ? 1 : -1)
        .at(planState.weekIdx);

    if (curWeek.index > 0) {
      setPlanState(prv => {
        return {
          ...prv,
          weekIdx: prv.weekIdx - 1,
        }
      })
    } else if (curBlock.index > 0) {
      setPlanState(prv => {
        return {
          ...prv,
          blockIdx: prv.blockIdx - 1,
          weekIdx: prv.plan.blocks.sort((a, b) => a.index > b.index ? 1 : -1).at(prv.blockIdx - 1).weeks.length - 1,
        }
      })
    }
  }

  return (
      <>
        {console.log(planState)}
        <Loader loading={loading}/>
        <NoteDialog approve={handleNewNote} close={() => setNewNote(false)} isOpen={newNote} />
        <Box sx={{display: "flex", justifyContent: "space-between", marginLeft: "20px", marginTop: "30px"}}>
          <Box
              onClick={() => setNewNote(true)}
              sx={{cursor: "pointer", mr: "20px", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <GreenPlusIcon/>
            <Typography sx={{
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "16px",
              color: "#4F535C"
            }}>הוסף הערות</Typography>
          </Box>
          <Box sx={{cursor: "pointer"}}
               onClick={() => navigate(`/app/clients/${params.userId}`)}>
            <BackIcon/>
          </Box>
        </Box>
        {planState && <Box>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Box sx={{cursor:  editMode ? '' : "pointer"}} onClick={editMode ? () => {} : handleBackwards}>
              <ForwordIcon/>
            </Box>
            <Typography sx={{ml: "10px", mr: "10px"}}> בלוק {Number(planState.blockIdx) +1} </Typography>
            <Typography>/</Typography>
            <Typography sx={{ml: "10px", mr: "10px"}}> שבוע {Number(planState.weekIdx) + 1} </Typography>
            <Box sx={{cursor: editMode ? '' : "pointer"}} onClick={editMode ? () => {} : handleForward}>
              <BackIcon/>
            </Box>
          </Box>
        </Box> }

        {planState && <Box sx={{marginTop: "16px", display: "flex", alignItems: "center", justifyContent: "center"}}>
          {Array.from(planState.plan.blocks.sort((a, b) => a.index > b.index ? 1 : -1)
              .at(planState.blockIdx).weeks
            .sort((a, b) => a.index > b.index ? 1 : -1)
            .at(planState.weekIdx).workouts.map((_, index) => {
                return <ClientWorkoutChip disable={editMode} key={index} setActiveTab={setActiveTab} index={index+1} active={activeTab === index+1}/>
              }))}
        </Box> }

        {planState && <Box sx={{marginLeft: "20px", marginRight: "20px"}}>
          <WorkoutActiveTab setLoading={setLoading}
                            traineeId={params.userId}
                            editMode={editMode} setEditMode={setEditMode}
                            workoutIdx={activeTab-1}
                            planState={planState}
          />
        </Box>}

      </>
  )
};

export default ClientWeekPage;
