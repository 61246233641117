import React, {useContext, useEffect, useState} from 'react';
import {getUser} from "../services/UserService";

const AuthContext = React.createContext({ undefined });

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const login = (user, token) => {
    if (!user?.authorities.find(x => x === "SYSTEM_ADMIN" || x === "ADMIN" || x === "TRAINER"))
      throw "Access denied";

    setCurrentUser(user);
    localStorage.setItem('auth', token);
  };

  const logout = () => {
    localStorage.removeItem('auth');
    setCurrentUser(null);
  };

  const isSuperAdmin = () => {
    return currentUser && currentUser?.authorities.some(element => {
      return element === "SYSTEM_ADMIN";
    })
  };

  const isCompanyAdmin = () => {
    return currentUser && currentUser?.authorities.some(element => {
      return element === "ADMIN";
    })
  };

  const isAdmin = () => {
    return isCompanyAdmin()  || isSuperAdmin();
  }

  const isTrainer = () => {
    return currentUser && currentUser?.authorities.some(element => {
      return element === "TRAINER";
    })
  };

  const refetchUser = () => {
    setLoading(true);
    const token = localStorage.getItem('auth');
    getUser(token).then(res => {
      setCurrentUser(res.data.body);
      setLoading(false);
    }).catch(() => {
      setLoading(false)
    })
  }

  useEffect( () => {
    setLoading(true);
    const token = localStorage.getItem('auth');
    if (token != null) {
      getUser(token).then(user => {
        login(user.data.body, token);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      })
    } else {
      setLoading(false);
    }
  }, []);


  const value = {
    currentUser,
    refetchUser,
    login,
    logout,
    isSuperAdmin,
    isCompanyAdmin,
    isTrainer,
    isAdmin,
  };

  return (
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
  );
}
