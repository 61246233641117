export const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 123: {
      return 'אימייל כבר קיים במערכת';
    }
    case 124: {
      return 'חברה כבר קיימת במערכת'
    }
    case 125: {
      return 'שם תרגיל כבר קיים במערכת'
    }
    case 126: {
      return 'שם תוכנית כבר קיים במערכת'
    }
    case 127: {
      return 'משתמש לא נמצא במערכת'
    }
    case 128: {
      return 'קוד פג תוקף או לא קיים, אנא שלח קוד נוסף'
    }
    default: {
      return 'אופס, קרה שגיאה בפעולה'
    }
  }
};
