import {Backdrop, Card} from "@mui/material";
import {useState} from "react";
import {resetPasswordWithToken, sendToken, verifyToken} from "../services/AuthService";
import ForgottenPasswordEmailStep from "./ForgottenPasswordEmailStep";
import {useMessageContext} from "../contexts/MessageContext";
import {getErrorMessage} from "../enums/ErrorMessages";
import ForgottenPasswordTokenStep from "./ForgottenPasswordTokenStep";
import Loader from "./Loader";
import ForgottenPasswordPasswordStep from "./ForgottenPasswordPasswordStep";
import ForgottenPasswordSuccessStep from "./ForgottenPasswordSuccessStep";

const ForgottenPassword = ({forgottenPassword, closeForgottenPassword}) => {
  const [email, setEmail] = useState('');
  const [digits, setDigits] = useState({});

  const {notificationHandler} = useMessageContext();
  const [currentStep, setCurrentStep] = useState('email');

  const [password, setPassword] = useState({
    password: '',
    secondPassword: ''
  })
  const [loading, setLoading] = useState(false);

  const handleSendToken = () => {
    setLoading(true);

    sendToken(email)
        .then(notificationHandler.success("קוד נשלח למייל בהצלחה"))
        .then(() => setCurrentStep('token'))
        .then(() => setLoading(false))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
          setLoading(false);
        })
  }

  const getToken = () => {
    return '' + digits.first
        + '' + digits.second
        + '' + digits.third
        + '' + digits.fourth
        + '' + digits.fifth;
  }

  const handleVerifyToken = () => {
    setLoading(true);
    verifyToken(getToken(), email)
        .then(notificationHandler.success("קוד אומת בהצלחה"))
        .then(() => setCurrentStep('password'))
        .then(() => setLoading(false))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
          setDigits({
            first: '',
            second: '',
            third: '',
            fourth: '',
            fifth: ''
          });
          setLoading(false);
        })
  }

  const resetPassword = () => {
    setLoading(true);
    resetPasswordWithToken(getToken(),
        email,
        password.password)
        .then(() => setCurrentStep('success'))
        .then(() => setLoading(false))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
          setLoading(false);
        })
  }

  const close =() => {
    setEmail('');
    setDigits({});
    setCurrentStep('email');
    closeForgottenPassword();
  }
  return (
      <>
      <Backdrop
          sx={{background: 'rgba(0,0,0,0.75)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={forgottenPassword}
          onClick={closeForgottenPassword}
      >
        <Card sx={{minWidth: {md: "312px", xs: "100vw"}, width: "600px",
          height: {xs: "100dvh", md: "auto"},
          backgroundColor: "#FCFCFD",
          borderRadius: {md: "8px", xs: "0"},
          display: "flex", flexDirection: "column", justifyContent: "space-between"}}
              onClick={e => e.stopPropagation()}>

          <Loader loading={loading}/>
          {currentStep === 'email' && <ForgottenPasswordEmailStep email={email} handleSendToken={handleSendToken} setEmail={setEmail} close={close} />}
          {currentStep === 'token' && <ForgottenPasswordTokenStep digits={digits} handleSendToken={handleSendToken} setDigits={setDigits} close={close} handleVerifyToken={handleVerifyToken}/>}
          {currentStep === 'password' && <ForgottenPasswordPasswordStep password={password} setPassword={setPassword} close={close} resetPassword={resetPassword}/>}
          {currentStep === 'success' && <ForgottenPasswordSuccessStep close={close}/>}
        </Card>
      </Backdrop>
      </>
  );
};

export default ForgottenPassword;
