import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {targetMuscles} from "../enums/TargetMuscles";
import {TempoTypes} from "../enums/TempoTypes";
import {ReactComponent as DoneIcon} from "../assets/images/done_oval.svg";
import {ReactComponent as DeleteIcon} from "../assets/images/delete_program.svg";
import {useEffect, useState} from "react";
import {RTL} from "../cache/CacheRtl";
import {ManipulationTypes} from "../enums/ManipulationTypes";
import {getByTypes, getExercise} from "../services/ExcersiceService";
import WeekIntervalRow from "./WeekIntervalRow";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";
import {WeightSets} from "../enums/WeightsSets";

const WeekRow = ({setEditWorkout, manipulationOpen, exercise, editMode, numberOfElements, prvWorkout}) => {

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const {notificationHandler} = useMessageContext();

  const [selectedValues, setSelectedValues] = useState({
    targetMuscle: '',
    exerciseDef: null,
    exerciseOptions: []
  });

  const prvExercise = prvWorkout?.programExercises.find(x => x.uuid === exercise.uuid);

  useEffect(() => {
    setLoading(true);
    if (exercise.exerciseId) {
      getExercise(exercise.exerciseId).then(res => {
        getByTypes(res.data.targetMuscle)
            .then(resEx => {
              setSelectedValues({
                targetMuscle: res.data.targetMuscle,
                exerciseDef: res.data,
                exerciseOptions: resEx.data});
              setLoading(false)}
            ).catch(e => {
          setLoading(false);
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode))
        })

      }).catch(e => {
        setLoading(false);
        notificationHandler.error(getErrorMessage(e.response.data?.errorCode))
      })
    } else {
      setSelectedValues({
        targetMuscle: '',
        exerciseDef: null,
        exerciseOptions: []
      })
      setLoading(false);
    }
  }, [exercise]);

  const moveExerciseUp = () => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};

      let programExercises = newWorkout.programExercises.sort((a, b) => a.index > b.index ? 1 : -1);

      let tmpId = programExercises.at(exercise.index-1).index;
      programExercises.at(exercise.index-1).index = programExercises.at(exercise.index).index;
      programExercises.at(exercise.index).index = tmpId;

      newWorkout.programExercises = programExercises;

      return newWorkout;
    })
  };

  const moveExerciseDown = () => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};
      let programExercises = newWorkout.programExercises.sort((a, b) => a.index > b.index ? 1 : -1);

      let tmpId = programExercises.at(exercise.index+1).index;
      programExercises.at(exercise.index+1).index = programExercises.at(exercise.index).index;
      programExercises.at(exercise.index).index = tmpId;

      newWorkout.programExercises = programExercises;

      return newWorkout;
    })
  };

  const changeExercise = (value) => {
    let exerciseFound = selectedValues.exerciseOptions.find(op => {
      return op.id === value;
    });

    setSelectedValues(prv => {
      return {
        ...prv,
        exerciseDef: exerciseFound
      }
    });

    setEditWorkout(prv => {
      let newWorkout = {...prv};

      let programExercises = newWorkout.programExercises.sort((a, b) => a.index > b.index ? 1 : -1);

      programExercises.at(exercise.index).exerciseId = exerciseFound.id;

      newWorkout.programExercises = programExercises;

      return newWorkout;
    })
  }

  const addInterval = () => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};

      let programExercises = newWorkout.programExercises.sort((a, b) => a.index > b.index ? 1 : -1);

      let intervals;
      if (programExercises.at(exercise.index).intervals) {
        intervals = programExercises.at(exercise.index).intervals;
      } else {
        intervals = [];
      }

      intervals
          .push({
            index: intervals.length
          })

      programExercises.at(exercise.index).intervals = intervals;

      newWorkout.programExercises = programExercises;

      return newWorkout;
    })
  }

  const handleOpenRow = () => {
    setOpen(true);
  }

  const handleCloseRow = () => {
    setOpen(false);
  }

  const deleteExercise = () => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};

      let idx = 0;

      newWorkout.programExercises = newWorkout.programExercises
          .sort((a, b) => a.index > b.index ? 1 : -1)
          .filter(x => x.index !== exercise.index)
          .map(val => {
            val.index = idx;
            idx += 1;
            return val;
          })

      return newWorkout;
    })
  };

  const handleRestTime = (value) => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};
      let programExercises = newWorkout.programExercises.sort((a, b) => a.index > b.index ? 1 : -1);

      programExercises.at(exercise.index).restTime = value;

      return newWorkout;
    })
  }

  const handleComment = (value) => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};
      let programExercises = newWorkout.programExercises.sort((a, b) => a.index > b.index ? 1 : -1);

      programExercises.at(exercise.index).comment = value;

      return newWorkout;
    })
  }

  const handleDurationTime = (value) => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};
      let programExercises = newWorkout.programExercises.sort((a, b) => a.index > b.index ? 1 : -1);

      programExercises.at(exercise.index).durationTime = value;

      return newWorkout;
    })
  }

  const updateTempo = (value) => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};
      let programExercises = newWorkout.programExercises.sort((a, b) => a.index > b.index ? 1 : -1);

      programExercises.at(exercise.index).tempo = value;

      return newWorkout;
    })
  }

  const updateManipulation = (value) => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};
      let programExercises = newWorkout.programExercises.sort((a, b) => a.index > b.index ? 1 : -1);

      programExercises.at(exercise.index).manipulation = value;

      return newWorkout;
    })
  }

  const updateTargetMuscle = (value) => {
    setLoading(true);

    getByTypes(value)
        .then(res => {
              setSelectedValues(prv => {
                return {
                  ...prv,
                  targetMuscle: value,
                  exerciseDef: null,
                  exerciseOptions: res.data
                }
              });
              setLoading(false);
            }
        ).catch(e => {
      notificationHandler.error(getErrorMessage(e.response.data?.errorCode))
      setLoading(false);
    });
  }

  return (
      <>
        {!loading &&
            <>
        <TableRow key={exercise.index} sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell align={"right"} sx={{
            width: "5%",
            borderBottom: "1px solid #A9ABB0",
            textAlign: "center",
            padding: "8px", color: "#717784",
            backgroundColor: "#F2F4F7"}}>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <KeyboardArrowUpRoundedIcon
                  sx={{
                    "&:hover" : {
                      color: "#B9D9D5",
                    },
                    cursor: "pointer", display: !editMode || exercise.index === 0 ? "none" : "inherit"}} onClick={moveExerciseUp}/>
              {exercise.index + 1}
              <KeyboardArrowDownRoundedIcon onClick={moveExerciseDown} sx={{
                "&:hover" : {
                  color: "#B9D9D5",
                },
                cursor: "pointer", display: !editMode || exercise.index === numberOfElements -1 ? "none" : "inherit"}} />
            </Box>
          </TableCell>
          {manipulationOpen &&
              <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
                textAlign: "center",
                width: "15%",
                padding: "8px", color: "#717784",
                backgroundColor: "#caeee1"}}>{
                editMode ?
                <RTL>
                  <FormControl fullWidth>
                    <Select
                        sx={{minWidth: "100px", height: "48px"}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={exercise.manipulation || ""}
                        onChange={(e) => updateManipulation(e.target.value)}
                        displayEmpty
                    >
                      {ManipulationTypes.map((res,i) => {
                        return <MenuItem key={i} value={res}>{res}</MenuItem>
                      })
                      })}
                    </Select>
                  </FormControl>
                </RTL> : exercise.manipulation}
              </TableCell>
          }
          <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
            textAlign: "center",
            width: "15%",
            padding: "8px", color: "#717784",
            backgroundColor: "#FCFCFD"}}>{
            editMode ?
                <RTL>
                  <FormControl fullWidth>
                    <Select
                        sx={{minWidth: "100px", height: "48px"}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedValues.targetMuscle || ""}
                        onChange={(e) => updateTargetMuscle(e.target.value)}
                        displayEmpty
                    >
                      {targetMuscles.map((res,i) => {
                        return <MenuItem key={i} value={res.value}>{res.name}</MenuItem>
                      })
                      })}
                    </Select>
                  </FormControl>
                </RTL>
             : targetMuscles.find(x => x.value === exercise.targetMuscle)?.name }
          </TableCell>
          <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
            textAlign: "center",
            width: "25%",
            padding: "8px", color: "#717784",
            backgroundColor: "#F2F4F7"}}>{editMode ?
              <RTL>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    fullWidth
                    value={selectedValues.exerciseDef}
                    getOptionLabel={(option) => option.nameHeb}
                    disableClearable
                    onChange={(event, value) => {
                        changeExercise(value.id)
                      }
                    }
                    options={selectedValues.exerciseOptions}
                    sx={{mr: "10px", ml: "10px", "& .MuiInputBase-root" : {
                        height: "46px"
                      }}}
                    renderInput={(params) => <TextField
                        size="small"
                        {...params} label="תרגיל" />}
                />
                {/*<FormControl fullWidth>*/}
                {/*  <Select*/}
                {/*      sx={{height: "48px"}}*/}
                {/*      fullWidth*/}
                {/*      labelId="demo-simple-select-label"*/}
                {/*      id="demo-simple-select"*/}
                {/*      value={selectedValues.exerciseDef?.id}*/}
                {/*      displayEmpty*/}
                {/*      onChange={e => changeExercise(e.target.value)}*/}
                {/*  >*/}
                {/*    {selectedValues.exerciseOptions.map((value, i) => {*/}
                {/*      return <MenuItem key={i} value={value.id}>{value.nameHeb}</MenuItem>*/}
                {/*    })}*/}
                {/*  </Select>*/}
                {/*</FormControl>*/}
              </RTL> : selectedValues.exerciseDef?.nameHeb}
          </TableCell>
          <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
            textAlign: "center",
            width: "5%",
            padding: "8px", color: "#717784",
            backgroundColor: "#FCFCFD"}}>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              {exercise.intervals?.length}

              {open && selectedValues.targetMuscle !== 'CARDIO' && <KeyboardArrowUpRoundedIcon
                  onClick={handleCloseRow}
                  sx={{
                    cursor: "pointer",
                    "&:hover" : {
                      color: "#B9D9D5",
                    },
                  }}
              />}
              {!open && selectedValues.targetMuscle !== 'CARDIO' && <KeyboardArrowDownRoundedIcon onClick={handleOpenRow}
                                                 sx={{
                                                   cursor: "pointer",
                                                   "&:hover" : {
                                                     color: "#B9D9D5",
                                                   },
                                                 }}/>}
            </Box>
          </TableCell>
          <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
            textAlign: "center",
            width: "5%",
            padding: "8px", color: "#717784",
            backgroundColor: "#F2F4F7"}}>{ editMode ? <TextField
                  value={exercise.restTime}
                  variant={"standard"}
                  type={"number"}
                  onChange={e => handleRestTime(e.target.value)}
              />
              : exercise.restTime}
          </TableCell>
          <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
            textAlign: "center",
            width: "15%",
            padding: "8px", color: "#717784",
            backgroundColor: "#FCFCFD"}}>
            { editMode ? <TextField
                      value={exercise.comment}
                      variant={"standard"}
                      multiline
                      onChange={e => handleComment(e.target.value)}
                  />
                  : exercise.comment || 'אין'}
          </TableCell>
          <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
            textAlign: "center",
            width: "10%",
            padding: "8px", color: "#717784",
            backgroundColor: "#F2F4F7"}}>
            {/*{ editMode ?*/}
            {/*    <RTL>*/}
            {/*      <FormControl fullWidth>*/}
            {/*        <Select*/}
            {/*            sx={{minWidth: "100px", height: "48px"}}*/}
            {/*            labelId="demo-simple-select-label"*/}
            {/*            id="demo-simple-select"*/}
            {/*            value={exercise.weightsSet || ""}*/}
            {/*            onChange={(e) => updateTempo(e.target.value)}*/}
            {/*            displayEmpty*/}
            {/*        >*/}
            {/*          {WeightSets.map((res,i) => {*/}
            {/*            return <MenuItem key={i} value={res}>{res}</MenuItem>*/}
            {/*          })*/}
            {/*          })}*/}
            {/*        </Select>*/}
            {/*      </FormControl>*/}
            {/*    </RTL> : WeightSets.find(x => x === exercise.weightsSet)}*/}
            {WeightSets.find(x => x === exercise.weightsSet)}
          </TableCell>
          {selectedValues.targetMuscle === "CARDIO" && <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
            textAlign: "center",
            width: "10%",
            padding: "8px", color: "#717784",
            backgroundColor: "#F2F4F7"}}>
            {editMode ? <TextField
                value={exercise.durationTime}
                variant={"standard"}
                type={"number"}
                onChange={e => handleDurationTime(e.target.value)}
            /> : exercise.durationTime + " דקות"}
          </TableCell>}
          {selectedValues.targetMuscle !== "CARDIO" && <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
            textAlign: "center",
            width: "10%",
            padding: "8px", color: "#717784",
            backgroundColor: "#FCFCFD"}}>
            { editMode ?
                <RTL>
                  <FormControl fullWidth>
                    <Select
                        sx={{minWidth: "100px", height: "48px"}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={exercise.tempo || ""}
                        onChange={(e) => updateTempo(e.target.value)}
                        displayEmpty
                    >
                      {TempoTypes.map((res,i) => {
                        return <MenuItem key={i} value={res.value}>{res.name}</MenuItem>
                      })
                      })}
                    </Select>
                  </FormControl>
                </RTL> : TempoTypes.find(x => x.value === exercise.tempo)?.name}
          </TableCell>}

          <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
            textAlign: "center",
            width: "5%",
            padding: "8px", color: "#717784",
            backgroundColor: "#F2F4F7"}}>{exercise.finished? <DoneIcon/> : ''}
          </TableCell>
          {editMode && <TableCell align={"right"} sx={{borderBottom: "1px solid #A9ABB0",
            textAlign: "center",
            width: "5%",
            padding: "2px", color: "#717784",
            backgroundColor: "#F2F4F7"}}>
            <Box sx={{cursor: "pointer"}} onClick={() => deleteExercise(exercise.index)}>
              <DeleteIcon/>
            </Box>
          </TableCell>}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8} onClick={e => e.stopPropagation()}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }} >
                <Table size="large" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell
                          sx={{borderBottom: "1px solid #A9ABB0",
                            textAlign: "center",
                            width: "5%",
                            padding: "2px", color: "#717784",
                            backgroundColor: "#caeee1"}}
                          align={"center"}>מס סט</TableCell>
                      <TableCell
                          sx={{borderBottom: "1px solid #A9ABB0",
                            textAlign: "center",
                            width: "5%",
                            padding: "2px", color: "#717784",
                            backgroundColor: "#caeee1"}}
                          align={"center"}>חזרות מומלצות</TableCell>
                      <TableCell
                          sx={{borderBottom: "1px solid #A9ABB0",
                            textAlign: "center",
                            width: "5%",
                            padding: "2px", color: "#717784",
                            backgroundColor: "#caeee1"}}
                          align={"center"}>חזרות שבוצעו</TableCell>
                      <TableCell
                          sx={{borderBottom: "1px solid #A9ABB0",
                            textAlign: "center",
                            width: "5%",
                            padding: "2px", color: "#717784",
                            backgroundColor: "#caeee1"}}
                          align={"center"}>סוג סט</TableCell>
                      <TableCell
                          sx={{borderBottom: "1px solid #A9ABB0",
                            textAlign: "center",
                            width: "5%",
                            padding: "2px", color: "#717784",
                            backgroundColor: "#caeee1"}}
                          align={"center"}>משקל מומלץ</TableCell>
                      <TableCell
                          sx={{borderBottom: "1px solid #A9ABB0",
                            textAlign: "center",
                            width: "5%",
                            padding: "2px", color: "#717784",
                            backgroundColor: "#caeee1"}}
                          align={"center"}>משקל שבוצע</TableCell>
                      <TableCell
                          sx={{borderBottom: "1px solid #A9ABB0",
                            textAlign: "center",
                            width: "5%",
                            padding: "2px", color: "#717784",
                            backgroundColor: "#caeee1"}}
                          align={"center"}>RIR</TableCell>
                      {editMode && <TableCell
                          sx={{borderBottom: "1px solid #A9ABB0",
                            textAlign: "center",
                            width: "5%",
                            padding: "2px", color: "#717784",
                            backgroundColor: "#caeee1"}}
                          align={"center"}>הוסף/מחק</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody onClick={(e) => e.stopPropagation()}>
                    {exercise.intervals?.sort((a, b) => a.index > b.index ? 1 : -1)
                        .map((interval, index) =>  {
                          let prvInterval = prvExercise?.intervals?.sort((a, b) => a.index > b.index ? 1 : -1).at(index);
                          return <WeekIntervalRow exercise={exercise} setEditWorkout={setEditWorkout} interval={interval} editMode={editMode} prvInterval={prvInterval}/>
                    }
                    )}
                  </TableBody>
                </Table>
              </Box>
              {editMode && <Box sx={{display: "flex", justifyContent: "flex-start"}}>
                <Button onClick={addInterval} sx={{mb: "10px", mt: "10px"}} variant={"contained"}>הוסף סט +</Button>
              </Box>}
            </Collapse>
          </TableCell>
        </TableRow>
            </>
        }
      </>
  );
};

export default WeekRow;
