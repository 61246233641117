import {Box, Button, Container, SvgIcon, Typography} from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {RTL} from "../cache/CacheRtl";

const Page404 =() => {
return (
    <>
      <Box
          component="main"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            minHeight: '100%'
          }}
      >
        <Container maxWidth="md">
          <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
          >
            <Box
                sx={{
                  mb: 3,
                  textAlign: 'center'
                }}
            >
              <img
                  alt="Under development"
                  src="/error-404.png"
                  style={{
                    display: 'inline-block',
                    maxWidth: '100%',
                    width: 400
                  }}
              />
            </Box>
            <Typography
                align="center"
                sx={{ mb: 3 }}
                variant="h3"
            >
              הדף הבוקש אינו קיים
            </Typography>
            {/*<Typography*/}
            {/*    align="center"*/}
            {/*    color="text.secondary"*/}
            {/*    variant="body1"*/}
            {/*>*/}
            {/*  הדף שהגעת אינו קיים, */}
            {/*</Typography>*/}
            <RTL>
            <Button
                href="/app/dashboard"
                endIcon={(
                    <SvgIcon fontSize="small">
                      <ArrowBackIosNewIcon />
                    </SvgIcon>
                )}
                sx={{ mt: 3 }}
                variant="contained"
            >
              חזור לדף הבית
            </Button>
            </RTL>
          </Box>
        </Container>
      </Box>
    </>
)
};

export default Page404;
