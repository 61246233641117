import httpService from "./HttpService";

export const getTemplate = async (templateId) => {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/template/${templateId}`, null, token);
};

export const deleteTemplate = async (templateId) => {
  const token = localStorage.getItem("auth");
  return httpService.delete(`admin/template/${templateId}`, null, token);
};

export const duplicateTemplate = async (templateId) => {
  const token = localStorage.getItem("auth");
  return httpService.post(`admin/template/duplicate/${templateId}`, null, token);
};

export const saveMetaData = async (metaData) => {
  const token = localStorage.getItem("auth");
  return httpService.post(`admin/template`, metaData, token);
}

export const updateMetaData = async (metaData) => {
  const token = localStorage.getItem("auth");
  return httpService.put(`admin/template/metaData`, metaData, token);
}

export const updateTemplate = async (template) => {
  const token = localStorage.getItem("auth");
  return httpService.put(`admin/template`, template, token);
}

export const enableTemplate = async (id) => {
  const token = localStorage.getItem("auth");
  return httpService.put(`admin/template/enable/${id}`, null, token);
}

export const disableTemplate = async (id) => {
  const token = localStorage.getItem("auth");
  return httpService.put(`admin/template/disable/${id}`, null, token);
}

export const getTemplates = async (pageId = 0,
    pageSize = 20,
    numberOfWorkouts,
    experienceLevel,
    keyword,
    mainMuscle,
    equipmentType,
    enabled,
    secondaryMuscle,
    secondaryMuscle2,
    gender
    ) => {
  const token = localStorage.getItem("auth");

  let url = `admin/template?pageId=${pageId}&pageSize=${pageSize}`;

  if (numberOfWorkouts !== "null" && numberOfWorkouts !== 0) {
    url += `&numberOfWorkouts=${numberOfWorkouts}`
  }

  if (experienceLevel !== 'ALL'){
    url += `&experienceLevel=${experienceLevel}`
  }

  if (keyword) {
    url += `&keyword=${keyword}`
  }

  if (mainMuscle && mainMuscle !== "null"){
    url += `&mainMuscle=${mainMuscle}`
  }

  if (secondaryMuscle && secondaryMuscle !== "null"){
    url += `&secondaryMuscle=${secondaryMuscle}`
  }

  if (secondaryMuscle2 && secondaryMuscle2 !== "null"){
    url += `&secondaryMuscle2=${secondaryMuscle2}`
  }

  if (equipmentType && equipmentType !== "null"){
    url += `&equipmentType=${equipmentType}`
  }

  if (enabled) {
    url += `&enabled=true`
  }

  if (gender !== 'null' && gender != null) {
    url += `&gender=${gender}`
  }
  return httpService.get(url, null, token);
}
