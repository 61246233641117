import {Box, Divider, Paper, Typography} from "@mui/material";
import {ReactComponent as MaleIcon} from '../../assets/images/male.svg';
import {ReactComponent as FemaleIcon} from '../../assets/images/female.svg';


const GenderStas = ({female, male, total}) => {

  return (
      <Paper sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7", minHeight: "108px"}}>
        <Box sx={{marginRight: "24px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <Box sx={{flex: 4}}>
          <Typography sx={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#4F535C",
            marginTop: "24px"
          }}></Typography>
        סה”כ לקוחות
          <Typography sx={{
            fontSize: "36px",
            fontWeight: 700,
            lineHeight: "49px",
            color: "#4F535C",
            marginTop: "8px"
          }}>
              {total}
          </Typography>
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem sx={{flex: 1}}/>
          <Box sx={{display: "flex", alignItems: "center", flex: 2}}>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <MaleIcon/>
              <Typography sx={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "24px",
                color: "#717784",
              }}>גברים</Typography>
            </Box>
            <Typography sx={{
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "27px",
              color: "#4F535C",
              marginRight: "34px"
            }}>{male}</Typography>
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem sx={{flex: 1}} />
          <Box sx={{display: "flex", alignItems: "center", flex: 2}}>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <FemaleIcon/>
              <Typography sx={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "24px",
                color: "#717784",
              }}>נשים</Typography>
            </Box>
            <Typography sx={{
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "27px",
              color: "#4F535C",
              marginRight: "34px",
              marginLeft: "24px"
            }}>{female}</Typography>
          </Box>
        </Box>
      </Paper>
  );
};

export default GenderStas;
