import {Box, Button} from "@mui/material";
import {ReactComponent as DeleteIcon} from "../assets/images/delete.svg";
import ClientProfileSection from "./client/ClientProfileSection";
import ClientTrainerSection from "./client/ClientTrainerSection";
import ClientPlansSection from "./client/ClientPlansSection";
import {disableClients, enableClients} from "../services/TraineeService";
import {useMessageContext} from "../contexts/MessageContext";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useAuth} from "../contexts/AuthContext";

const ClientPageProfile = ({handleReload, client}) => {

  const {notificationHandler} = useMessageContext();
  const {isTrainer} = useAuth();

  const handleEnableDisable = () => {
    if (client.enabled) {
      disableClients([client.id])
          .then(() => notificationHandler.success("מתאמן נחסם בהצלחה"))
          .then(handleReload)
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
    } else {
      enableClients([client.id])
          .then(() => notificationHandler.success("מתאמן הופעל בהצלחה"))
          .then(handleReload)
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
    }
  }

  return  (
      <Box>
        {!isTrainer() && <Box sx={{marginTop: "28px", display: "flex", justifyContent: "flex-end"}}>
         <Button variant="outlined"
                 sx={{backgroundColor: "#FCFCFD", color: "#27272E", height: "44px", width: "78px", "& .MuiButton-endIcon" : {
                     marginLeft: "-4px",
                     marginTop: "2px",
                     borderRadius: "8px",
                     marginRight: "8px",
                   }}} endIcon={<DeleteIcon/>}
         >
           מחק
         </Button>
         <Button
             onClick={handleEnableDisable}
             variant="text"
                 sx={{color: client.enabled ? "#F16063" : "#57835b", height: "44px", width: "78px"}}
         >
           {client.enabled ? "חסום" : "הפעל"}
         </Button>
       </Box>}
        <Box sx={{marginTop: "28px"}}>
        <ClientProfileSection refetch={handleReload} client={client}/>
        </Box>
        <Box sx={{marginTop: "22px"}}>
          <ClientTrainerSection refetch={handleReload} client={client}/>
        </Box>
        <Box sx={{marginTop: "22px"}}>
          <ClientPlansSection handleReload={handleReload} client={client}/>
        </Box>
      </Box>
  );
};

export default ClientPageProfile;
