import {Box, Button, Divider, TextField, Typography} from "@mui/material";
import {ReactComponent as PlusIcon} from "../../assets/images/plus.svg";

const NutritionPageHeader = ({setNewFile, menu}) => {

  return (
      <Box sx={{marginRight: "40px"}}>
        <Typography sx={{
          fontSize: "36px",
          fontWeight: 700,
          lineHeight: "49px",
          color: "#27272E"
        }}>תפריט תזונה</Typography>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center",marginTop: "35px", }}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography sx={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#717784",
          }}>קלוריות: </Typography>
          <Typography sx={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#717784",
          }}>{menu.calories ? menu.calories : "000"}</Typography>
          <Typography sx={{
            marginRight: "40px",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#717784",
            textDecoration: "underline",
            textUnderlineOffset: "8px",
            textDecorationColor: "#57837E",
            textDecorationThickness: '3px'
          }}> פחמימה: &nbsp;</Typography>
          <Typography sx={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#717784",
            textDecoration: "underline",
            textUnderlineOffset: "8px",
            textDecorationColor: "#57837E",
            textDecorationThickness: '3px'
          }}>{menu.carbs ? menu.carbs : "000"}</Typography>
          <Typography sx={{
            marginRight: "40px",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#717784",
            textDecoration: "underline",
            textUnderlineOffset: "8px",
            textDecorationColor: "#57837E",
            textDecorationThickness: '3px'
          }}> חלבון: &nbsp;</Typography>
          <Typography sx={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#717784",
            textDecoration: "underline",
            textUnderlineOffset: "8px",
            textDecorationColor: "#57837E",
            textDecorationThickness: '3px'
          }}>{menu.proteins ? menu.proteins : "000"}</Typography>
          <Typography sx={{
            marginRight: "40px",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#717784",
            textDecoration: "underline",
            textUnderlineOffset: "8px",
            textDecorationColor: "#57837E",
            textDecorationThickness: '3px'
          }}> שומן: &nbsp;</Typography>
          <Typography sx={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#717784",
            textDecoration: "underline",
            textUnderlineOffset: "8px",
            textDecorationColor: "#57837E",
            textDecorationThickness: '3px'
          }}>{menu.fats ? menu.fats : "000"}</Typography>


        </Box>
          <Box sx={{marginLeft: "40px"}}>
            <Button variant="outlined"
                    onClick={setNewFile}
                    sx={{backgroundColor: "#F2F4F7", height: "44px", width: "132px", "& .MuiButton-startIcon" : {
                        marginRight: "-4px",
                        marginTop: "2px",
                        marginLeft: "8px",
                      }}} startIcon={<PlusIcon/>}
                    >
              יצירת תפריט
            </Button>
          </Box>
        </Box>
    </Box>
  );
};

export default NutritionPageHeader;
