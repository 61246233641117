import {Box, Divider, Hidden, Typography, useMediaQuery} from "@mui/material";
import {ReactComponent as VisibilityIcon} from "../assets/images/visibility.svg";
import {ReactComponent as HideIcon} from "../assets/images/hide.svg";
import {useEffect, useState} from "react";
import {getClientsStats} from "../services/TraineeService";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";

const Stats = () => {
  const [open, setOpen] = useState(false);
  const [stats, setStats] = useState();

  const {notificationHandler} = useMessageContext();
  const flipOpen = () => {
    setOpen(prv => {
      return !prv;
    })
  }

  useEffect(() => {
    getClientsStats().then((res) => {
      setStats(res.data);
    }).catch(e => {
      notificationHandler.error(getErrorMessage(e.response.data?.errorCode))
    })
  }, [])

  return (
      <Box sx={{display: "flex"}}>
      <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", cursor: "pointer"}}
      onClick={flipOpen}>
        {open ? <HideIcon/> : <VisibilityIcon/>}
        <Typography sx={{color: "#446864", fontWeight: 400, fontSize: "18px", lineHeight: "24.48px",
          textDecoration: {xs: "underline", md: "none"},
          textUnderlineOffset: "1.5px",
          textDecorationColor: "#446864"}}>{open ? "הסתר" : "הצג סיכום"}</Typography>
      </Box>
        {stats &&
      <Box sx={{display: `${open ? "flex" : "none" }`, alignItems: "center", marginRight: "24px"}}>
        <Divider sx={{width: "0.5px", height: "48px"}} orientation="vertical" flexItem color={"#4F535C"}/>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", marginRight: "24px"}}>
          <Typography sx={{color: "#425466"}}>לקוחות</Typography>
          <Typography>{stats.total}</Typography>
        </Box>
        <Divider sx={{width: "0.5px", height: "48px",marginRight: "24px"}} orientation="vertical" flexItem color={"#4F535C"}/>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", marginRight: "24px"}}>
          <Typography sx={{color: "#425466"}}>גברים</Typography>
          <Typography>{stats.males}%</Typography>
        </Box>
        <Divider sx={{width: "0.5px", height: "48px",marginRight: "24px"}} orientation="vertical" flexItem color={"#4F535C"}/>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", marginRight: "24px"}}>
          <Typography sx={{color: "#425466"}}>נשים</Typography>
          <Typography>{stats.females}%</Typography>
        </Box>
        <Divider sx={{width: "0.5px", height: "48px",marginRight: "24px"}} orientation="vertical" flexItem color={"#4F535C"}/>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", marginRight: "24px"}}>
          <Typography sx={{color: "#425466"}}>אחר</Typography>
          <Typography>{stats.unknown}%</Typography>
        </Box>
      </Box>}
      </Box>
  );

};

export default Stats;
