import {ReactComponent as BackIcon} from '../assets/images/backIcon.svg';
import {ReactComponent as DeleteIcon} from '../assets/images/delete.svg';
import {ReactComponent as PdfIcon} from '../assets/images/pdf.svg';
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import NutritionPageHeader from "../componenets/nutrition/NutritionPageHeader";
import {useEffect, useState} from "react";
import {deleteNutrition, getClientNutritions} from "../services/TraineeService";
import moment from "moment";
import NewNutritionDialog from "../componenets/NewNutritionDialog";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";

const ClientNutritionPage = () => {
  let navigate = useNavigate();
  const [newFile, setNewFile] = useState({
    isOpen: false,
    menu: {}
  });

  const params = useParams();

  const [menus, setMenus] = useState([]);
  const {notificationHandler} = useMessageContext();

  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    getClientNutritions(params.userId)
        .then(res => setMenus(res.data))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
  },[reloadData]);

  const getNutritionText = (menu) => {
    return (<Typography>
      קלוריות: {menu.calories}    פחמימות: {menu.carbs} / חלבון: {menu.proteins} / שומן: {menu.fats}
    </Typography>);
  }

  const deleteMenu = (menu) => {
    deleteNutrition(menu.id)
        .then(() => navigate(0))
        .catch(e => console.log(e));
  }

  const handleNew =() => {

    console.log("new");
    setNewFile({
      isOpen: true,
      menu: {},
      isNew: true
    })
  }

  const handleClose = () => {
    setNewFile({
      isOpen: false,
      menu: {},
      isNew: true
    })
  }

  const handleEdit = (menu) => {
    console.log("menu:" + menu)
    setNewFile({
      isOpen: true,
      menu,
      isNew: false
    })
  }

  return (
      <Box>
        <NewNutritionDialog isNew={newFile.isNew} newFile={newFile} traineeId={params.userId} reload={() => setReloadData(prv => !prv)} close={handleClose} />
        <Box sx={{display: "flex", justifyContent: "flex-end", marginLeft: "20px", marginTop: "30px", cursor: "pointer"}}
        onClick={() => navigate(-1)}>
          <BackIcon/>
        </Box>
          <NutritionPageHeader menu={menus.length > 0 ? menus.at(0) : {}} setNewFile={handleNew}/>
        <Box sx={{margin: "40px"}}>
        <TableContainer
                        sx={{
                          borderRadius: "4px 4px 0px 0px"}}>
          <Table>
            <TableHead sx={{backgroundColor: "#F9FAFB"}}>
              <TableRow>
                <TableCell align={"right"} sx={{borderBottom: 0, color: "#718096"}}>מספר תפריט</TableCell>
                <TableCell align={"right"} sx={{borderBottom: 0, color: "#718096"}}>ערכים תזונתיים</TableCell>
                <TableCell align={"right"} sx={{borderBottom: 0, color: "#718096"}}>תאריך יצירה</TableCell>
                <TableCell align={"right"} sx={{borderBottom: 0}}>PDF</TableCell>
                <TableCell align={"right"} sx={{borderBottom: 0}}>מחק</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {menus.map((menu, i) => {
                return <TableRow key={i} sx ={{"&:hover" : {
                    backgroundColor: "rgba(185,217,213,0.41)"
                  },}}>
                  <TableCell onClick={() => handleEdit(menu)}  width={"10%"} align={"right"} sx={{
                    cursor: "pointer", color: "#4F535C", fontSize: "14px", fontWeight: 600, lineHeight: "24px"}}>{menu.name}</TableCell>
                  <TableCell onClick={() => handleEdit(menu)} width={"55%"} align={"right"} sx={{cursor: "pointer", color: "#4F535C", fontSize: "14px", fontWeight: 600, lineHeight: "24px"}}>{getNutritionText(menu)}</TableCell>
                  <TableCell onClick={() => handleEdit(menu)} width={"10%"} align={"right"} sx={{cursor: "pointer"}}>{moment.unix(moment(menu.createdDate)).format("DD/MM/YY")}</TableCell>
                  <TableCell
                      width={"15%"} sx={{cursor: menu.fileUrl ? "pointer" : ""}} align={"right"}>{menu.fileUrl ?
                      <a download href={menu.fileUrl} target="_blank">
                    <PdfIcon/></a> : "אין קובץ"}</TableCell>
                  <TableCell width={"10%"} sx={{cursoxr: "pointer"}} align={"right"}><Box onClick={() => deleteMenu(menu)}><DeleteIcon/></Box></TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
      </Box>
  );
};

export default ClientNutritionPage;
