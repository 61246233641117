import PageHeader from "../componenets/shared/PageHeader";
import {ReactComponent as EditIcon} from "../assets/images/editProfile.svg";
import {
  Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import {getUserLoggedIn, updateUser} from "../services/UserService";
import Loader from "../componenets/Loader";
import {useMessageContext} from "../contexts/MessageContext";
import {getErrorMessage} from "../enums/ErrorMessages";

const SettingPage = () => {

  const [currentUser, setCurrentUser] = useState();

  const [orginalCopy, setOriginalCopy] = useState();

  const [showName, setShowName] = useState(false);

  const [showPhoneNumber, setShowPhoneNumber] = useState(false);

  const [showEmail, setShowEmail] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const {notificationHandler} = useMessageContext();

  const [reload, setReload] = useState(false);

  useEffect(() => {
    setLoading(true);
    setShowName(false);
    setShowEmail(false);
    setShowPassword(false);
    setShowPhoneNumber(false);

    getUserLoggedIn()
        .then((res) => {
          setCurrentUser(res.data.body);
          setOriginalCopy(res.data.body);
          setLoading(false);
        }).catch(e => {
        notificationHandler.error(getErrorMessage(e.response.data?.errorCode))
      setLoading(false);
    })
  }, [reload]);

  const updateName = (name) => {
    setCurrentUser(prv => {
      return {
        ...prv,
        name: name
      }
    })
  }

  const updatePhoneNumber = (phoneNumber) => {
    setCurrentUser(prv => {
      return {
        ...prv,
        phoneNumber: phoneNumber
      }
    })
  }

  const updateEmail = (email) => {
    setCurrentUser(prv => {
      return {
        ...prv,
        email: email
      }
    })
  }

  const updatePassword = (password) => {
    setCurrentUser(prv => {
      return {
        ...prv,
        password: password
      }
    })
  }

  const saveName = () => {
    updateUser({name: currentUser.name})
        .then(() => notificationHandler.success('משתמש עודכן בהצלחה'))
        .then(() => setReload(prv => !prv))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
        })
  }

  const savePhoneNumber = () => {
    updateUser({phoneNumber: currentUser.phoneNumber})
        .then(() => setReload(prv => !prv))
        .then(() => notificationHandler.success('משתמש עודכן בהצלחה'))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
        })
  }

  const saveEmail = () => {
    updateUser({email: currentUser.email})
        .then(() => notificationHandler.success('משתמש עודכן בהצלחה'))
        .then(() => setReload(prv => !prv))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
        })
  }

  const savePassword = () => {
    updateUser({password: currentUser.password})
        .then(() => notificationHandler.success('משתמש עודכן בהצלחה'))
        .then(() => setReload(prv => !prv))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
        })
  }

  const cancel = () => {
    setCurrentUser({...orginalCopy});
    setShowName(false);
    setShowPhoneNumber(false);
    setShowEmail(false);
    setShowPassword(false);
  }

  return (
      <>
        <Loader loading={loading}/>
        <PageHeader title={"הגדרות"} />
          <Card sx={{margin: "40px", padding: "20px", boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)"}}>
            <TableContainer>
              <Table sx={{"&.MuiTable-root" : {
                  borderCollapse: "separate",
                  borderSpacing: "0 15px",
                }}}>
                <TableHead>
                  <TableRow>
                    <TableCell align="right" sx={{border: 0, color: "#717784"}}>
                     סוג:
                    </TableCell>
                    <TableCell align="right" sx={{border: 0, color: "#717784"}}>
                     הגדרה
                    </TableCell>
                    <TableCell align="left" sx={{border: 0, color: "#717784"}}>
                      פעולות
                    </TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="right" sx={{border: 0, color: "#4F535C",  backgroundColor: "#F2F4F7"}}>
                    שם מלא:
                  </TableCell>
                  <TableCell align="right" sx={{border: 0, color: "#4F535C", backgroundColor: "#F2F4F7"}}>
                    {currentUser && !showName && currentUser.name}
                    {currentUser && showName &&
                        <TextField
                            fullWidth
                            value={currentUser.name}
                            onChange={(e) => updateName(e.target.value)}
                            id="name"
                            variant="standard"
                        />}
                  </TableCell>
                  <TableCell align="left" sx={{border: 0, color: "#717784", backgroundColor: "#F2F4F7"}}>
                    <Box>
                      {!showName ? <Box sx={{cursor: "pointer"}} onClick={() => setShowName(true)}><EditIcon/> </Box> : <>
                        <Button
                            onClick={cancel}
                            sx={{marginRight: "24px",
                              "&:hover" : {
                              backgroundColor: "#F1F3F5",
                              },
                              backgroundColor: "#F1F3F5", color: "black"}}
                            variant={"contained"}>
                          בטל
                        </Button>
                        <Button
                            onClick={saveName}
                            sx={{marginRight: "24px"}}
                            variant={"contained"}>
                          שמור
                        </Button>
                      </>}
                   </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" sx={{border: 0, color: "#4F535C", backgroundColor: "#F2F4F7"}}>
                    טלפון:
                  </TableCell>
                  <TableCell align="right" sx={{border: 0, color: "#4F535C", backgroundColor: "#F2F4F7"}}>
                    {currentUser && !showPhoneNumber && currentUser.phoneNumber}
                    {currentUser && showPhoneNumber &&
                        <TextField
                            fullWidth
                            value={currentUser.phoneNumber}
                            onChange={(e) => updatePhoneNumber(e.target.value)}
                            id="name"
                            variant="standard"
                        />}
                  </TableCell>
                  <TableCell align="left" sx={{border: 0, color: "#717784", backgroundColor: "#F2F4F7"}}>
                    <Box>
                      {!showPhoneNumber ? <Box sx={{cursor: "pointer"}} onClick={() => setShowPhoneNumber(true)}><EditIcon/> </Box> : <>
                        <Button
                            onClick={cancel}
                            sx={{marginRight: "24px",
                              "&:hover" : {
                                backgroundColor: "#F1F3F5",
                              },
                              backgroundColor: "#F1F3F5", color: "black"}}
                            variant={"contained"}>
                          בטל
                        </Button>
                        <Button
                            onClick={savePhoneNumber}
                            sx={{marginRight: "24px"}}
                            variant={"contained"}>
                          שמור
                        </Button>
                      </>}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" sx={{border: 0, color: "#4F535C", backgroundColor: "#F2F4F7"}}>
                    מייל:
                  </TableCell>
                  <TableCell align="right" sx={{border: 0, color: "#4F535C", backgroundColor: "#F2F4F7"}}>
                    {currentUser && !showEmail && currentUser.email}
                    {currentUser && showEmail &&
                        <TextField
                            fullWidth
                            value={currentUser.email}
                            onChange={(e) => updateEmail(e.target.value)}
                            id="name"
                            variant="standard"
                        />}
                  </TableCell>
                  <TableCell align="left" sx={{border: 0, color: "#717784", backgroundColor: "#F2F4F7"}}>
                    <Box>
                      {!showEmail ? <Box sx={{cursor: "pointer"}} onClick={() => setShowEmail(true)}><EditIcon/> </Box> : <>
                        <Button
                            onClick={cancel}
                            sx={{marginRight: "24px",
                              "&:hover" : {
                                backgroundColor: "#F1F3F5",
                              },
                              backgroundColor: "#F1F3F5", color: "black"}}
                            variant={"contained"}>
                          בטל
                        </Button>
                        <Button
                            onClick={saveEmail}
                            sx={{marginRight: "24px"}}
                            variant={"contained"}>
                          שמור
                        </Button>
                      </>}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" sx={{border: 0, color: "#4F535C", backgroundColor: "#F2F4F7"}}>
                    סיסמה:
                  </TableCell>
                  <TableCell align="right" sx={{border: 0, color: "#4F535C", backgroundColor: "#F2F4F7"}}>
                    {!showPassword && "****"}
                    {currentUser && showPassword &&
                        <TextField
                            fullWidth
                            type="password"
                            placeholder="****"
                            value={currentUser.password}
                            onChange={(e) => updatePassword(e.target.value)}
                            id="name"
                            variant="standard"
                        />}
                  </TableCell>
                  <TableCell align="left" sx={{border: 0, color: "#717784", backgroundColor: "#F2F4F7"}}>
                    <Box>
                      {!showPassword ? <Box sx={{cursor: "pointer"}} onClick={() => setShowPassword(true)}><EditIcon/> </Box> : <>
                        <Button
                            onClick={cancel}
                            sx={{marginRight: "24px",
                              "&:hover" : {
                                backgroundColor: "#F1F3F5",
                              },
                              backgroundColor: "#F1F3F5", color: "black"}}
                            variant={"contained"}>
                          בטל
                        </Button>
                        <Button
                            onClick={savePassword}
                            sx={{marginRight: "24px"}}
                            variant={"contained"}>
                          שמור
                        </Button>
                      </>}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
              </Table>
            </TableContainer>
          </Card>
      </>
  );
};

export default SettingPage;
