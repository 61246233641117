import Dialog from "./shared/Dialog";
import {useEffect, useState} from "react";
import {getTemplates} from "../services/TemplatesService";
import {Box, Button, MenuItem, Typography} from "@mui/material";
import SelectCom from "./SelectCom";
import {experienceLevels} from "../enums/ExperienceLevels";
import {EquipmentTypes} from "../enums/EquipmentType";
import {mainMuscles} from "../enums/MainMuscles";
import {SecondaryMuscles} from "../enums/SecondaryMuscles";

const ChangeProgramDialog = ({isOpen, close, handleChangeProgram, trainee}) => {

  const [availableTemplates, setAvailableTemplates] = useState([])

  const [selectedTemplate, setSelectedTemplate] = useState();

  const [profileFields, setProfileFields] = useState({
    experienceLevel: trainee.experienceLevel ? trainee.experienceLevel : "null",
    numOfWorkouts: trainee.numOfDaysTraining ? trainee.numOfDaysTraining : "null",
    mainMuscle: "null",
    secondaryMuscle: "null",
    secondaryMuscle2: "null",
    equipmentType: trainee.equipmentType ? trainee.equipmentType : "null"
  })

  const updateProfileField = (value, keyName) => {
    setProfileFields(prv => {
      return {
        ...prv,
        [keyName] : value
      };
    })
  }

  const canFetchTemplates = () => {
    return profileFields.experienceLevel !== "null" &&
        profileFields.numOfWorkouts !== "null" &&
        profileFields.targetMuscle !== "null" &&
        profileFields.equipmentType !== "null";
  }

  useEffect(() => {
    if (canFetchTemplates()){
      getTemplates(0, 50,
          profileFields.numOfWorkouts,
          profileFields.experienceLevel,
          null,
          profileFields.mainMuscle,
          profileFields.equipmentType,
          true,
          profileFields.secondaryMuscle,
          profileFields.secondaryMuscle2,
          trainee.gender)
          .then(res => setAvailableTemplates(res.data.content))
    }
  }, [profileFields])

  return (
      <Dialog title={"תוכנית"} close={close} isOpen={isOpen}>
        <Box
            sx={{
              display: "flex",
              alignItems: "top",
              justifyContent: "space-between",
              paddingTop: "40px",
              paddingLeft: "100px",
              paddingRight: "100px"
            }}>
          <Box sx={{width: "320px"}}>
            <Typography sx={{marginBottom: "8px"}}>
              *רמת מתאמן:
            </Typography>
            <SelectCom
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"רמת מתאמן"}
                contrast={"black"}
                height={"56px"}
                value={profileFields.experienceLevel}
                onChange={e => updateProfileField(e, "experienceLevel")}
                defaultValue={"null"}>
              {experienceLevels.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            <Typography sx={{marginTop: "24px", marginBottom: "8px"}}>
              *מספר אימונים בשבוע:
            </Typography>
            <SelectCom
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"מספר אימונים בשבוע"}
                contrast={"black"}
                height={"56px"}
                value={profileFields.numOfWorkouts}
                onChange={e => updateProfileField(e, "numOfWorkouts")}
                defaultValue={"null"}>
              {Array.from(Array(7).keys()).map((_, i) => {
                return <MenuItem key={i} value={i+1}>{i+1}</MenuItem>
              })}
            </SelectCom>
            <Typography sx={{marginTop: "24px", marginBottom: "8px"}}>
              *שריר ראשי:
            </Typography>
            <SelectCom
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"שריר מטרה"}
                contrast={"black"}
                height={"56px"}
                value={profileFields.mainMuscle}
                onChange={e => updateProfileField(e, "mainMuscle")}
                defaultValue={"null"}>
              {mainMuscles.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
          </Box>
          <Box sx={{marginRight: "80px", width: "320px"}}>
            <Typography sx={{marginBottom: "8px"}}>
              שריר ספציפי:
            </Typography>
            <SelectCom
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"שריר מטרה 2"}
                contrast={"black"}
                height={"56px"}
                value={profileFields.secondaryMuscle}
                onChange={e => updateProfileField(e, "secondaryMuscle")}
                defaultValue={"null"}>
              {SecondaryMuscles.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            <Typography sx={{marginBottom: "8px"}}>
              שריר ספציפי 2:
            </Typography>
            <SelectCom
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"שריר מטרה 2"}
                contrast={"black"}
                height={"56px"}
                value={profileFields.secondaryMuscle2}
                onChange={e => updateProfileField(e, "secondaryMuscle2")}
                defaultValue={"null"}>
              {SecondaryMuscles.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            <Typography sx={{marginBottom: "8px", marginTop: "24px", }}>
              *ציוד קיים:
            </Typography>
            <SelectCom
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"ציוד קיים"}
                contrast={"black"}
                height={"56px"}
                value={profileFields.equipmentType}
                onChange={e => updateProfileField(e, "equipmentType")}
                defaultValue={"null"}>
              {EquipmentTypes.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            <Typography sx={{marginBottom: "8px", marginTop: "24px", }}>
              *סוג פרופיל:
            </Typography>
            <SelectCom
                disabled={!(profileFields.experienceLevel !== "null" &&
                    profileFields.numOfWorkouts !== "null" &&
                    profileFields.targetMuscle !== "null" &&
                    profileFields.equipmentType !== "null")}
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"סוג פרופיל"}
                contrast={"black"}
                height={"56px"}
                value={selectedTemplate}
                onChange={e => setSelectedTemplate(e)}
                defaultValue={"null"}>
              {availableTemplates.map((value, i) => {
                return <MenuItem key={i} value={value.id} sx={{display: "flex", alignItems: "center"}}>
                  <Box>
                    <Typography sx={{marginLeft: "8px"}}>{value.nameHeb}</Typography>
                    <Box sx={{display: "flex", alignItems: "right", justifyContent: "center"}}>
                      <Typography sx={{fontSize: "12px", color: "#57837e"}}>{value.numOfWorkouts}</Typography>
                      <Typography sx={{fontSize: "12px", color: "#57837e"}}>{value.gender === "FEMALE" && "נקבה"}
                        -
                      {value.gender === "MALE" && "זכר"}
                        {!value.gender && "אין מידע"}</Typography>
                      <Typography sx={{fontSize: "12px", color: "#57837e"}}>
                        -{value.experienceLevel ? experienceLevels.find(lvl => lvl.value === value.experienceLevel).name :  "אין מידע"}
                      </Typography>
                      <Typography sx={{fontSize: "12px", color: "#57837e"}}>
                        -{value.mainMuscle ? mainMuscles.find(lvl => lvl.value === value.mainMuscle).name :  "אין מידע"}
                      </Typography>
                      <Typography sx={{fontSize: "12px", color: "#57837e"}}>
                        -{value.secondaryMuscle ? SecondaryMuscles.find(lvl => lvl.value === value.secondaryMuscle).name :  "אין מידע"}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
              })}
            </SelectCom>
          </Box>
        </Box>
        <Box sx={{textAlign: "center", marginTop: "40px", pb: "40px"}}>
          <Button
              onClick={close}
              sx={{backgroundColor: "#F1F3F5", color: "black"}}
              variant={"contained"}>
            בטל
          </Button>
          <Button
              disabled={!selectedTemplate || selectedTemplate === 'null'}
              onClick={() => handleChangeProgram(selectedTemplate)}
              sx={{marginRight: "24px"}}
              variant={"contained"}>
            עדכן לתוכנית חדשה
          </Button>
        </Box>
      </Dialog>
  );
};

export default ChangeProgramDialog;
