import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import ExerciseCard from "./ExerciseCard";

const MiniExercises = ({exerciseState, fetchNextPage, reload}) => {

  return (<Card elevation={0} sx={{overflow: "auto",
    overflowY: "hidden"
    , marginTop: {md: "20px", xs: "70px"}}}>
    <InfiniteScroll
        initialLoad={true}
        hasMore={exerciseState.hasMoreData}
        loadMore={fetchNextPage}
        loader={

          <Box key={1}
              sx={{display: "flex", justifyContent: "center", margin: "16px" }}
          >
            <CircularProgress size={150} sx={{ color: "primary.main"}} />
          </Box>
        }
    >
      {exerciseState.exercises.length || exerciseState.isLoading ? (
          <Box
              sx={{ margin: { md: "15px", xs: "15px" }}}
          >
            <TableContainer>
              <Table sx={{"&.MuiTable-root" : {
                  borderCollapse: "separate",
                  borderSpacing: "0 15px",
                }}}>
                <TableHead>
                  <TableRow>
                    <TableCell align="right">
                      שם תרגיל
                    </TableCell>
                    <TableCell align="right">
                      קבוצת שרירים
                    </TableCell>
                    <TableCell align="right">
                      שריר
                    </TableCell>
                    <TableCell align="right">
                      סוג התנגדות
                    </TableCell>
                    <TableCell align="left">
                      תמונה
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
            {exerciseState.exercises.map((exercise, i) => (
                <ExerciseCard
                    reload={reload}
                    exercise={exercise}
                    key={exercise.id}
                />))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
      ) : (
          <Box
              sx={{ margin: { md: "15px auto", xs: "15px" }}}
          >
            <Typography sx={{ fontSize: "20px",
              fontWeight: "700",
              color: "primary.main",
              textAlign: "center",}}>
              אין תוצאות
            </Typography>
          </Box>
      )}
    </InfiniteScroll>
  </Card>)
};

export default MiniExercises;
