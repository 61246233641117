import {
  Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import {ReactComponent as CheckFalseIcon} from '../assets/images/check-false.svg';
import {ReactComponent as CheckTrueIcon} from '../assets/images/done_green.svg';
import {ReactComponent as DoneOvalIcon} from '../assets/images/done_oval.svg';
import {ReactComponent as UndoneOvalIcon} from '../assets/images/undone_oval.svg';
import {ReactComponent as LeftArrowIcon} from '../assets/images/left-arrow.svg';
import WeekChip from "./WeekChip";
import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";

const Block = ({programId, block, index}) => {
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const params = useParams();
  const handleClick = (e, weekIdx) => {
    e.stopPropagation();
    navigate(`/app/clients/${params.userId}/${programId}/week/${block.index}/${weekIdx}`)
  };

  return (
      <Paper
          onClick={() => setOpen(prv => !prv)}
          sx={{backgroundColor: "#FCFCFD",
        border: "1.5px solid #F2F4F7"
        ,"&:hover" : {
        backgroundColor: open ? "#FCFCFD" : "rgba(185,217,213,0.41)"
        },
        cursor: "pointer"}}>
        <Box sx={{margin: "24px" }}>
          <Typography sx={{
            fontWeight: 500,
            fontSize: "28px",
            color: "primary.light"
          }}> בלוק {index}</Typography>
          <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
            <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: "27px",
              marginLeft: "6px",
              color: "primary.light"
            }}
            >{!block.finished ?  "לא בוצע" : "בוצע"}</Typography>
            {!block.finished ? <CheckFalseIcon/> : <CheckTrueIcon/>}
          </Box>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Box sx={{display: "flex"}}>
              {block.weeks.sort((a, b) => b.index < a.index ? 1 : -1).map((week, index) => {
                return  <WeekChip key={index} number={index + 1} isDone={week.finished}/>
              })}
            </Box>
            <Box sx={{display: "flex"}}>
            <Typography sx={{
              fontSize: "20px",
              fontWeight: 400,
              lineHeight: "27px",
              marginLeft: "6px",
              color: "primary.light"
            }}>תאריך יצירה: </Typography>
            <Typography sx={{
              fontSize: "20px",
              fontWeight: 400,
              lineHeight: "27px",
              marginLeft: "6px",
              color: "primary.light"
            }}>{moment(block.createdDate)?.format("DD/MM/YY")}</Typography>
            </Box>
          </Box>
        </Box>
        {open && block && <Box>
          <TableContainer>
            <Table>
              <TableHead sx={{backgroundColor: "#F9FAFB"}}>
                <TableRow>
                  <TableCell align={"right"} sx={{borderBottom: 0,
                    padding: "8px", color: "#718096",
                    backgroundColor: "#F9FAFB"}}>מספר שבוע
                  </TableCell>
                  <TableCell align={"right"} sx={{borderBottom: 0,padding: "8px", color: "#718096",
                    backgroundColor: "#F9FAFB"}}>בוצע
                  </TableCell>
                  <TableCell align={"left"} sx={{borderBottom: 0,padding: "8px", color: "#718096",
                    backgroundColor: "#F9FAFB"}}>תאריך יצירה
                  </TableCell>
                  <TableCell align={"left"} sx={{borderBottom: 0,padding: "8px", color: "#718096",
                    backgroundColor: "#F9FAFB"}}>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {block.weeks.sort((a, b) => b.index < a.index ? 1 : -1).map((week, i) => {
                  return <TableRow onClick={(e) => handleClick(e, i)} >
                    <TableCell
                        align={"right"} sx={{borderBottom: 0,
                      padding: "8px", color: "#718096",
                      backgroundColor: "#FCFCFD"}}> שבוע  {i+1}
                    </TableCell>
                    <TableCell align={"right"} sx={{borderBottom: 0,padding: "8px", color: "#718096",
                      backgroundColor: "#FCFCFD"}}>{week.finished ? <DoneOvalIcon/> : <UndoneOvalIcon/>}
                    </TableCell>
                    <TableCell align={"left"} sx={{borderBottom: 0,padding: "8px", color: "#718096",
                      backgroundColor: "#FCFCFD"}}>{moment(week.createdDate).format("DD/MM/YY")}
                    </TableCell>
                    <TableCell align={"left"} sx={{borderBottom: 0,padding: "8px", color: "#718096",
                      backgroundColor: "#FCFCFD"}}><LeftArrowIcon/>
                    </TableCell>
                  </TableRow>
                })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>}
      </Paper>
  );
};

export default Block;
