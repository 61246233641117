import {Box, Button} from "@mui/material";
import {ReactComponent as BackIcon} from "../assets/images/backIcon.svg";
import {useNavigate, useParams} from "react-router-dom";
import {ReactComponent as DeleteIcon} from "../assets/images/delete.svg";
import {ReactComponent as EditIcon} from "../assets/images/edit.svg";
import {useEffect, useState} from "react";
import {
  deleteTemplate,
  disableTemplate, duplicateTemplate,
  enableTemplate,
  getTemplate,
  updateMetaData,
  updateTemplate
} from "../services/TemplatesService";
import TemplateProfileSection from "../componenets/templates/TemplateProfileSection";
import TemplatePreparationSection from "../componenets/templates/TemplatePreparationSection";
import TemplateEditMetaData from "../componenets/templates/TemplateEditMetaData";
import TemplateNumbers from "../componenets/templates/TemplateNumbers";
import TemplateBlockSection from "../componenets/templates/TemplateBlockSection";
import cloneDeep from 'lodash/cloneDeep';
import {LoadingButton} from "@mui/lab";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";
import DeleteDialog from "../componenets/DeleteDialog";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const TemplatePage = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [template, setTemplate] = useState();
  const [editMode, setEditMode] = useState(false);
  const [numberOfWorkouts, setNumberOfWorkouts] = useState(0);
  const [numberOfWeeks, setNumberOfWeeks] = useState(0);
  const [numberOfBlocks, setNumberOfBlocks] = useState(0);
  const [profileEdit, setProfileEdit] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);


  const {notificationHandler} = useMessageContext();
  useEffect(() => {
    getTemplate(params.templateId)
        .then(r =>{
          setTemplate(r.data);
          setNumberOfWorkouts(r.data.numOfWorkouts);
          let numOfWeeks = r.data.numOfWeeks;
          setNumberOfWeeks(numOfWeeks === 0 ? numOfWeeks : (numOfWeeks - 1) / r.data.blocks.length);
          setNumberOfBlocks(r.data.blocks.length)
        }).catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
  }, [editMode]);


  const handleDeleteTemplate = () => {
    setSaveLoading(true);
    deleteTemplate(params.templateId)
        .then(() => setSaveLoading(false))
        .then(() => navigate("/app/templates"))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
        .catch(() => setSaveLoading(false));
  }

  const handleDuplicateTemplate = () => {
    setSaveLoading(true);
    duplicateTemplate(params.templateId)
        .then(() => setSaveLoading(false))
        .then(() => navigate("/app/templates"))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
        .catch(() => setSaveLoading(false));
  }

  const updateNumberOfBlocks = (newVal) => {
    setTemplate(prv => {
      let newTemplate = {...prv};

      if (newTemplate.blocks.length === 0) {
        newTemplate.blocks = [{
          index: 0,
          weeks: [
            {
              index: 0,
              workouts: [{
                index: 0,
                exercises: []
              }]
            }
          ],
        }]
      }

      while (newVal > newTemplate.blocks.length) {
        const firstBlock = newTemplate.blocks.at(0);
        let clonedBlock = cloneDeep(firstBlock);
        clonedBlock.id = null;
        clonedBlock.index = newTemplate.blocks.length
        clonedBlock.weeks.forEach(week => {
          week.id = null;
          week.workouts.forEach(workout => {
            workout.id = null;
            workout.exercises.forEach(ex => {
              ex.id = null;
              ex.intervals = [];
            })
          })
        })
        newTemplate.blocks.push(clonedBlock);
      }

      while (newVal < newTemplate.blocks.length) {
        newTemplate.blocks.pop();
      }

      newTemplate.numOfWeeks = (newVal * numberOfWeeks) +1;
      return newTemplate;
    });

    setNumberOfBlocks(newVal);
  }

  const updateNumberOfWeeks = (newVal) => {
    setTemplate(prv => {
      let newTemplate = {...prv};

      let clonedFirstWeek;
      newTemplate.blocks?.sort((a, b) => a.index > b.index ? 1 : -1).forEach(block => {
        if (block.weeks?.sort((a, b) => a.index > b.index ? 1 : -1).length === 0) {
          block.weeks.sort((a, b) => a.index > b.index ? 1 : -1).push({
            index: block.weeks.length,
            workouts: [{
              exercises: []
            }]
          })
        }

        clonedFirstWeek = cloneDeep(block.weeks.sort((a, b) => a.index > b.index ? 1 : -1).at(0));

        clonedFirstWeek.id = null;

        clonedFirstWeek.workouts.sort((a, b) => a.index > b.index ? 1 : -1).forEach(workout => {
          workout.id = null;
          workout.exercises.sort((a, b) => a.index > b.index ? 1 : -1).forEach(ex => {
            ex.id = null
            ex.intervals = []
          })
        })

        while (newVal > block.weeks.length) {
          block.weeks.push(cloneDeep(clonedFirstWeek));
        }

        while (newVal < block.weeks.length) {
          block.weeks.sort((a, b) => a.index > b.index ? 1 : -1).pop();
        }
      })

      newTemplate.blocks.sort((a, b) => a.index > b.index ? 1 : -1).at(0).weeks.push(clonedFirstWeek);


      newTemplate.blocks.sort((a, b) => a.index > b.index ? 1 : -1).forEach(block => {
        block.weeks.sort((a, b) => a.index > b.index ? 1 : -1).forEach((week, i) => {
          week.index = i;
        })
      })

      newTemplate.numOfWeeks = (newVal * newTemplate.blocks.length) +1;
      return newTemplate;
    })

    setNumberOfWeeks(newVal);
  }

  const updateNumberOfWorkouts = (newVal) => {
    setTemplate(prv => {
      let newTemplate = {...prv};

      newTemplate.blocks?.sort((a, b) => a.index > b.index ? 1 : -1).forEach(block => {
        block.weeks.sort((a, b) => a.index > b.index ? 1 : -1).forEach(week => {

            while (week.workouts.length < newVal) {
              week.workouts.sort((a, b) => a.index > b.index ? 1 : -1).push({
                index: week.workouts.length,
                exercises: []
              });
            }

          while (week.workouts.sort((a, b) => a.index > b.index ? 1 : -1).length > newVal) {
            week.workouts.pop();
          }
        })
      })

      newTemplate.numOfWorkouts = newVal;
      return newTemplate;
    });

    setNumberOfWorkouts(newVal);
  }

  const saveTemplate = () => {
    setSaveLoading(true);
    updateTemplate(template)
        .then(res => {
          setTemplate(res.data);
          navigate(0)
        })
        .then(() => setSaveLoading(false))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
          setSaveLoading(false);
        })
  }

  const enableDisableTemplate = () => {
    if (template.enabled) {
      disableTemplate(template.id)
          .then(() => navigate(0))
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
    } else {
      enableTemplate(template.id)
          .then(() => navigate(0))
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
    }
  }

  const handleSaveMetaData = (metaData) => {
    updateMetaData(metaData)
          .then(() => navigate(0))
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
  }

  return (
      <>
        <DeleteDialog handleClose={() => setDeleteDialogOpen(false)} open={deleteDialogOpen} handleClickOpen={handleDeleteTemplate}/>
        <Box sx={{display: "flex", justifyContent: "flex-end", marginLeft: "20px", marginTop: "30px", cursor: "pointer"}}
             onClick={() => navigate(-1)}>
          <BackIcon/>
        </Box>
        <Box sx={{marginTop: "20px", marginRight: "40px"}}>
          <Button variant="outlined"
                  onClick={() => setDeleteDialogOpen(true)}
                  sx={{backgroundColor: "#FCFCFD", color: "#27272E", height: "44px", width: "78px", "& .MuiButton-endIcon" : {
                      marginLeft: "-4px",
                      marginTop: "2px",
                      borderRadius: "8px",
                      marginRight: "8px",
                    }}} endIcon={<DeleteIcon/>}
          >
            מחק
          </Button>
          <Button variant="outlined"
                  onClick={handleDuplicateTemplate}
                  sx={{backgroundColor: "#FCFCFD",
                    mr: "6px",
                    color: "#27272E", height: "44px", width: "135px", "& .MuiButton-endIcon" : {
                      marginLeft: "-4px",
                      marginTop: "2px",
                      borderRadius: "8px",
                      marginRight: "8px",
                    }}} endIcon={<ContentCopyIcon/>}
          >
            שכפל תוכנית
          </Button>
          <Button variant="outlined"
                  onClick={() => setEditMode(prv => !prv)}
                  sx={{backgroundColor: "#FCFCFD",
                      marginRight: "8px",
                      color: "#27272E",
                      height: "44px",
                    "& .MuiButton-endIcon" : {
                      marginLeft: "-4px",
                      marginTop: "2px",
                      borderRadius: "8px",
                      marginRight: "8px",
                    }}} endIcon={<EditIcon/>}
          >
            {editMode ? "בטל עריכה" : "עריכה"}
          </Button>
          <Button variant="text"
                  onClick={enableDisableTemplate}
                  sx={{color: template && template.enabled ? "#F16063" : "#1a7c73",
                    marginRight: "8px",
                    height: "44px",
                    width: "78px"}}
          >
            {template && template.enabled ? "חסום" : "הפעל"}
          </Button>
        </Box>
        <Box sx={{marginTop: "20px", marginRight: "40px", marginLeft: "40px"}}>
          {template && <>
            <TemplateEditMetaData handleSubmit={handleSaveMetaData} open={profileEdit} close={() => setProfileEdit(false)} template={template}/>
            <TemplateProfileSection setProfileEdit={setProfileEdit} template={template}/>
            <TemplateNumbers setNumberOfBlocks={updateNumberOfBlocks} numberOfBlocks={numberOfBlocks} numberOfWeeks={numberOfWeeks} updateNumberOfWeeks={updateNumberOfWeeks} numberOfWorkouts={numberOfWorkouts} updateNumberOfWorkouts={updateNumberOfWorkouts} editMode={editMode}/>
            <TemplatePreparationSection setTemplate={setTemplate} template={template} editMode={editMode}/>
            <TemplateBlockSection setTemplate={setTemplate} template={template} numberOfBlocks={numberOfBlocks} numberOfWeeks={numberOfWeeks} editMode={editMode}/>
          </>}

          {editMode && <Box sx={{display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px"}}>

            <Button sx={{color: "#F16063"}} onClick={() => setEditMode(false)} variant="text">בטל</Button>
            <LoadingButton variant="contained"
                   loading={saveLoading}
                    onClick={saveTemplate}
                    sx={{marginRight: "24px"}}>שמור</LoadingButton>
          </Box>}
        </Box>
        </>
        );

};

export default TemplatePage;
