export const WeightSets = [
    "DUMBBELL_A",
    "DUMBBELL_B",
    "DUMBBELL_C",
    "DUMBBELL_LBS",
    "CABLE_CROSS_A",
    "CABLE_CROSS_B",
    "CABLE_CROSS_C",
    "CABLE_CROSS_D",
    "CABLE_CROSS_E",
    "CABLE_CROSS_F",
    "SELECTORIZED_A",
    "SELECTORIZED_B",
    "SELECTORIZED_C",
    "SELECTORIZED_D"
]
