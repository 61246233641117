import {Box, Button, CardContent, Hidden, TextField, Typography} from "@mui/material";
import {ReactComponent as CloseIcon} from "../assets/images/close.svg";
import {ReactComponent as LogoIcon} from "../assets/images/logo.svg";

const ForgottenPasswordPasswordStep = ({password, setPassword, close, resetPassword}) => {

  return (
      <>
      <Box sx={{display: "flex",
        flexDirection: "column"}}>
        <CardContent sx={{paddingBottom: 0,paddingLeft: "30px", display:"flex", justifyContent: "end"}}>
          <Box sx={{cursor: "pointer"}} onClick={close}>
            <CloseIcon/>
          </Box>
        </CardContent>
        <CardContent sx={{padding: 0, marginTop: "12px", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Typography sx={{fontWeight: {md: 700, xs: "600"}, fontSize: {md: "40px", xs: "28px"}, lineHeight: {md:"52px", xs: "38px"}}} color="primary.light">הגדר סיסמה חדשה</Typography>
        </CardContent>
        <CardContent>
          <Typography sx={{marginLeft: "auto", marginRight: "auto", color:"#718096", fontWeight: {md:400, xs: 600}, fontSize: {md: "20px", xs: "14px"}, lineHeight: {md: "136.02%", xs: "18.31px"}, textAlign: "center"}} maxWidth="402px">
            הזן את הסיסמה החדשה שלך למטה </Typography>
        </CardContent>
        <CardContent >
          <Box sx={{width: "90%", maxWidth: "414px", marginRight: "auto", marginLeft: "auto"}}>
            <Typography color="primary.light">סיסמה חדשה</Typography>
            <TextField value={password.password} onChange={(event) => setPassword(prv => {
              return {
                ...prv,
                password: event.target.value
              }
            })} sx={{width: "100%"}}  type={"password"} id="email" variant="outlined" />
          </Box>
        </CardContent>
        <CardContent >
          <Box sx={{width: "90%", maxWidth: "414px", marginRight: "auto", marginLeft: "auto"}}>
            <Typography color="primary.light">סיסמה חדשה בשנית</Typography>
            <TextField value={password.secondPassword} onChange={(event) => setPassword(prv => {
              return {
                ...prv,
                secondPassword: event.target.value
              }
            })} sx={{width: "100%"}} type={"password"}  id="email" variant="outlined" />
          </Box>
        </CardContent>
        <CardContent>
          <Box sx={{width: "90%", maxWidth: "414px", marginRight: "auto", marginLeft: "auto", pb: "40px"}}>
            <Button
                onClick={resetPassword}
                disabled={password.password !== password.secondPassword || password.password === ''}
                sx={{width: "100%"}} variant="contained">
              שלח
            </Button>
          </Box>
        </CardContent>
      </Box>
  <Hidden mdUp>
    <Box>
      <CardContent sx={{textAlign: "center"}}>
        <LogoIcon/>
      </CardContent>
    </Box>
  </Hidden>
      </>
  );
};

export default ForgottenPasswordPasswordStep;
