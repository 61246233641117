import Chart from "react-apexcharts";

const CompanyStats = ({colors, data}) => {

  const state = {

    series: data?.map(data => data.value),
    name: "חלוקת לקוחות",
    options: {
      colors: [...colors],
      plotOptions: {
        pie: {
          expandOnClick: false, donut: {
            size: '75%',
          }
        }
      }, dataLabels: {
        dropShadow: {
          enabled: false,
        }, enabled: true, style: {
          colors: ['#FCFCFD', '#FCFCFD', '#4F535C'],
        },
      }, labels: data?.map(data => data.name), markers: {
        width: 25, height: 25,
      }, legend: {
        show: false,
      }, chart: {
        type: 'donut',
      }
    },
  };

  return (
      <>
      {data && <Chart options={state.options} height={200} series={state.series} type="donut"/>}
      </>
  );
};

export default CompanyStats;
