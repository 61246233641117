import {Box, Button, CardContent, TextField, Typography} from "@mui/material";
import {ReactComponent as CloseIcon} from "../assets/images/close.svg";

const ForgottenPasswordTokenStep = ({close , handleSendToken, handleVerifyToken, digits, setDigits}) => {

  return (
      <>
        <Box sx={{display: "flex",
          flexDirection: "column"}}>
          <CardContent sx={{paddingBottom: 0,paddingLeft: "30px", display:"flex", justifyContent: "end"}}>
            <Box sx={{cursor: "pointer"}} onClick={close}>
              <CloseIcon/>
            </Box>
          </CardContent>
          <CardContent sx={{padding: 0, marginTop: "12px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{fontWeight: {md: 700, xs: "600"}, fontSize: {md: "40px", xs: "28px"}, lineHeight: {md:"52px", xs: "38px"}}} color="primary.light">קוד אימות</Typography>
          </CardContent>
          <CardContent>
            <Typography sx={{marginLeft: "auto", marginRight: "auto", color:"#718096", fontWeight: {md:400, xs: 600}, fontSize: {md: "20px", xs: "14px"}, lineHeight: {md: "136.02%", xs: "18.31px"}, textAlign: "center"}} maxWidth="402px">
              העתק את הקוד שקיבלת במייל כדי לאמת
              שחזור חשבון זה
            </Typography>
          </CardContent>
          <CardContent sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <TextField
                inputProps={{maxLength: 1}}
                sx={{
                  ml:  "16px",
                  width: "58px",
                  '& input[type=number]': {
                    '-moz-appearance': 'textfield'
                  },
                  '& input[type=number]::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  }
                }}
                onChange={e => setDigits(prv => {
                  return {
                    ...prv,
                    fifth: e.target.value
                  }
                })}
                value={digits.fifth}
                type={"text"}
            />
            <TextField
                inputProps={{maxLength: 1}}
                sx={{
                  ml:  "16px",
                  width: "58px",
                  '& input[type=number]': {
                    '-moz-appearance': 'textfield'
                  },
                  '& input[type=number]::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  }
                }}
                onChange={e => setDigits(prv => {
                  return {
                    ...prv,
                    fourth: e.target.value
                  }
                })}
                value={digits.fourth}
                type={"text"}
            />
            <TextField
                inputProps={{maxLength: 1}}
                sx={{
                  ml:  "16px",
                  width: "58px",
                  '& input[type=number]': {
                    '-moz-appearance': 'textfield'
                  },
                  '& input[type=number]::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  }
                }}
                onChange={e => setDigits(prv => {
                  return {
                    ...prv,
                    third: e.target.value
                  }
                })}
                value={digits.third}
                type={"text"}
            />
            <TextField
                inputProps={{maxLength: 1}}
                sx={{
                  ml:  "16px",
                  width: "58px",
                  '& input[type=number]': {
                    '-moz-appearance': 'textfield'
                  },
                  '& input[type=number]::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  }
                }}
                onChange={e => setDigits(prv => {
                  return {
                    ...prv,
                    second: e.target.value
                  }
                })}
                value={digits.second}
                type={"text"}
            />
            <TextField
                inputProps={{maxLength: 1}}
                sx={{
                  width: "58px",
                  '& input[type=number]': {
                    '-moz-appearance': 'textfield'
                  },
                  '& input[type=number]::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  }
                }}
                onChange={e => setDigits(prv => {
                  return {
                    ...prv,
                    first: e.target.value
                  }
                })}
                value={digits.first}
                type={"text"}
            />
          </CardContent>
          <CardContent sx={{display: "flex", justifyContent: "center"}}>
            <Button
                onClick={handleSendToken}
                variant={"text"}>
              לא קבלתי, שלח קוד נוסף
            </Button>
          </CardContent>
          <CardContent>
            <Box sx={{width: "90%", maxWidth: "414px", marginRight: "auto", marginLeft: "auto", pb: "40px"}}>
              <Button
                  onClick={handleVerifyToken}
                  disabled={!digits.first ||
                      !digits.second ||
                      !digits.third ||
                      !digits.fourth ||
                      !digits.fifth }
                  sx={{width: "100%"}} variant="contained">
                אישור
              </Button>
            </Box>
          </CardContent>
        </Box>
      </>
  );
};

export default ForgottenPasswordTokenStep;
