import {Box, Divider, Paper, Typography} from "@mui/material";
import {ReactComponent as ProfileIcon} from "../../assets/images/profile.svg";
import {ReactComponent as EditIcon} from "../../assets/images/editProfile.svg";
import {ReactComponent as DeleteIcon} from "../../assets/images/delete-round.svg";
import moment from "moment";
import {useState} from "react";
import EditClientDialog from "./EditClientDialog";
import {experienceLevels} from "../../enums/ExperienceLevels";
import {EquipmentTypes} from "../../enums/EquipmentType";
import DeleteDialog from "../DeleteDialog";
import {deleteTrainee} from "../../services/TraineeService";
import {useNavigate} from "react-router-dom";
import {getErrorMessage} from "../../enums/ErrorMessages";
import {useMessageContext} from "../../contexts/MessageContext";
import Loader from "../Loader";

const ClientProfileSection = ({client, refetch}) => {

  const [editOpen, setEditOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {notificationHandler} = useMessageContext();

  const handleDeleteClient = () => {
    setLoading(true);
    deleteTrainee(client.id)
        .then(() => setLoading(false))
        .then(() => navigate(`/app/clients`))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
        .catch(() => setLoading(false));
  }

  return (
      <>
        <Loader loading={loading}/>
      <DeleteDialog handleClose={() => setDeleteDialogOpen(false)} open={deleteDialogOpen} handleClickOpen={handleDeleteClient}/>
      <Paper sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7",
        flexGrow: 0,
        borderRadius: "4px"}}>
        <EditClientDialog navigate={navigate} reload={refetch}  close={() => setEditOpen(false)} isOpen={editOpen} trainee={client}/>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Box sx={{display: "flex", alignItems: "center", marginRight: "8px"}}>
            <ProfileIcon/>
            <Typography sx={{fontWeight: 600,
              fontSize: "20px",
              lineHeight: "27.3px",
              color: "#4F535C",
              padding: "10px"}}>פרטים אישיים</Typography>
          </Box>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Box onClick={() => setEditOpen(true)} sx={{marginLeft: "8px", cursor: "pointer"}}>
              <EditIcon/>
            </Box>
            <Box onClick={() => setDeleteDialogOpen(true)} sx={{marginLeft: "8px", cursor: "pointer"}}>
              <DeleteIcon/>
            </Box>
          </Box>
        </Box>
        <Divider/>
        <Box sx={{marginTop: "44px", paddingBottom: "45px", marginRight: "21px",marginLeft: "28px",display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#717784"
            }}>שם מלא:</Typography>
            <Typography sx={{
              marginRight: "14px",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#1E1E1E"
            }}>{client.name}</Typography>
          </Box>
          <Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>גיל:</Typography>
              <Typography sx={{
                marginRight: "26px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{client.dateOfBirth ? moment().year() - moment(moment.unix(client.dateOfBirth)).year() : "אין מידע"}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>מגדר:</Typography>
              <Typography sx={{
                marginRight: "14px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>      {client.gender === "FEMALE" && "נקבה"}
                {client.gender === "MALE" && "זכר"}
                {!client.gender && "אין מידע"}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>מספר אימוניים בשבוע:</Typography>
              <Typography sx={{
                marginRight: "14px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{client.numOfDaysTraining ? client.numOfDaysTraining : "אין מידע"}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>מייל:</Typography>
              <Typography sx={{
                marginRight: "22px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{client.email}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>טלפון:</Typography>
              <Typography sx={{
                marginRight: "14px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{client.phoneNumber ? client.phoneNumber :  "אין מידע"}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>רמת מתאמן:</Typography>
              <Typography sx={{
                marginRight: "14px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{client.experienceLevel ? experienceLevels.find(lvl => lvl.value === client.experienceLevel).name :  "אין מידע"}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>סטטוס:</Typography>
              <Typography sx={{
                marginRight: "49px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{client.enabled ? "פעיל" : "חסום"}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>תאריך יצירה:</Typography>
              <Typography sx={{
                marginRight: "14px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{moment(moment.unix(client.createdDate))?.format("DD/MM/YY")}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>ציוד קיים:</Typography>
              <Typography sx={{
                marginRight: "14px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{client.equipmentType ? EquipmentTypes.find(lvl => lvl.value === client.equipmentType).name :  "אין מידע"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
      </>
  );
};

export default ClientProfileSection;
