import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {RTL} from "../cache/CacheRtl";

const SelectCom = ({background="#F9FAFB",
  defaultValue,
  contrast = "#F9FAFB",
  mainItem,
  onBlur,
  id,
  width,
  label,
  disabled,
  marginLeft,
  hidden,
  height ="42px",
  onChange,
  onChangeReal,
  value,
  children}) => {

  return (
      <RTL>
        <FormControl disabled={disabled} sx={{alignSelf: "end", marginLeft: {marginLeft}, display: `${hidden ? "none" : "auto"}`}}>
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select
              onBlur={onBlur}
              onChange={onChangeReal ? onChangeReal : (e) => onChange(e.target.value)}
              sx={{height: {height}, width: {width}, backgroundColor: {background}, color: {contrast}, maxHeight: {height}, "& .MuiSvgIcon-root" : {
                  color: {contrast}
                }}}
              id={id}
              label={label}
              value={value}
          >
            <MenuItem value={defaultValue}>
              <em>{mainItem}</em>
            </MenuItem>
            {children}
          </Select>
        </FormControl>
      </RTL>
  );
};

export default SelectCom;
