import {Box, Button, Divider, Paper, Typography} from "@mui/material";
import {ReactComponent as EditIcon} from "../../assets/images/editProfile.svg";
import AddCompanyDialog from "./AddCompanyDialog";
import {useState} from "react";
import {saveCompany} from "../../services/CompanyService";

const CompanyProfileSection = ({company, setLoadCompany}) => {

  const [openEdit, setOpenEdit] = useState(false);

  return (
      <Paper variant={"outlined"} sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7",
        flexGrow: 0,
        borderRadius: "4px"}}>
        <AddCompanyDialog setReload={setLoadCompany} open={openEdit} close={() => setOpenEdit(false)} company={company}/>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Box sx={{display: "flex", alignItems: "center", marginRight: "8px"}}>
            <Typography sx={{fontWeight: 600,
              fontSize: "20px",
              lineHeight: "27.3px",
              color: "#4F535C",
              padding: "10px"}}>פרטי חברה</Typography>
          </Box>
          <Box
              onClick={() => setOpenEdit(true)}
              sx={{marginLeft: "20px", cursor: "pointer"}}>
            <EditIcon/>
          </Box>

        </Box>
        <Divider/>
        <Box sx={{marginTop: "44px", paddingBottom: "45px", marginRight: "21px",marginLeft: "28px",display: "flex", alignItems: "flex-start"}}>
          <Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>שם חברה:</Typography>
              <Typography sx={{
                marginRight: "26px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{company.name}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>מיקום:</Typography>
              <Typography sx={{
                marginRight: "50px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{company.address}</Typography>
            </Box>
          </Box>
          <Box sx={{marginRight: "50px"}}>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>טלפון:</Typography>
              <Typography sx={{
                marginRight: "14px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{company.phoneNumber}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
  );
};
export default CompanyProfileSection;
