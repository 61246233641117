export const SecondaryMuscles = [
  {
    value: "GENERAL",
    name: "כללי"
  },
  {
    value: "UPPER_LEGS",
    name: "רגליים-שרשרת קדמית"
  },
  {
    value: "LOWER_LEGS",
    name: "רגליים-שרשרת אחורית"
  },
  {
    value: "BUT",
    name: "ישבן"
  },
  {
    value: "CHEST",
    name: "חזה"
  },
  {
    value: "BACK",
    name: "גב"
  },
  {
    value: "SHOULDERS",
    name: "כתפיים"
  },
  {
    value: "ARMS",
    name: "ידיים"
  },
  {
    value: "ARMS_CHEST",
    name: "חזה +ידיים"
  },
  {
    value: "ARMS_BACK",
    name: "גב + ידיים"
  },
  {
    value: "ARMS_SHOULDERS",
    name: "כתפיים + ידיים"
  },
  {
    value: "BACK_LEGS",
    name: "רגליים+גב"
  },
  {
    value: "CHEST_LEGS",
    name: "גב+ חזה"
  },
  {
    value: "CHEST_BACK",
    name: "גב + כתפיים"
  },
  {
    value: "LOWER_LEGS_SHOULDERS",
    name: "שרשרת אחורית +כתף אמצעית"
  },
  {
    value: "LOWER_LEGS_BACK",
    name: "  שרשרת אחורית + גב"
  },
  {
    value: "FRONT_ARM",
    name: "יד קדמית"
  },
  {
    value: "LOWER_ARM",
    name: "יד אחורית"
  },
  {
    value: "CALVES",
    name: "תאומים"
  },
]
