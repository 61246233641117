import AppBar from '@mui/material/AppBar';
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import {ThemeProvider, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {createTheme} from "@mui/material/styles";
import {useMenu} from "../contexts/MenuContext";
import {ReactComponent as LeftIcon} from '../assets/images/left-arrow.svg';
import {useNavigate} from "react-router-dom";

const TopNav = ({changeIsMenuOpen}) => {
  const {menuText, showBack} = useMenu();
  let navigate = useNavigate();

  const theme = createTheme({
    direction: "rtl",
    palette: {
      primary: {
        light: '#4F535C',
        main: '#ffffff',
        dark: '#425466',
        contrastText: '#27272E',
        error: '#F16063'
      },
    },
    typography: {
      fontFamily: [
        'Assistant',
        '-apple-system',
        'BlinkMacSystemFont',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });

  return (
      <Box sx={{ flexGrow: 1}}>
        <ThemeProvider theme={theme}>
        <AppBar variant="outlined"
                elevation={0}
                position="fixed"
                sx={{height: "70px", display: "flex", zIndex: 1199, justifyContent: "center", border: "0", top: 0}}>
          <Toolbar sx={{justifyContent: 'space-between', alignItems: "center"}}>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={changeIsMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Typography
                sx={{flex: 1,
                  textAlign: 'center',
                  marginLeft: "40px",
                  fontWeight: "700",
                  fontSize: "22px",
                  lineHeight: "28px",
                  textDecoration: "underline",
                  textUnderlineOffset: "8px",
                  textDecorationColor: "#57837E",
                  textDecorationThickness: '3px'}}>
              {menuText}
            </Typography>
            <Box sx={{marginLeft: "5px", cursor: "pointer", display: showBack ? "block" : "none"}}
                 onClick={() => navigate(-1)}>
              <LeftIcon/>
            </Box>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      </Box>
  );
};

export default TopNav;
