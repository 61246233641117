import MyDialog from "../shared/Dialog";
import {Box, Button, MenuItem, TextField, Typography} from "@mui/material";
import {useState} from "react";
import SelectCom from "../SelectCom";
import {EquipmentTypes} from "../../enums/EquipmentType";
import {InjuryNotes} from "../../enums/InjuryNotes";
import {experienceLevels} from "../../enums/ExperienceLevels";
import {mainMuscles} from "../../enums/MainMuscles";
import {SecondaryMuscles} from "../../enums/SecondaryMuscles";
import {TemplateGoals} from "../../enums/TemplateGoals";

const TemplateEditMetaData = ({open, close, template, handleSubmit}) => {

  const [templateMetaData, setTemplateMetaData] = useState({
    id: template ? template.id : null,
    name: template ? template.name : "",
    nameHeb: template ? template.nameHeb : "",
    gender : template ? template.gender : "null",
    experienceLevel: template ? template.experienceLevel : "null",
    mainMuscle: template ? template.mainMuscle : "null",
    secondaryMuscle: template ? template.secondaryMuscle : "null",
    secondaryMuscle2: template ? template.secondaryMuscle2 : "null",
    equipmentType: template ? template.equipmentType : "null",
    notes: template ? template.notes : "null",
    description: template ? template.description : "",
    descriptionHeb: template ? template.descriptionHeb : "",
  })

  const updateDescription =(description) => {
    setTemplateMetaData(prv => {
      return {
        ...prv,
        description: description
      }
    })
  }

  const updateDescriptionHeb =(descriptionHeb) => {
    setTemplateMetaData(prv => {
      return {
        ...prv,
        descriptionHeb: descriptionHeb
      }
    })
  }

  const updateName = (name) => {
    setTemplateMetaData(prv => {
      return {
        ...prv,
        name: name
      }
    })
  }

  const updateNameHeb = (name) => {
    setTemplateMetaData(prv => {
      return {
        ...prv,
        nameHeb: name
      }
    })
  }

  const updateGender = (gender) => {
    setTemplateMetaData(prv => {
      return {
        ...prv,
        gender: gender
      }
    })
  }

  const updateExperienceLevel = (experienceLevel) => {
    setTemplateMetaData(prv => {
      return {
        ...prv,
        experienceLevel: experienceLevel
      }
    })
  }

  const updateMainMuscle = (mainMuscle) => {
    setTemplateMetaData(prv => {
      return {
        ...prv,
        mainMuscle: mainMuscle
      }
    })
  }

  const updateSecondaryMuscle = (secondaryMuscle) => {
    setTemplateMetaData(prv => {
      return {
        ...prv,
        secondaryMuscle: secondaryMuscle
      }
    })
  }

  const updateSecondaryMuscle2 = (secondaryMuscle) => {
    setTemplateMetaData(prv => {
      return {
        ...prv,
        secondaryMuscle2: secondaryMuscle
      }
    })
  }

  const updateGoals = (goals) => {
    setTemplateMetaData(prv => {
      return {
        ...prv,
        goals: goals
      }
    })
  }

  const updateEquipmentType = (equipmentType) => {
    setTemplateMetaData(prv => {
      return {
        ...prv,
        equipmentType: equipmentType
      }
    })
  }

  const updateNotes = (notes) => {
    setTemplateMetaData(prv => {
      return {
        ...prv,
        notes: [notes]
      }
    })
  }
  return (
      <MyDialog title={"תוכנית אימון"}
              isOpen={open}
              close={close} actions={
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Button
              onClick={close}
              sx={{marginRight: "24px", backgroundColor: "#F1F3F5", color: "black"}}
              variant={"contained"}>
            בטל
          </Button>
          <Button
              disabled={templateMetaData.name === "" ||
                  templateMetaData.nameHeb === "" ||
                  templateMetaData.equipmentType === "null" ||
                  templateMetaData.experienceLevel === "null" ||
                  templateMetaData.targetMuscle === "null"}
              onClick={() => handleSubmit({...templateMetaData,
                gender: templateMetaData.gender === "null" ? null : templateMetaData.gender,
                notes: templateMetaData.notes === "null" ? [] : templateMetaData.notes,
                secondTargetMuscle: templateMetaData.secondTargetMuscle === "null" ? null : templateMetaData.secondTargetMuscle
              })}
              sx={{marginRight: "24px"}}
              variant={"contained"}>
            שמור
          </Button>
        </Box>
      }>

        <Box sx={{display: "flex", alignItems: "top", justifyContent: "space-between", padding: "40px"}}>
          <Box>
          <Typography>
            *שם התוכנית:
          </Typography>
            <TextField
                sx={{marginTop: "8px"}}
                fullWidth
                value={templateMetaData.nameHeb}
                onChange={(e) => updateNameHeb(e.target.value)}
                id="nameHeb"
                multiline
                variant="outlined"
            />
            <Typography sx={{marginTop: "24px"}}>
              *שם התוכנית באנגלית:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                value={templateMetaData.name}
                onChange={(e) => updateName(e.target.value)}
                id="name"
                multiline
                variant="outlined"
            />
            <SelectCom
                id={"gender"}
                width={"100px"}
                mainItem={"לא נבחר"}
                label={"מגדר"}
                contrast={"black"}
                marginLeft="8px"
                value={templateMetaData.gender}
                onChange={updateGender}
                defaultValue={"null"}>
              <MenuItem value={"MALE"} sx={{display: "flex", alignItems: "center"}}>
                <Typography sx={{marginLeft: "8px"}}>זכר</Typography>
              </MenuItem>
              <MenuItem value={"FEMALE"} sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Typography sx={{marginLeft: "8px"}}>נקבה</Typography>
              </MenuItem>
            </SelectCom>
          </Box>

          <Box sx={{marginRight: "80px"}}>
            <Typography sx={{marginBottom: "8px"}}>
              *רמת מתאמן:
            </Typography>
            <SelectCom
                id={"experienceLevel"}
                width={"280px"}
                height={"56px"}
                contrast={"black"}
                marginLeft="8px"
                value={templateMetaData.experienceLevel}
                onChange={updateExperienceLevel}
                mainItem={"לא נבחר"}
                defaultValue={'null'}>
              {experienceLevels.map((res, i) => {
                return  <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>

            <Typography sx={{marginBottom: "8px", marginTop: "24px"}}>
              *שריר ראשי:
            </Typography>
            <SelectCom
                id={"mainMuscle"}
                width={"280px"}
                height={"56px"}
                contrast={"black"}
                marginLeft="8px"
                value={templateMetaData.mainMuscle}
                onChange={updateMainMuscle}
                mainItem={"לא נבחר"}
                defaultValue={'null'}>
              {mainMuscles.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>

            <Typography sx={{marginBottom: "8px", marginTop: "24px"}}>
              *שריר ספציפי:
            </Typography>
            <SelectCom
                id={"secondaryMuscle"}
                width={"280px"}
                height={"56px"}
                contrast={"black"}
                marginLeft="8px"
                value={templateMetaData.secondaryMuscle}
                onChange={updateSecondaryMuscle}
                mainItem={"לא נבחר"}
                defaultValue={'null'}>
              {SecondaryMuscles.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            <Typography sx={{marginBottom: "8px", marginTop: "24px"}}>
              *שריר ספציפי 2:
            </Typography>
            <SelectCom
                id={"secondaryMuscle"}
                width={"280px"}
                height={"56px"}
                contrast={"black"}
                marginLeft="8px"
                value={templateMetaData.secondaryMuscle2}
                onChange={updateSecondaryMuscle2}
                mainItem={"לא נבחר"}
                defaultValue={'null'}>
              {SecondaryMuscles.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            <Typography sx={{marginTop: "8px"}}>
              *מטרה:
            </Typography>
            <SelectCom
                id={"secondaryMuscle"}
                width={"280px"}
                height={"56px"}
                contrast={"black"}
                marginLeft="8px"
                value={templateMetaData.goals}
                onChange={updateGoals}
                mainItem={"לא נבחר"}
                defaultValue={'null'}>
              {Object.keys(TemplateGoals).map((res, i) => {
                return <MenuItem key={i} value={res} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{TemplateGoals[res]}</Typography>
                </MenuItem>
              })}
            </SelectCom>
          </Box>
          <Box sx={{marginRight: "80px"}}>
            <Typography sx={{marginBottom: "8px"}}>
              *ציוד קיים
            </Typography>
            <SelectCom
                id={"equipmentType"}
                width={"280px"}
                height={"56px"}
                contrast={"black"}
                marginLeft="8px"
                value={templateMetaData.equipmentType}
                onChange={updateEquipmentType}
                mainItem={"לא נבחר"}
                defaultValue={'null'}>
              {EquipmentTypes.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>

            <Typography sx={{marginBottom: "8px", marginTop: "24px"}}>
              מגבלות/פציעות (אופציונלי)
            </Typography>
            <SelectCom
                id={"notes"}
                width={"280px"}
                height={"56px"}
                contrast={"black"}
                marginLeft="8px"
                value={templateMetaData.notes}
                onChange={updateNotes}
                mainItem={"לא נבחר"}
                defaultValue={"null"}>
              {InjuryNotes.map((value, i) => {
                return <MenuItem key={i} value={value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{value}</Typography>
                </MenuItem>
              })}
            </SelectCom>

            <Typography sx={{marginTop: "24px"}}>
              תיאור:
            </Typography>
            <TextField
                sx={{marginTop: "8px"}}
                fullWidth
                id={"descriptionHeb"}
                value={templateMetaData.descriptionHeb}
                onChange={(e) => updateDescriptionHeb(e.target.value)}
                multiline
                variant="outlined"
            />
            <Typography sx={{marginTop: "24px"}}>
              תיאור באנגלית:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                value={templateMetaData.description}
                onChange={(e) => updateDescription(e.target.value)}
                id={"description"}
                multiline
                variant="outlined"
            />
          </Box>
        </Box>
      </MyDialog>
  );
};

export default TemplateEditMetaData;
