import {
  Autocomplete,
  Box,
  IconButton,
  InputBase,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import {ReactComponent as SearchIcon} from "../assets/images/search.svg";
import SelectCom from "./SelectCom";
import {resistanceTypes} from "../enums/resistanceTypes";
import {specificMuscles} from "../enums/SpecificMuscle";
import {RTL} from "../cache/CacheRtl";
import {targetMuscles} from "../enums/TargetMuscles";

const ExerciseSearchBar = ({resistanceType,
  updateResistanceType,
  updateKeyword,
  keyword,
  updateSpecificMuscle,
  updateTargetMuscle,
  targetMuscle,
  specificMuscle}) => {

  return (
      <>
          <Box sx={{marginLeft: "16px", marginTop: "30px", display: "flex", justifyContent: "flex-end"}}>
            <Box sx={{display: "flex"}}>
              <RTL>
              <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={resistanceType}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    if (value == null) {
                      updateResistanceType({
                        name: "",
                        value: null
                      })
                    } else {
                      updateResistanceType(value)
                    }
                  }}
                  options={resistanceTypes}
                  sx={{width: 200, mr: "10px", ml: "10px", "& .MuiInputBase-root" : {
                      height: "46px"
                    }}}
                  renderInput={(params) => <TextField
                      sx={{height: "42px"}}
                      {...params} label="סוג התנגדות" />}
              />
              </RTL>
              <RTL>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={targetMuscle}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      if (value == null) {
                        updateTargetMuscle({
                          name: "",
                          value: null
                        })
                      } else {
                        updateTargetMuscle(value)
                      }
                    }}
                    options={targetMuscles}
                    sx={{width: 200, mr: "10px", ml: "10px", "& .MuiInputBase-root" : {
                      height: "46px"
                    }}}
                    renderInput={(params) => <TextField
                        size="small"
                        {...params} label="שריר" />}
                />
              </RTL>
              <SelectCom
                  label={"שריר ספציפי"}
                  value={specificMuscle}
                  onChange={updateSpecificMuscle}
                  mainItem={"הכול"}
                  width={"200px"}
                  contrast={"black"}
                  marginLeft="8px"
                  defaultValue={'ALL'}>
                {specificMuscles.map((res, i) => {
                  return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                    <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                  </MenuItem>
                })}
              </SelectCom>
                <Box sx={{backgroundColor: "#F9FAFB",
                  border: "1px solid #A9ABB0",
                  borderRadius: "8px",
                  maxHeight: "41px",
                  marginRight: "8px",
                  width: "auto",
                  display: "inline-flex",
                }}>
                  <IconButton disabled={true} sx={{ p: '12px', marginLeft: "6px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                      onChange={(newValue) => updateKeyword(newValue.target.value)}
                      value={keyword}
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="חיפוש לפי שם"
                  />
                </Box>
            </Box>
          </Box>
        </>
  );
};

export default ExerciseSearchBar;
