import {Outlet} from 'react-router-dom';
import {AuthGuard} from "../guards/AuthGuard";
import SideNav from "../componenets/nav/SideNav";
import {Box, Hidden} from "@mui/material";
import {useState} from "react";
import TopNav from "../componenets/TopNav";
import {MenuProvider} from "../contexts/MenuContext";
import {CompanyProvider} from "../contexts/CompanyContext";

const DashboardLayout = () => {
  const [isMiniNav, setIsMiniNav] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const changeIsMenuOpen = () => {
    setIsMenuOpen(prv => {
      return !prv;
    })
  };

  const changeMiniNavDir = () => {
    setIsMiniNav(prv => {
      return !prv;
    })
  }

  return (
      <AuthGuard>
        <CompanyProvider>
        <MenuProvider>
          <Hidden mdUp>
            <TopNav changeIsMenuOpen={changeIsMenuOpen}/>
          </Hidden>
          <SideNav changeIsMenuOpen={changeIsMenuOpen} isMiniNav={isMiniNav} changeMiniNavDir={changeMiniNavDir} isMenuOpen={isMenuOpen}/>
          <Box sx={{paddingRight: {md: isMiniNav ? "104px" : "345px", xs: 0}, marginTop: {xs: "65px", md: '0'}}}>
            <Outlet />
          </Box>
        </MenuProvider>
        </CompanyProvider>
      </AuthGuard>
  );
};

export default DashboardLayout;
