import {
  Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {getExercise} from "../../services/ExcersiceService";
import IntervalPage from "./IntervalPage";
import {getErrorMessage} from "../../enums/ErrorMessages";
import {useMessageContext} from "../../contexts/MessageContext";

const TemplateBlockExercise = ({editMode, exerciseIdx, template, setTemplate, workoutIdx, exercise, numberOfWeeks, numberOfBlocks}) => {

  const [exerciseDef, setExerciseDef] = useState();

  const {notificationHandler} = useMessageContext();

  const [intervalData, setIntervalData] = useState({
    open: false,
    blockIndex: 0,
    weekIndex: 0
  });

  const isEnabled = (blockIdx, weekIdx) => {
    let newWeekId = weekIdx;

    if (blockIdx === 0) {
      newWeekId++;
    }

    return template.blocks
        .at(blockIdx)?.weeks
        .at(newWeekId)?.workouts
        .at(workoutIdx-1)?.exercises.at(exerciseIdx-1)?.intervals?.length > 0;
  }

  const handleOpenInterval = (blockIndex, weekIndex) => {
    setIntervalData({
      open: true,
      blockIndex: blockIndex,
      weekIndex: weekIndex
    })
  }

  const closeInterval = () => {
    setIntervalData({
      open: false,
      blockIndex: 0,
      weekIndex: 0
    })
  }

  useEffect(() => {
    if (exercise.exerciseId) {
      getExercise(exercise.exerciseId).then(res => {
        setExerciseDef(res.data);
      }).catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
    }
  }, [exercise.exerciseId]);

  return (
      <Box sx={{display: "flex", flexDirection: "row",  alignItems: "center", marginTop: "24px"}}>
        {exerciseDef && <IntervalPage editMode={editMode} setTemplate={setTemplate} workoutIdx={workoutIdx} exerciseIdx={exerciseIdx} isOpen={intervalData.open} close={closeInterval} exerciseName={exerciseDef.name} template={template} blockIndex={intervalData.blockIndex} weekIndex={intervalData.weekIndex}/>}
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell width={"10px"} sx={{borderTop: "1px solid #E7E9EE", borderBottom: "1px solid #E7E9EE"}} align={"right"}>
                  <Typography sx={{color: "#69B8AF",
                    alignSelf: "flex-end",
                    paddingBottom: "8px",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "27px",
                  }}>{exerciseIdx}</Typography>
                </TableCell>
                <TableCell width={"150px"} sx={{borderTop: "1px solid #E7E9EE", borderBottom: "1px solid #E7E9EE"}} align={"right"}>
                  {exerciseDef && <Typography sx={{color: "#1E1E1E",
                    alignSelf: "flex-end",
                    width: "100px",
                    textOverflow: "ellipsis",
                    paddingBottom: "8px",
                    marginRight: "24px",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "24px",
                  }}>{exerciseDef.name}</Typography>}
                </TableCell>
                {Array.from(Array(numberOfBlocks)).map((_, blockIndex) => {
                  return <TableCell align={"right"} sx={{border: "1px solid #E7E9EE"}}>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                      <Typography sx={{
                        color: "#1E1E1E",
                        fontWeight: 700,
                        fontSize: "12px"
                      }}>בלוק {blockIndex} </Typography>
                    <Typography sx={{
                      marginRight: "4px",
                      color: "#4F535C",
                      fontWeight: 600,
                      fontSize: "12px"
                    }}> שבועות {(blockIndex * Math.floor(numberOfWeeks)) +1 } - {(blockIndex * Math.floor(numberOfWeeks) + Math.floor(numberOfWeeks))}</Typography>
                    </Box>
                      <Box sx={{display: "flex", justifyContent: "flex-start"}}>
                      {Array.from(Array(Math.floor(numberOfWeeks))).map((_, weekIndex) => {
                        return <Box
                            onClick={() => handleOpenInterval(blockIndex, weekIndex)}
                            sx={{
                          cursor: "pointer",
                          marginRight: "5px",
                          width: "10px",
                          marginTop: "5px",
                          border: "1px solid #E7E9EE",
                          padding: "12px 16px",
                          background: isEnabled(blockIndex, weekIndex) ? `#57837E` : "#FCFCFD",
                          borderRadius: "8px",
                        }}>
                          <Typography sx={{
                            color: isEnabled(blockIndex, weekIndex) ? `#FCFCFD` : "#27272E",
                            fontSize: "14px",
                            fontWeight: 600,
                            textAlign: "center",
                            lineHeight: "24px",
                          }}>{(weekIndex + 1) + (blockIndex * Math.floor(numberOfWeeks))}</Typography>
                        </Box>
                      })}
                    </Box>
                  </TableCell>})}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
  );
};

export default TemplateBlockExercise;
