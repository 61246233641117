import {ReactComponent as BackIcon} from "../assets/images/backIcon.svg";
import {ReactComponent as ForwordIcon} from "../assets/images/forward.svg";
import {
  Box, Button,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getClientWeightTable, updateWeightTable} from "../services/TraineeService";
import moment from "moment";
import {WeeklyRanges} from "../enums/WeeklyRanges";
import {GoalTypes} from "../enums/GoalTypes";
import {RTL} from "../cache/CacheRtl";
import Loader from "../componenets/Loader";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";

const ClientWeightTrackPage = () => {
  let navigate = useNavigate();
  const params = useParams();

  const [currentWeek, setCurrentWeek] = useState(moment().day(0));
  const [weightTracks, setWeightTracks] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  const {notificationHandler} = useMessageContext();

  const [columnEdit, setColumnEdit] = useState(-1);

  useEffect(() => {
    setLoading(true);
      getClientWeightTable(params.userId,
          moment(currentWeek).subtract(7, "weeks"),
          currentWeek)
          .then(res => {
            setWeightTracks(res.data.weeks);
            setLoading(false);
          }).catch(e => {
            notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
            setLoading(false);
      })

  }, [currentWeek, reload, params.userId])


  const onSave = () => {
    const row = weightTracks.at(columnEdit);

    updateWeightTable(params.userId, row)
        .then(() => setColumnEdit(-1))
        .then(() => setReload(prv => !prv))
        .then(() => notificationHandler.success("נתונים נשמרו בהצלחה"))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
  }

  const onCancel = () => {
    setReload(prv => !prv);
    setColumnEdit(-1);
  }

  const nextXWeeks = () => {
    setCurrentWeek(prv => {
      let x = prv.add(7, "weeks")
      return moment(x);
    })
  }

  const backXWeeks = () => {
    setCurrentWeek(prv => {
      let x = prv.subtract(7, "weeks")
      return moment(x);
    })
  }

  const getAverage = (record) => {
    let numberOfRecords = 0;
    let sum = 0

    if (record.sundayWeight) {
      numberOfRecords += 1;
      sum += record.sundayWeight;
    }

    if (record.mondayWeight) {
      numberOfRecords += 1;
      sum += record.mondayWeight;
    }

    if (record.tuesdayWeight) {
      numberOfRecords += 1;
      sum += record.tuesdayWeight;
    }

    if (record.wednesdayWeight) {
      numberOfRecords += 1;
      sum += record.wednesdayWeight;
    }

    if (record.thursdayWeight) {
      numberOfRecords += 1;
      sum += record.thursdayWeight;
    }

    if (record.fridayWeight) {
      numberOfRecords += 1;
      sum += record.fridayWeight;
    }

    if (record.saturdayWeight) {
      numberOfRecords += 1;
      sum += record.saturdayWeight;
    }

    if (numberOfRecords === 0)
      return 0;

    return (Math.round(sum / numberOfRecords * 100) / 100).toFixed(2);
  }

  const getTrend = (i) => {
    const current = weightTracks.at(i);
    const nextWeek = i+1 < weightTracks.length ? weightTracks.at(i+1) : current;

    if (getAverage(current) === 0 || getAverage(nextWeek) ===0) {
      return 0;
    }

    return roundNumber(getAverage(current) - getAverage(nextWeek));
  }

  const roundNumber = (number) => {
    return (Math.round(number * 100) / 100).toFixed(2);
  }

  const getTrendColor = (i) => {
    const trend = getTrend(i);

    const currentGoal = weightTracks.at(i).goal;

    switch (currentGoal) {
      case "LOSE_WEIGHT":
      case "MINI_CUT":
      case "CUTTING": {
        return trend < 0 ? "#66CB9F" : "#F16063";
      }
      case "GAIN_WEIGHT":
      case "REVERSE_DIET": {
        return trend > 0 ? "#66CB9F" : "#F16063";
      }
      case "MAINTENANCE": {
        const weeklyRange = weightTracks.at(i).weeklyRange;
        const foundRange = WeeklyRanges.filter(x => {
          return x.value === weeklyRange
        }).at(0);

        return trend >= foundRange.min && trend <= foundRange.max ? "#66CB9F" : "#F16063"
      }
    }
  }

  const updateSteps = (newValue, i) => {
    setWeightTracks(prv => {
      let newPrv = [...prv];
      newPrv.at(i).averageSteps = newValue;
      return newPrv;
    })

    setColumnEdit(i);
  }

  const updateGoal = (newValue, i) => {
    setWeightTracks(prv => {
      let newPrv = [...prv];
      newPrv.at(i).goal = newValue;
      return newPrv;
    })

    setColumnEdit(i);
  };

  const updateWeeklyRange = (newValue, i) => {
    setWeightTracks(prv => {
      let newPrv = [...prv];
      newPrv.at(i).weeklyRange = newValue;
      return newPrv;
    })

    setColumnEdit(i);
  };

  const updateCalories = (newValue, i) => {
    setWeightTracks(prv => {
      let newPrv = [...prv];
      newPrv.at(i).calories = newValue;
      return newPrv;
    })

    setColumnEdit(i);
  };

  const updateSupplements = (newValue, i) => {
    setWeightTracks(prv => {
      let newPrv = [...prv];
      newPrv.at(i).supplements = newValue;
      return newPrv;
    })

    setColumnEdit(i);
  };

  const updateNotes = (newValue, i) => {
    setWeightTracks(prv => {
      let newPrv = [...prv];
      newPrv.at(i).notes = newValue;
      return newPrv;
    })

    setColumnEdit(i);
  };

  const updateSundayWeight = (newValue, i) => {
    setWeightTracks(prv => {
      let newPrv = [...prv];
      newPrv.at(i).sundayWeight = newValue;
      return newPrv;
    })

    setColumnEdit(i);
  };

  const updateMondayWeight = (newValue, i) => {
    setWeightTracks(prv => {
      let newPrv = [...prv];
      newPrv.at(i).mondayWeight = newValue;
      return newPrv;
    })

    setColumnEdit(i);
  };

  const updateTuesdayWeight = (newValue, i) => {
    setWeightTracks(prv => {
      let newPrv = [...prv];
      newPrv.at(i).tuesdayWeight = newValue;
      return newPrv;
    })

    setColumnEdit(i);
  };

  const updateWednesdayWeight = (newValue, i) => {
    setWeightTracks(prv => {
      let newPrv = [...prv];
      newPrv.at(i).wednesdayWeight = newValue;
      return newPrv;
    })

    setColumnEdit(i);
  };

  const updateThursdayWeight = (newValue, i) => {
    setWeightTracks(prv => {
      let newPrv = [...prv];
      newPrv.at(i).thursdayWeight = newValue;
      return newPrv;
    })

    setColumnEdit(i);
  };

  const updateFridayWeight = (newValue, i) => {
    setWeightTracks(prv => {
      let newPrv = [...prv];
      newPrv.at(i).fridayWeight = newValue;
      return newPrv;
    })

    setColumnEdit(i);
  };

  const updateSaturdayWeight = (newValue, i) => {
    setWeightTracks(prv => {
      let newPrv = [...prv];
      newPrv.at(i).saturdayWeight = newValue;
      return newPrv;
    })

    setColumnEdit(i);
  };

  return (
      <Box>
        <Loader loading={loading}/>
        <Box sx={{display: "flex", justifyContent: "flex-end", marginLeft: "40px", marginTop: "30px", cursor: "pointer"}}
             onClick={() => navigate(-1)}>
          <BackIcon/>
        </Box>
        <Box sx={{marginRight: "40px"}}>
          <Typography sx={{
            fontSize: "36px",
            fontWeight: 700,
            lineHeight: "49px",
            color: "#27272E"
          }}>מעקב שקילות</Typography>
        </Box>
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Box onClick={nextXWeeks} sx={{cursor: "pointer"}}>
            <ForwordIcon/>
          </Box>
          <Typography sx={{
            fontSize: "16px",
            marginRight: "24px",
            marginLeft: "24px",
            fontWeight: 600,
            lineHeight: "25px"
          }}>{currentWeek.format("DD/MM/YYYY")} - {moment(currentWeek).subtract(6, "weeks").format("DD/MM/YYYY")}</Typography>
          <Box onClick={backXWeeks} sx={{cursor: "pointer"}}>
            <BackIcon/>
          </Box>
        </Box>

        <Box sx={{margin: "40px"}}>
        <TableContainer>
          <Table>
            <TableHead sx={{backgroundColor: "#F9FAFB"}}>
              <TableRow >
                <TableCell align={"right"} sx={{padding: "4px", color: "#FCFCFD", backgroundColor: "#446864"}}>
                  <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <Typography>תאריך</Typography>
                    <Typography>יום/שבוע</Typography>
                  </Box>

                </TableCell>
                {weightTracks.map((data, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{padding: "4px",color: "#FCFCFD", backgroundColor: "#446864"}}>
                      <Box key={i} sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <Typography>{moment(data.weekDate * 1000).format("DD/MM/YYYY")}</Typography>
                        <Typography>({moment(data.weekDate * 1000).week()})</Typography>
                      </Box>

                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow >
                <TableCell align={"right"} sx={{padding: "4px", color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>א</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{padding: "4px", color: "#4F535C", backgroundColor: "#F1F3F5"}}>
                      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <TextField
                            variant={"standard"}
                            multiline
                            disabled={columnEdit !== -1 && columnEdit !== i}
                            sx={{width: "120px"}}
                            onChange={e => updateSundayWeight(e.target.value, i)}
                            value={row.sundayWeight || 0}
                        />
                      </Box>

                    </TableCell>
                ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{padding: "4px", color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>ב</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{padding: "4px", color: "#4F535C", backgroundColor: "#F1F3F5"}}>
                      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <TextField
                            variant={"standard"}
                            multiline
                            disabled={columnEdit !== -1 && columnEdit !== i}
                            sx={{width: "120px"}}
                            onChange={e => updateMondayWeight(e.target.value, i)}
                            value={row.mondayWeight || 0}
                        />
                      </Box>

                    </TableCell>
                ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{padding: "4px", color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>ג</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{padding: "4px", color: "#4F535C", backgroundColor: "#F1F3F5"}}>
                      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <TextField
                            variant={"standard"}
                            multiline
                            disabled={columnEdit !== -1 && columnEdit !== i}
                            sx={{width: "120px"}}
                            onChange={e => updateTuesdayWeight(e.target.value, i)}
                            value={row.tuesdayWeight || 0}
                        />
                      </Box>

                    </TableCell>
                ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{padding: "4px", color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>ד</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{padding: "4px", color: "#4F535C", backgroundColor: "#F1F3F5"}}>
                      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <TextField
                            variant={"standard"}
                            multiline
                            disabled={columnEdit !== -1 && columnEdit !== i}
                            sx={{width: "120px"}}
                            onChange={e => updateWednesdayWeight(e.target.value, i)}
                            value={row.wednesdayWeight || 0}
                        />
                      </Box>

                    </TableCell>
                ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{padding: "4px", color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>ה</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{padding: "4px", color: "#4F535C", backgroundColor: "#F1F3F5"}}>
                      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <TextField
                            variant={"standard"}
                            multiline
                            disabled={columnEdit !== -1 && columnEdit !== i}
                            sx={{width: "120px"}}
                            onChange={e => updateThursdayWeight(e.target.value, i)}
                            value={row.thursdayWeight || 0}
                        />
                      </Box>

                    </TableCell>
                ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{padding: "4px", color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>ו</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{padding: "4px", color: "#4F535C", backgroundColor: "#F1F3F5"}}>
                      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <TextField
                            variant={"standard"}
                            multiline
                            disabled={columnEdit !== -1 && columnEdit !== i}
                            sx={{width: "120px"}}
                            onChange={e => updateFridayWeight(e.target.value, i)}
                            value={row.fridayWeight || 0}
                        />
                      </Box>

                    </TableCell>
                ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{padding: "4px", color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>ש</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{padding: "4px", color: "#4F535C", backgroundColor: "#F1F3F5"}}>
                      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <TextField
                            variant={"standard"}
                            multiline
                            disabled={columnEdit !== -1 && columnEdit !== i}
                            sx={{width: "120px"}}
                            onChange={e => updateSaturdayWeight(e.target.value, i)}
                            value={row.saturdayWeight || 0}
                        />
                      </Box>

                    </TableCell>
                ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{padding: "4px", color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>ממוצע</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{padding: "4px", color: "#4F535C", backgroundColor: "#FCFCFD"}}>
                      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <Typography>{getAverage(row)}
                            </Typography>
                      </Box>

                    </TableCell>
                ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{padding: "4px", color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>מגמה</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{padding: "4px", color: "#FCFCFD", backgroundColor: getTrendColor(i)}}>
                      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <Typography>{getTrend(i)}</Typography>
                      </Box>

                    </TableCell>
                ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>מטרה שבועית</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"center"}
                               sx={{color: "#4F535C", backgroundColor: "#FCFCFD"}}>
                      <RTL>
                        <FormControl fullWidth>
                          <Select
                              variant={"standard"}
                              disabled={columnEdit !== -1 && columnEdit !== i}
                              sx={{height: "48px", width: "120px"}}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={row.weeklyRange}
                              displayEmpty
                              onChange={(e) => updateWeeklyRange(e.target.value, i)}
                          >
                            {WeeklyRanges.map((res, i) => {
                              return <MenuItem key={i} value={res.value}>{res.name}</MenuItem>
                            })}
                          </Select>
                        </FormControl>
                      </RTL>

                    </TableCell>
                ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>מטרה כללית</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                  <TableCell align={"center"}
                                    sx={{color: "#4F535C", backgroundColor: "#FCFCFD"}}>
                      <RTL>
                        <FormControl fullWidth>
                          <Select
                              variant={"standard"}
                              disabled={columnEdit !== -1 && columnEdit !== i}
                              sx={{height: "48px", width: "120px"}}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={row.goal}
                              displayEmpty
                              onChange={(e) => updateGoal(e.target.value, i)}
                          >
                            {GoalTypes.map((res, i) => {
                              return <MenuItem key={i} value={res.value}>{res.name}</MenuItem>
                            })}
                          </Select>
                        </FormControl>
                      </RTL>
                  </TableCell>
                  ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>ממוצע צעדים</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{color: "#4F535C", backgroundColor: "#FCFCFD"}}>
                      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <TextField
                            variant={"standard"}
                            multiline
                            disabled={columnEdit !== -1 && columnEdit !== i}
                            sx={{width: "120px"}}
                            onChange={e => updateSteps(e.target.value, i)}
                            value={row.averageSteps || 0}
                        />
                      </Box>
                    </TableCell>
                ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>קלוריות</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{color: "#4F535C", backgroundColor: "#FCFCFD"}}>
                      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <TextField
                            variant={"standard"}
                            multiline
                            disabled={columnEdit !== -1 && columnEdit !== i}
                            sx={{width: "120px"}}
                            onChange={e => updateCalories(e.target.value, i)}
                            value={row.calories || 0}
                        />
                      </Box>
                    </TableCell>
                ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>תוספי תזונה</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{color: "#4F535C", backgroundColor: "#FCFCFD"}}>
                      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <TextField
                            multiline
                            disabled={columnEdit !== -1 && columnEdit !== i}
                            variant={"standard"}
                            sx={{width: "120px"}}
                          value={row.supplements || ""}
                          onChange={e => updateSupplements(e.target.value, i)}
                        />
                      </Box>

                    </TableCell>
                ))}
              </TableRow>
              <TableRow >
                <TableCell align={"right"} sx={{color: "#F9FAFB", backgroundColor: "#57837E"}}>
                  <Typography sx={{textAlign: "center"}}>הערות</Typography>
                </TableCell>
                {weightTracks.map((row, i) => i === weightTracks.length-1 ? '' : (
                    <TableCell align={"right"}
                               sx={{color: "#4F535C", backgroundColor: "#FCFCFD"}}>
                      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        <TextField
                            multiline
                            disabled={columnEdit !== -1 && columnEdit !== i}
                            variant={"standard"}
                            sx={{width: "120px"}}
                            value={row.notes || ""}
                            onChange={e => updateNotes(e.target.value, i)}
                        />
                      </Box>

                    </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>
                </TableCell>
                <TableCell sx={{border: 0}}>
                  <Button
                      onClick={onSave}
                      sx={{mr: "40px", display: columnEdit !== 0 ? 'none' : 'inherit'}}
                      variant={"contained"}>save</Button>
                  <Button
                      onClick={onCancel}
                      sx={{mt: "10px", mr: "40px", color: "#F16063", display: columnEdit !== 0 ? 'none' : 'inherit'}}
                      variant={"text"}>cancel</Button>
                </TableCell>
                <TableCell sx={{border: 0}}>
                  <Button
                      onClick={onSave}
                      sx={{mr: "40px", display: columnEdit !== 1 ? 'none' : 'inherit'}}
                      variant={"contained"}>save</Button>
                  <Button
                      onClick={onCancel}
                      sx={{mt: "10px", mr: "40px", color: "#F16063", display: columnEdit !== 1 ? 'none' : 'inherit'}}
                      variant={"text"}>cancel</Button>
                </TableCell>
                <TableCell sx={{border: 0}}>
                  <Button
                      onClick={onSave}
                      sx={{mr: "40px", display: columnEdit !== 2 ? 'none' : 'inherit'}}
                      variant={"contained"}>save</Button>
                  <Button
                      onClick={onCancel}
                      sx={{mt: "10px", mr: "40px", color: "#F16063", display: columnEdit !== 2 ? 'none' : 'inherit'}}
                      variant={"text"}>cancel</Button>
                </TableCell>
                <TableCell sx={{border: 0}}>
                  <Button
                      onClick={onSave}
                      sx={{mr: "40px", display: columnEdit !== 3 ? 'none' : 'inherit'}}
                      variant={"contained"}>save</Button>
                  <Button
                      onClick={onCancel}
                      sx={{mt: "10px", mr: "40px", color: "#F16063", display: columnEdit !== 3 ? 'none' : 'inherit'}}
                      variant={"text"}>cancel</Button>
                </TableCell>
                <TableCell sx={{border: 0}}>
                  <Button
                      onClick={onSave}
                      sx={{mr: "40px", display: columnEdit !== 4 ? 'none' : 'inherit'}}
                      variant={"contained"}>save</Button>
                  <Button
                      onClick={onCancel}
                      sx={{mt: "10px", mr: "40px", color: "#F16063", display: columnEdit !== 4 ? 'none' : 'inherit'}}
                      variant={"text"}>cancel</Button>
                </TableCell>
                <TableCell sx={{border: 0}}>
                  <Button
                      onClick={onSave}
                      sx={{mr: "40px", display: columnEdit !== 5 ? 'none' : 'inherit'}}
                      variant={"contained"}>save</Button>
                  <Button
                      onClick={onCancel}
                      sx={{mt: "10px", mr: "40px", color: "#F16063", display: columnEdit !== 5 ? 'none' : 'inherit'}}
                      variant={"text"}>cancel</Button>
                </TableCell>
                <TableCell sx={{border: 0}}>
                  <Button
                      onClick={onSave}
                      sx={{mr: "40px", display: columnEdit !== 6 ? 'none' : 'inherit'}}
                      variant={"contained"}>save</Button>
                  <Button
                      onClick={onCancel}
                      sx={{mt: "10px", mr: "40px", color: "#F16063", display: columnEdit !== 6 ? 'none' : 'inherit'}}
                      variant={"text"}>cancel</Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
      </Box>
  );
};

export default ClientWeightTrackPage;
