import {Navigate} from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import Login from './pages/Login';
import Clients from "./pages/Clients";
import ClientPage from "./pages/ClientPage";
import Companies from "./pages/Companies";
import Dashboard from "./pages/Dashboard";
import ClientNutrionPage from "./pages/ClientNutrionPage";
import ClientWeightTrackPage from "./pages/ClientWeightTrackPage";
import CompanyPage from "./pages/CompanyPage";
import Exercises from "./pages/Exercises";
import Trainers from "./pages/Trainers";
import TrainerPage from "./pages/TrainerPage";
import SettingPage from "./pages/SettingPage";
import Templates from "./pages/Templates";
import TemplatePage from "./pages/TemplatePage";
import ClientWeekPage from "./pages/ClientWeekPage";
import AlertsPage from "./pages/AlertsPage";
import ProgramRenew from "./pages/ProgramRenew";
import Page404 from "./pages/Page404";
import {useAuth} from "./contexts/AuthContext";

function NavigateGuard() {
  const {isAdmin} = useAuth();
  return isAdmin() ? <Navigate to="/app/dashboard"/> : <Navigate to="/app/clients" />;
}

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'templates', element: <Templates /> },
      { path: 'templates/:templateId', element: <TemplatePage /> },
      { path: 'exercises', element: <Exercises /> },
      { path: 'companies', element: <Companies /> },
      { path: 'trainers', element: <Trainers /> },
      { path: 'trainers/:trainerId', element: <TrainerPage /> },
      { path: 'companies/:companyId', element: <CompanyPage /> },
      { path: 'alerts', element: <AlertsPage/>},
      { path: 'program_renew', element: <ProgramRenew/>},
      { path: 'setting', element: <SettingPage /> },
      { path: 'clients', element: <Clients /> },
      { path: 'clients/:userId', element: <ClientPage /> },
      { path: 'clients/:userId/:programId/week/:blockIndex/:weekIndex', element: <ClientWeekPage /> },
      { path: 'clients/:userId/nutrition', element: <ClientNutrionPage /> },
      { path: 'clients/:userId/weight', element: <ClientWeightTrackPage /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '/', element: <NavigateGuard/> },
      { path: '*', element: <Page404 to="/404" /> }
    ]
  }
];

export default routes;
