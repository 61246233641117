import {ReactComponent as BackIcon} from '../assets/images/backIcon.svg';
import {Box, Button} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import CompanyProfileSection from "../componenets/company/CompanyProfileSection";
import {ReactComponent as ImageSkeletonIcon} from "../assets/images/image-skelaton.svg";
import {ReactComponent as EditIcon} from "../assets/images/editProfile.svg";
import CompanyManagersSection from "../componenets/company/CompanyManagersSection";
import CompanyStatusSection from "../componenets/company/CompanyStatusSection";
import {ReactComponent as DeleteIcon} from "../assets/images/delete.svg";
import {useEffect, useState} from "react";
import {
  deleteCompany,
  disableComapny,
  enableCompany,
  getCompany,
  uploadImage
} from "../services/CompanyService";
import Loader from "../componenets/Loader";
import {useMessageContext} from "../contexts/MessageContext";
import {getErrorMessage} from "../enums/ErrorMessages";

const CompanyPage = () => {
  let navigate = useNavigate();
  const params = useParams();

  const [company, setCompany] = useState();

  const [loading, setLoading] = useState(false);

  const [loadCompany, setLoadCompany] = useState(true);

  const {notificationHandler} = useMessageContext();

  useEffect(() => {
    getCompany(params.companyId).then(res => {
      setCompany(res.data);
    }).catch((e) => {
      notificationHandler.error(getErrorMessage(e.response.data?.errorCode))
    })
  },[loadCompany])

  const handleEnableDisableCompany = () => {
    if (company.metaData.enabled) {
      disableComapny(params.companyId)
          .then(() => notificationHandler.success("חברה בוטלה בהצלחה"))
          .then(() => setLoadCompany(prv => !prv))
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
    } else {
      enableCompany(params.companyId)
          .then(() => notificationHandler.success("חברה הופעלה בהצלחה"))
          .then(() => setLoadCompany(prv => !prv))
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
    }
  }

  const handleDeleteCompany = () => {
    deleteCompany(params.companyId)
        .then(() => navigate(-1))
        .then(() => notificationHandler.success("חברה נמחקה בהצלחה"))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
  }

  const handleFileUpload =(e) => {
    setLoading(true);
    const file = e.target.files[0];
    uploadImage(company.metaData.id, file)
        .then(() => {
          setLoading(false);
          navigate(0);
        });
  }

  return (
      <>
        <Loader loading={loading}/>
        <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: "45px", cursor: "pointer", marginLeft: "54px", marginRight: "20px"}} onClick={() => navigate(-1)}>
          <BackIcon/>
        </Box>


        <Box sx={{position: "relative", marginRight: "40px"}}>
          <Button
              variant="text"
              component="label"
              sx={{marginTop: "33px"}}
          >
            {company && company.metaData.imageUrl ? <img
                width={116}
                height={116}
                src={company.metaData.imageUrl}
                loading="lazy"
            /> : <ImageSkeletonIcon/>}
            <input
                accept="image/png, image/gif, image/jpeg"
                onChange={handleFileUpload}
                type="file"
                hidden
            />
          <Box sx={{position: "absolute", top: "80%", right: "98px", cursor: "pointer"}}>
            <EditIcon/>
          </Box>
          </Button>
        </Box>
        <Box sx={{margin: "40px"}}>
          {company && <CompanyProfileSection setLoadCompany={setLoadCompany} company={company.metaData}/>}
          <Box sx={{marginTop: "40px"}}>
            {company && <CompanyManagersSection compantId={company.metaData.id} setLoadCompany={setLoadCompany} companyOwners={company.companyOwners}/>}
          </Box>
          <Box sx={{marginTop: "40px"}}>
            {company && <CompanyStatusSection company={company.metaData} statistics={company.statistics}/>}
          </Box>
          <Box sx={{marginTop: "20px"}}>
            <Button
                onClick={handleDeleteCompany}
                variant="outlined"
                    sx={{backgroundColor: "#FCFCFD", color: "#27272E", height: "44px", width: "78px", "& .MuiButton-endIcon" : {
                        marginLeft: "-4px",
                        marginTop: "2px",
                        borderRadius: "8px",
                        marginRight: "8px",
                      }}} endIcon={<DeleteIcon/>}
            >
              מחק
            </Button>
            <Button
                onClick={handleEnableDisableCompany}
                variant="text"
                    sx={{color: company && company.metaData.enabled ? "#F16063" : "#57835b", height: "44px", width: "78px"}}
            >
              {company && company.metaData.enabled ? "חסום" : "הפעל"}
            </Button>
          </Box>
        </Box>
      </>
  )
};

export default CompanyPage;
