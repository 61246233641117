import React, {useContext, useEffect, useState} from "react";
import {useAuth} from "./AuthContext";
import {getCompanies, getCompany, getRegularCompany} from "../services/CompanyService";

const CompanyContext = React.createContext({ undefined });

export function useCompany() {
  return useContext(CompanyContext);
}

export function CompanyProvider({children}) {
  const {isSuperAdmin, currentUser} = useAuth();
  const [companiesData, setCompaniesData] = useState({
    loading: true,
    companies: []
  });

  const [reload, setReload] = useState(false);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (isSuperAdmin()) {
      getCompanies().then(res => {
        setCompaniesData({
          loading: false,
          companies: res.data
        });
      })
    } else {
      getRegularCompany(currentUser.companyId).then(res => {
        setCompany(res.data);
      });

      setCompaniesData({
        loading: false,
        companies: []
      })
    }
  }, [reload])

  const updateCompanies = () => {
    setReload(prv => !prv);
  }

  const changeCompany = (companyId) => {
    if (isSuperAdmin()) {

      const found = companiesData.companies.find(cmp => {
        return companyId === cmp.id;
      });

      if (found)
        setCompany(found);
      else {
        setCompany(null);
      }
    }
  }

  const getCompaniesList = () => {
    return companiesData.companies;
  }

  const value = {
    changeCompany,
    getCompaniesList,
    company,
    updateCompanies,
  };

  return (
      <CompanyContext.Provider value={value}>
        {!companiesData.loading && children}
      </CompanyContext.Provider>
  );
};
