import {Box, FormControl, MenuItem, Select, TextField, Typography} from "@mui/material";
import {RTL} from "../../cache/CacheRtl";
import {useEffect, useState} from "react";
import {getByTypes, getExercise} from "../../services/ExcersiceService";
import {targetMuscles} from "../../enums/TargetMuscles";
import {ManipulationTypes} from "../../enums/ManipulationTypes";
import {TempoTypes} from "../../enums/TempoTypes";
import {getErrorMessage} from "../../enums/ErrorMessages";
import {useMessageContext} from "../../contexts/MessageContext";

const TemplateExercise = ({workoutIdx, setTemplate, exercise, number, readOnly}) => {

  const {notificationHandler} = useMessageContext();

  const [selectedValues, setSelectedValues] = useState({
    targetMuscle: '',
    exerciseDef: null,
    exerciseOptions: []
  });

  const [loading, setLoading] = useState(true);

  const updateInterval = (value) => {
    setTemplate(prv => {
      let newTemp = {...prv};

      let intervals = newTemp.blocks
          .at(0).weeks
          .at(0).workouts
          .at(workoutIdx).exercises
          .at(number-1).intervals;

      if (intervals.length === 0) {
        intervals.push({
          index: 0,
          typeSet: "REGULAR_SET",
        });

        intervals.push({
          index: 1,
          typeSet: "REGULAR_SET",
        });

        intervals.push({
          index: 2,
          typeSet: "REGULAR_SET",
        });
      }

      intervals.at(0).recommendedReps = value;
      intervals.at(1).recommendedReps = value;
      intervals.at(2).recommendedReps = value;

      newTemp.blocks
          .at(0).weeks
          .at(0).workouts
          .at(workoutIdx).exercises
          .at(number-1).intervals = intervals;

      return newTemp;
    })
  }

  const updateRestTime = (restTime) => {
    setTemplate(prv => {
      let newTemp = {...prv}

      newTemp.blocks.forEach(block => {
        block.weeks.forEach(week => {
          week.workouts.at(workoutIdx)
              .exercises
              .at(number-1)
              .restTimeInSec = restTime;
        })
      })

      return newTemp;
    })
  }

  const updateTempo = (tempo) => {
    setTemplate(prv => {
      let newTemp = {...prv}

      newTemp.blocks.forEach(block => {
        block.weeks.forEach(week => {
          week.workouts.at(workoutIdx)
              .exercises
              .at(number-1)
              .tempo = tempo;
        })
      })

      return newTemp;
    })
  }

  const updateManipulation = (manipulation) => {
    setTemplate(prv => {
      let newTemp = {...prv}

      newTemp.blocks.forEach(block => {
        block.weeks.forEach(week => {
          week.workouts.at(workoutIdx)
              .exercises
              .at(number-1)
              .manipulation = manipulation;
        })
      })

      return newTemp;
    })
  }

  const updateDurationTime = (durationTime) => {
    setTemplate(prv => {
      let newTemp = {...prv}

      newTemp.blocks.forEach(block => {
        block.weeks.forEach(week => {
          week.workouts.at(workoutIdx)
              .exercises
              .at(number-1)
              .durationTime = durationTime;
        })
      })

      return newTemp;
    })
  }


  const updateComment = (comment) => {
    setTemplate(prv => {
      let newTemp = {...prv}

      newTemp.blocks.forEach(block => {
        block.weeks.forEach(week => {
          week.workouts.at(workoutIdx)
              .exercises
              .at(number-1)
              .comment = comment;
        })
      })

      return newTemp;
    })
  }

  useEffect(() => {
    setLoading(true);
    if (exercise.exerciseId) {
      getExercise(exercise.exerciseId).then(res => {
        getByTypes(res.data.targetMuscle)
            .then(resEx => {
              setSelectedValues({
                targetMuscle: res.data.targetMuscle,
                exerciseDef: res.data,
                exerciseOptions: resEx.data});
                  setLoading(false)}
        ).catch(e => {
          setLoading(false);
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
        })

      }).catch(e => {
        setLoading(false);
        notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
      })
    } else {
      setLoading(false);
    }
  }, []);


  const changeExercise = (value) => {
    let exerciseFound = selectedValues.exerciseOptions.find(op => {
      return op.id === value;
    });

    setSelectedValues(prv => {
      return {
        ...prv,
        exerciseDef: exerciseFound
      }
    })

    setTemplate(prv => {
      let newTemp = {...prv};

      newTemp.blocks.forEach(block => {
        block.weeks.forEach(week => {
          week.workouts.at(workoutIdx)
              .exercises
              .at(number-1)
              .exerciseId = exerciseFound.id;
        })
      })

      return newTemp;
    })
  }

  const updateTargetMuscle = (value) => {
    setLoading(true);

    if (value === 'CARDIO') {
      updateDurationTime(60);
    } else {
      updateDurationTime(null);
    }

      getByTypes(value)
          .then(res => {
            setSelectedValues(prv => {
              return {
                ...prv,
                targetMuscle: value,
                exerciseDef: null,
                exerciseOptions: res.data
              }
            });
            setLoading(false);
          }
          ).catch(e => {
        notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
        setLoading(false);
      });
  }

  return (
      <>
        {!loading && <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: "24px"}}>
        <Typography sx={{color: "#69B8AF",
          alignSelf: "flex-end",
          paddingBottom: "8px",
        fontSize: "20px",
        fontWeight: 600,
          lineHeight: "27px",
        }}>{number}</Typography>
            <Box sx={{display: "flex", flexDirection: "column", marginRight: "8px"}}>
          <Typography sx={{
            color: "#4F535C", fontSize: "14px", fontWeight: 600, lineHeight: "24px",
          }}>שריר עיקרי</Typography>
              <RTL>
                <FormControl fullWidth>
                  <Select
                      disabled={readOnly}
                      sx={{height: "48px", width: "200px"}}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedValues.targetMuscle}
                      displayEmpty
                      onChange={(e) => updateTargetMuscle(e.target.value)}
                  >
                    {targetMuscles.map((res, i) => {
                      return <MenuItem key={i} value={res.value}>{res.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </RTL>
        </Box>
          <Box sx={{display: "flex", flexDirection: "column", marginRight: "8px"}}>
          <Typography sx={{color: "#4F535C",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "24px",}}>שם התרגיל</Typography>
            <RTL>
              <FormControl fullWidth>
                <Select
                    disabled={readOnly}
                    sx={{height: "48px", width: "200px"}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedValues.exerciseDef?.id}
                    displayEmpty
                    onChange={e => changeExercise(e.target.value)}
                >
                  {selectedValues.exerciseOptions.map((value, i) => {
                    return <MenuItem key={i} value={value.id}>{value.nameHeb}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </RTL>
          </Box>
        <Box sx={{display: "flex", flexDirection: "column", marginRight: "8px"}}>
          <Typography sx={{color: "#4F535C",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "24px",
          }}>RM חזרות</Typography>
          <RTL>
            <FormControl fullWidth>
              <Select
                  disabled={readOnly || selectedValues.targetMuscle === 'CARDIO'}
                  sx={{height: "48px"}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => updateInterval(e.target.value)}
                  value={exercise.intervals.length > 0 ? exercise.intervals.at(0).recommendedReps : ''}
                  displayEmpty
              >
                {Array.from(Array(30).keys()).map((_, i) => {
                  return <MenuItem key={i} value={i+1}>{i+1}</MenuItem>
                })}
              </Select>
            </FormControl>
          </RTL>
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", marginRight: "8px"}}>
          <Typography sx={{color: "#4F535C",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "24px",
          }}>מניפולציה</Typography>
          <RTL>
            <FormControl fullWidth>
              <Select
                  disabled={readOnly || selectedValues.targetMuscle === 'CARDIO'}
                  sx={{minWidth: "140px", height: "48px"}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={exercise.manipulation || ""}
                  onChange={(e) => updateManipulation(e.target.value)}
                  displayEmpty
              >
                {ManipulationTypes.map((manipulation,i) => {
                  return <MenuItem key={i} value={manipulation}>{manipulation}</MenuItem>
                    })
                })}
              </Select>
            </FormControl>
          </RTL>
        </Box>
          {selectedValues.targetMuscle !== 'CARDIO' ?
          <Box sx={{display: "flex", flexDirection: "column", marginRight: "8px"}}>
            <Typography sx={{color: "#4F535C",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "24px",
            }}>קצב תנועה</Typography>
            <RTL>
              <FormControl fullWidth>
                <Select
                    disabled={readOnly}
                    sx={{minWidth: "140px", height: "48px"}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={exercise.tempo}
                    onChange={(e) => updateTempo(e.target.value)}
                    displayEmpty
                >
                  {TempoTypes.map((res,i) => {
                    return <MenuItem key={i} value={res.value}>{res.name}</MenuItem>
                  })
                  })}
                </Select>
              </FormControl>
            </RTL>
          </Box> :
              <Box sx={{display: "flex", flexDirection: "column", marginRight: "8px"}}>
                <Typography sx={{color: "#4F535C",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}>זמן עבודה</Typography>
                <RTL>
                  <FormControl fullWidth>
                    <Select
                        disabled={readOnly}
                        sx={{minWidth: "140px", height: "48px"}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={exercise.durationTime || 60}
                        onChange={(e) => updateDurationTime(e.target.value)}
                        displayEmpty
                    >ֿ
                      <MenuItem value={60}>15</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={45}>45</MenuItem>
                      <MenuItem value={60}>60</MenuItem>
                      <MenuItem value={90}>90</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </RTL>
              </Box>}
            <Box sx={{display: "flex", flexDirection: "column", marginRight: "8px"}}>
          <Typography sx={{color: "#4F535C",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "24px",
          }}>זמן מנוחה (בשניות)</Typography>
          <TextField
              disabled={readOnly || selectedValues.targetMuscle === 'CARDIO'}
              sx={{maxWidth: "100px"}}
              onChange={(e) => updateRestTime(e.target.value)}
              value={exercise.restTimeInSec ? exercise.restTimeInSec : 0}/>
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", marginRight: "8px"}}>
          <Typography sx={{color: "#4F535C",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "24px",
          }}>הערות</Typography>
          <TextField
              disabled={readOnly}
              onChange={(e) => updateComment(e.target.value)}
              value={exercise.comment}/>
        </Box>
      </Box>}
      </>
  );
};

export default TemplateExercise;
