import {Box, Divider, Paper, Typography} from "@mui/material";
import {ReactComponent as ActiveIcon} from "../../assets/images/active.svg";
import {ReactComponent as InactiveIcon} from "../../assets/images/inactive.svg";

const CompanyStatusSection = ({company, statistics}) => {

  return (
      <Paper variant={"outlined"} sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7",
        flexGrow: 0,
        borderRadius: "4px"}}>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Box sx={{display: "flex", alignItems: "center", marginRight: "8px"}}>
            <Typography sx={{fontWeight: 600,
              fontSize: "20px",
              lineHeight: "27.3px",
              color: "#4F535C",
              padding: "10px"}}>סטטוס חברה</Typography>
          </Box>
          <Box sx={{marginLeft: "20px"}}>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                marginLeft: "16px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"}}>{company.enabled ? "פעילה" : "חסומה"}</Typography>
              {company.enabled ? <ActiveIcon/> : <InactiveIcon/>}
            </Box>
          </Box>
        </Box>
        <Divider/>
        <Box sx={{marginTop: "44px", paddingBottom: "45px", marginRight: "21px",marginLeft: "28px",display: "flex", alignItems: "flex-start"}}>
          <Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>מספר מאמנים:</Typography>
              <Typography sx={{
                marginRight: "26px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{statistics.numberOfTrainers}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>מספר לקוחות:</Typography>
              <Typography sx={{
                marginRight: "26px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{statistics.numberOfTrainees}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
  );
};
export default CompanyStatusSection;
