import {
  Box,
  Card,
  CircularProgress,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import TemplateCard from "./TemplateCard";

const MiniTemplates = ({templatesState, fetchNextPage}) => {

  return (<Card elevation={0} sx={{overflow: "auto",
    overflowY: "hidden",
    marginTop: {md: "20px", xs: "70px"}}}>
    <InfiniteScroll
        initialLoad={true}
        hasMore={templatesState.hasMoreData}
        loadMore={fetchNextPage}
        loader={
          <Box
              key={0}
              sx={{ display: "flex", justifyContent: "center", margin: "16px" }}
          >
            <CircularProgress sx={{ color: "#6750A4" }} />
          </Box>
        }
    >
      {templatesState.templates.length || templatesState.isLoading ? (
          <Box
              sx={{ margin: { md: "15px auto", xs: "15px" }}}
          >

            <TableContainer>
              <Table  sx={{"&.MuiTable-root" : {
                  borderCollapse: "separate",
                  borderSpacing: "0 15px",
                }}}>
                <TableHead>
                  <TableRow>
                    <TableCell align="right">
                      שם תוכנית
                    </TableCell>
                    <TableCell align="center">
                      מגדר
                    </TableCell>
                    <TableCell align="center">
                      מטרה
                    </TableCell>
                    <TableCell align="center">
                      מספר אימונים
                    </TableCell>
                    <TableCell align="center">
                      זמן אימון
                    </TableCell>
                    <TableCell align="center">
                      רמת מתאמן
                    </TableCell>
                    <TableCell align="center">
                      דגש ראשי
                    </TableCell>
                    <TableCell align="center">
                      שריר ספציפי
                    </TableCell>
                    <TableCell align="center">
                       שריר ספציפי 2
                    </TableCell>
                    <TableCell align="left">
                      תאריך יצירה
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {templatesState.templates.map((template, id) => (
                    <TemplateCard
                        key={id}
                        template={template}
                        index={template.id}
                    />
                ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
      ) : (
          <Box
              sx={{ margin: { md: "15px auto", xs: "15px" }}}
          >
            <Typography sx={{ fontSize: "20px",
              fontWeight: "700",
              color: "primary.main",
              textAlign: "center",}}>
              אין תוצאות
            </Typography>
          </Box>
      )}
    </InfiniteScroll>
  </Card>)
};

export default MiniTemplates;
