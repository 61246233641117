import {Box, Button, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {CacheProvider} from "@emotion/react";
import {CacheRtl} from "../cache/CacheRtl";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {useState} from "react";

const LoginForm = ({handleRestLogin, openForgottenPassword, failedLogin}) => {

  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
      <Box component="form"
           sx={{
        marginTop: {md: "30px", xs: "30px"},
        marginRight: "auto",
        marginLeft: "auto",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>
          <Box sx={{display: "flex"}}>
            <Typography sx={{
              fontWeight: {md: 700, xs: "600"},
              fontSize: {md: "40px", xs: "28px"},
              lineHeight: {md: "52px", xs: "38px"}
            }} color="primary.light">התחברות ל</Typography>
            <Typography sx={{
              fontWeight: {md: 700, xs: "600"},
              fontSize: {md: "40px", xs: "28px"},
              lineHeight: {md: "52px", xs: "38px"}
            }} color="primary.main">PROGRESS</Typography>
          </Box>
          <CacheProvider value={CacheRtl}>
            <Typography sx={{
              marginLeft: "auto",
              marginRight: "auto",
              color: "#718096",
              fontWeight: {md: 400, xs: 600},
              fontSize: {md: "20px", xs: "14px"},
              lineHeight: {md: "136.02%", xs: "18.31px"},
              textAlign: "center"
            }} maxWidth="402px">התחילו לבנות את תוכניות האימון של המתאמנים שלכם
              בצורה המקצועית ביותר!</Typography>
            <Box sx={{
              marginTop: "36px",
              width: "90%",
              maxWidth: "414px",
              marginRight: "auto",
              marginLeft: "auto"
            }}>
              <Typography color="primary.light">מייל</Typography>
              <TextField value={email} onChange={(event) => setEmail(event.target.value)}
                         sx={{width: "100%"}} placeholder="gmail.com@" id="email"
                         variant="outlined"/>
            </Box>
            <Box sx={{
              marginTop: "28px",
              width: "90%",
              maxWidth: "414px",
              marginRight: "auto",
              marginLeft: "auto"
            }}>
              <Typography color="primary.light">סיסמה</Typography>
              <TextField value={password}
                         onChange={(event) => setPassword(event.target.value)}
                         sx={{width: "100%"}} placeholder="***"
                         type={showPassword ? 'text' : 'password'}
                         id="password" variant="outlined"
                         InputProps={{
                           endAdornment: (<InputAdornment position="end">
                                 <IconButton
                                     aria-label="toggle password visibility"
                                     onClick={handleClickShowPassword}
                                     onMouseDown={handleMouseDownPassword}
                                     edge="start"
                                 >
                                   {showPassword ? <VisibilityOff/> : <Visibility/>}
                                 </IconButton>
                               </InputAdornment>),
                         }}/>
            </Box>
            <Box sx={{width: "90%", maxWidth: "414px", marginRight: "auto", marginLeft: "auto"}}>
              {failedLogin && <Typography color="primary.error" sx={{marginTop: "14px"}}>שם משתמש או
                הסיסמה אינם תואמים, אנא נסו שנית</Typography>}
              <Typography onClick={openForgottenPassword} color="primary.dark"
                          sx={{cursor: "pointer", textDecoration: "underline", marginTop: "24px"}}>
                שכחת סיסמה?
              </Typography>
            </Box>
            <Box sx={{
              marginTop: {md: "48px", xs: "28px"},
              width: "90%",
              maxWidth: "414px",
              marginRight: "auto",
              marginLeft: "auto",
            }}>
              <Button
                  disabled={password.length < 1 || email.length < 1}
                  type="submit" sx={{width: "100%"}} variant="contained" onClick={handleRestLogin}>
                התחברות
              </Button>
            </Box>
          </CacheProvider>
      </Box>
  );
};
export default LoginForm;
