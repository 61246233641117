export const mainMuscles = [
  {
    value: "GENERAL",
    name: "כללי"
  },
  {
    value: "UPPER_BODY",
    name: "פלג גוף עליון"
  },
  {
    value: "LOWER_BODY",
    name: "פלג גוף תחתון"
  }
]
