import {Paper, Typography} from "@mui/material";
import Chart from "react-apexcharts";
import moment from "moment";

const ClientStats = ({statsData}) => {

  const state = {

    series: [{
      name: " לקוחות",data: statsData.map(record => {
        return record.data;
      }),
    }], options: {
      chart: {
        type: 'area', zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      }, dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: statsData.map(record => {
          return moment(record.date).format("DD/MM");
        }),
      },
      yaxis: {
        opposite: false
      },
    }
  }

  return (
      <Paper sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7", minHeight: "423px"}}>
        <Typography sx={{padding: "20px"}}>
        סקירת לקוחות
        </Typography>
        <Chart options={state.options} series={state.series} height={343}
               type="area"/>
      </Paper>)
}
export default ClientStats;
