import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, TextField,
  Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TemplateExercise from "./TemplateExercise";
import {ReactComponent as PlusIcon} from "../../assets/images/plus_round.svg";
import {ReactComponent as MinusIcon} from "../../assets/images/minus_round.svg";
import {TempoTypes} from "../../enums/TempoTypes";
import {ManipulationTypes} from "../../enums/ManipulationTypes";

const TemplatePreparationSection = ({template, setTemplate, editMode}) => {

  const removeExerciseToWorkout = (workoutId) => {
    setTemplate(prv => {
      let newTemplate = {...prv}

      newTemplate.blocks.forEach(block => {
        block.weeks.forEach(week => {
          week.workouts?.at(workoutId).exercises.pop();
        })
      });

      return newTemplate;
    });
  };

  const addExerciseToWorkout = (workoutId) => {
    setTemplate(prv => {
      let newTemplate = {...prv}

      newTemplate.blocks.forEach(block => {
        block.weeks.forEach(week => {
          week.workouts?.at(workoutId).exercises.push({
            intervals: [{
              index: 0,
              typeSet: "REGULAR_SET",
            },
            {
              index: 1,
              typeSet: "REGULAR_SET",
            },
            {
              index: 2,
              typeSet: "REGULAR_SET",
            }],
            tempo: TempoTypes.at(0).value,
            manipulation: ManipulationTypes.at(0),
            index: week.workouts?.at(workoutId).exercises.length,
            comment: ""
          })
        })
      });

      return newTemplate;
    });
  }

  const updateWorkoutName = (name, workoutIdx) => {
    setTemplate(prv => {
      let newTemp = {...prv}

      newTemp.blocks.forEach(block => {
        block.weeks.forEach(week => {
          week.workouts.at(workoutIdx).name = name
        })
      });

      return newTemp;
    })
  }

  const updateWorkoutNameHeb = (name, workoutIdx) => {
    setTemplate(prv => {
      let newTemp = {...prv}

      newTemp.blocks.forEach(block => {
        block.weeks.forEach(week => {
          week.workouts.at(workoutIdx).nameHeb = name
        })
      });

      return newTemp;
    })
  }

  return (
      <>
        <Typography sx={{
          fontSize: "14px",
          marginTop: "40px",
          fontWeight: 600,
          lineHeight: "24px",
          color: "#1E1E1E"
        }}>שבוע הכנה</Typography>

        <Box sx={{marginTop: "28px"}}>
        {template.blocks?.sort((a, b) => a.index > b.index ? 1 : -1).at(0)?.weeks?.sort((a, b) => a.index > b.index ? 1 : -1).at(0).workouts?.sort((a, b) => a.index > b.index ? 1 : -1).map((workout, workoutIdx) => {
          return <Accordion key={workoutIdx} variant={"elevation"} sx={{backgroundColor: "#FCFCFD", minHeight: "64px"}}>
            <AccordionSummary
                sx={{"&.MuiAccordionSummary-root" : {
                    height: "64px"
                  }}}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
              <Typography>אימון {workoutIdx +1}</Typography>
            </AccordionSummary>
            <AccordionDetails >
              <Box sx={{display: "flex"}}>
                <Box>
                  <Typography>שם אימון בעברית:</Typography>
                  <TextField
                  value={workout.nameHeb}
                  onChange={(e) => updateWorkoutNameHeb(e.target.value, workoutIdx)}
                  />
                </Box>
                <Box sx={{mr: "20px", mb: "20px"}}>
                  <Typography>שם אימון באנגלית:</Typography>
                  <TextField
                      value={workout.name}
                      onChange={(e) => updateWorkoutName(e.target.value, workoutIdx)}
                  />
                </Box>
              </Box>
              <Box sx={{display: "flex", alignItems: "center"}}>
                <Typography sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  color: "#1E1E1E"
                }}>מספר תרגילים באימון</Typography>
                {editMode ? <Box onClick={() => removeExerciseToWorkout(workoutIdx)} sx={{marginRight: "16px", cursor: "pointer"}}><MinusIcon/></Box> : ""}
                <Typography sx={{
                  fontSize: "14px",
                  marginRight: "16px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  color: "#1E1E1E"
                }}>{workout.exercises.length}</Typography>
                {editMode ? <Box onClick={() => addExerciseToWorkout(workoutIdx)} sx={{marginRight: "16px", cursor: "pointer"}}><PlusIcon/></Box> : ""}

              </Box>
              {workout.exercises.map((exercise, index) => {
                return <TemplateExercise workoutIdx={workoutIdx} setTemplate={setTemplate}  exercise={exercise} number={index + 1} readOnly={!editMode}/>
              })}
            </AccordionDetails>
          </Accordion>
        })}
        </Box>
      </>
  );
};

export default TemplatePreparationSection;
