import {Box, Divider, Paper, Typography} from "@mui/material";
import {ReactComponent as ProfileIcon} from "../../assets/images/profile.svg";
import {ReactComponent as EditIcon} from "../../assets/images/editProfile.svg";
import moment from "moment";

const TrainerProfileSection = ({trainer, setEditTrainer}) => {

  return (
      <Paper sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7",
        flexGrow: 0,
        borderRadius: "4px"}}>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Box sx={{display: "flex", alignItems: "center", marginRight: "8px"}}>
            <ProfileIcon/>
            <Typography sx={{fontWeight: 600,
              fontSize: "20px",
              lineHeight: "27.3px",
              color: "#4F535C",
              padding: "10px"}}>פרטים אישיים</Typography>
          </Box>
          <Box sx={{marginLeft: "8px", cursor: "pointer"}}
          onClick={() => setEditTrainer(true)}
          >
            <EditIcon/>
          </Box>
        </Box>
        <Divider/>
        <Box sx={{marginTop: "44px", paddingBottom: "45px", marginRight: "21px",marginLeft: "28px",display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#717784"
            }}>שם מלא:</Typography>
            <Typography sx={{
              marginRight: "14px",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#1E1E1E"
            }}>{trainer.name}</Typography>
          </Box>
          <Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>גיל:</Typography>
              <Typography sx={{
                marginRight: "26px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{trainer.age}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>מגדר:</Typography>
              <Typography sx={{
                marginRight: "14px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{trainer.gender === "FEMALE" && "נקבה"}
                {trainer.gender === "MALE" && "זכר"}
                {!trainer.gender && "אין מידע"}</Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>מייל:</Typography>
              <Typography sx={{
                marginRight: "22px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{trainer.email}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>טלפון:</Typography>
              <Typography sx={{
                marginRight: "14px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{trainer.phoneNumber}</Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>סטטוס:</Typography>
              <Typography sx={{
                marginRight: "49px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{trainer.enabled ? "פעיל" : "חסום"}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>תאריך יצירה:</Typography>
              <Typography sx={{
                marginRight: "14px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{trainer.createdDate ? moment(trainer.createdDate).format("DD/MM/YY") : "אין מידע"}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
  );
};

export default TrainerProfileSection;
