import {Box, TableCell, TableRow} from "@mui/material";
import moment from "moment";
import {ReactComponent as ActiveIcon} from "../../assets/images/active.svg";
import {ReactComponent as InactiveIcon} from "../../assets/images/inactive.svg";
import {useNavigate} from "react-router-dom";

const MiniTrainers = ({trainers}) => {
  const navigate = useNavigate()

  return (
      <>
      {trainers.map((trainer, i) => {
        return <TableRow key={i}
            onClick={(e) => {
              navigate(`/app/trainers/${trainer.id}`)
            }}
            sx={{"&.MuiTableRow-root" : {
                border: "1px solid #E7E9EE",
                height: "100px",
                backgroundColor: "#F9FAFB",
                cursor: "pointer",
                "&:hover" : {
                  backgroundColor: "rgba(185,217,213,0.41)"
                },
                boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06)"
              }}}>
          <TableCell align="right">
            {trainer.name}
          </TableCell>
          <TableCell align="center">
            {trainer.gender === "FEMALE" && "נקבה"}
            {trainer.gender === "MALE" && "זכר"}
            {!trainer.gender && "אין מידע"}
          </TableCell>
          <TableCell align="center">
            {moment(moment.unix(trainer.createdDate)).format("DD/MM/YY")}
          </TableCell>
          <TableCell align="left">
            {trainer.enabled ? <ActiveIcon/> : <InactiveIcon/>}
          </TableCell>
        </TableRow>})}
        </>
      )
      };

export default MiniTrainers;
