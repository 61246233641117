import {Box} from "@mui/material";

import ClientsSearchBar from "../componenets/ClientsSearchBar";
import NewClient from "../componenets/NewClient";
import {useEffect, useMemo, useState} from "react";
import MiniClients from "../componenets/MiniClients";
import {disableClients, enableClients, getClients} from "../services/TraineeService";
import MessageDialog from "../componenets/client/MessageDialog";
import {useLocation} from "react-router-dom";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";
import {useCompany} from "../contexts/CompanyContext";

const Clients = () => {
  const [newClientOpen, setNewClientOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);

  const {company} = useCompany()
  const {notificationHandler} = useMessageContext();

  const openNewClient = () => {
    setNewClientOpen(true);
  }

  const closeNewClient = () => {
    setNewClientOpen(false);
  }

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();


  useEffect(() => {
    setClientsState((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        pageId: 0,
        clients: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  }, [company])

  const addOrRemoveClient = (client) => {
    if (selectedClients.some(item => item.id === client.id)){
      setSelectedClients(prv => {
       return prv.filter((item) => item.id !== client.id);
      });
    } else {
      setSelectedClients(prv => {
        let newList = [...prv];
        newList.push(client);
        return newList;
      })
    }
  }

  const [clientsState, setClientsState] = useState({
    clients: [],
    trainer: 0,
    totalNumberOfElements: 0,
    keyword: "",
    gender: "ALL",
    pageId: 0,
    hasMoreData: true,
    isLoading: true,
    state: query.has("state") ? query.get("state") : 'ALL'
  });

  const updateTrainer = (trainer) => {
    setClientsState((prv) => {
      return {
        ...prv,
        trainer: trainer,
        totalNumberOfElements: 0,
        pageId: 0,
        clients: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const updateKeyword = (keyword) => {
    setClientsState((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        keyword: keyword,
        pageId: 0,
        clients: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const updateGender = (gender) => {
    setClientsState((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        gender: gender,
        pageId: 0,
        clients: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const updateState = (state) => {
    query.delete("state");
    setClientsState((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        state: state,
        pageId: 0,
        clients: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const resetClientSearch = () => {
    setClientsState({
      clients: [],
      trainer: 0,
      totalNumberOfElements: 0,
      keyword: "",
      gender: "ALL",
      pageId: 0,
      hasMoreData: true,
      isLoading: true,
      state: 'ALL'
    })
  }


  const fetchNextPage = () => {
    getClients(
        clientsState.pageId,
        20,
        clientsState.keyword,
        clientsState.trainer,
        clientsState.gender,
        clientsState.state,
        company,
    )
        .then((res) => {
          const content = res.data.content;

          setClientsState((prevState) => {
            let newclients = [...prevState.clients, ...content];

            if (clientsState.pageId === 0) {
              newclients = content;
            }

            return {
              ...prevState,
              clients: newclients,
              totalNumberOfElements: res.data.totalElements,
              pageId: prevState.pageId + 1,
              hasMoreData: content.length !== 0,
              isLoading: false,
            };
          });
        })
        .catch((e) => {
          setClientsState((prvState) => {
            return { ...prvState, isLoading: false, hasMoreData: false };
          });
          console.log("Error: ", e);
        });
  };

  const handleSendMessage = () => {
    setMessageOpen(true);
  }

  const handleEnableClients = () => {
    const ids = [...selectedClients].map(item => item.id);
    enableClients(ids)
        .then(() => setSelectedClients([]))
        .then(resetClientSearch)
        .then(() => notificationHandler.success("משתמשים הופעלו בהצלחה"))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
        })
  }

  const handleDisableClients = () => {
    const ids = [...selectedClients].map(item => item.id);
    disableClients(ids)
        .then(() => setSelectedClients([]))
        .then(resetClientSearch)
        .then(() => notificationHandler.success("משתמשים בוטלו בהצלחה"))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
        })
  }

  return  (
      <>
        {newClientOpen && <NewClient newClientOpen={newClientOpen} closeNewClient={closeNewClient}/>}
      <Box sx={{marginRight: {md: "12px", xs: "0"}}}>
        <MessageDialog setSelectedClients={setSelectedClients} selectedClients={selectedClients} isOpen={messageOpen} close={() => setMessageOpen(false)}/>
        <ClientsSearchBar
            handleEnableClients={handleEnableClients}
            handleDisableClients={handleDisableClients}
            handleSendMessage={handleSendMessage}
            selectedClients={selectedClients}
            updateKeyword={updateKeyword}
            trainer={clientsState.trainer}
            gender={clientsState.gender}
            updateGender={updateGender}
            updateState={updateState}
            state={clientsState.state}
            updateTrainer={updateTrainer}
            openNewClient={openNewClient}/>

        <MiniClients addOrRemoveClient={addOrRemoveClient} selectedClients={selectedClients} clientsState={clientsState} fetchNextPage={fetchNextPage}/>
      </Box>
      </>
  );
};

export default Clients;
