import {Box, IconButton, InputBase, MenuItem, Typography} from "@mui/material";
import {ReactComponent as SearchIcon} from "../assets/images/search.svg";
import SelectCom from "./SelectCom";
import {experienceLevels} from "../enums/ExperienceLevels";
import {mainMuscles} from "../enums/MainMuscles";
import {SecondaryMuscles} from "../enums/SecondaryMuscles";

const TemplateSearchBar = ({numberOfWorkouts,
  updateNumberOfWorkouts,
  experienceLevel,
  updateExperienceLevel,
  keyword,
  updateKeyword,
  mainMuscle,
  secondaryMuscle,
  updateSecondaryMuscle,
  updateMainMuscle,
  updateGender,
  gender}) => {

  return (
      <>
          <Box sx={{marginTop: "30px", display: "flex", justifyContent: "flex-end"}}>
            <Box sx={{display: "flex"}}>
              <SelectCom
                  id={"gender"}
                  width={"100px"}
                  mainItem={"לא נבחר"}
                  label={"*מגדר"}
                  contrast={"black"}
                  marginLeft="33px"
                  // height={"56px"}
                  value={gender}
                  onChange={updateGender}
                  defaultValue={"null"}>
                <MenuItem value={"MALE"} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>זכר</Typography>
                </MenuItem>
                <MenuItem value={"FEMALE"}
                          sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>נקבה</Typography>
                </MenuItem>
              </SelectCom>
              <SelectCom
                  value={mainMuscle}
                  onChange={updateMainMuscle}
                  label={"שריר ראשי"}
                  defaultValue={"null"}
                  width={"150px"}
                  mainItem={"הכול"} contrast={"black"}  marginLeft="8px">
                {mainMuscles.map((res, i) => {
                  return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                    <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                  </MenuItem>
                })}
              </SelectCom>
              <SelectCom
                  value={secondaryMuscle}
                  onChange={updateSecondaryMuscle}
                  label={"שריר משני"}
                  defaultValue={"null"}
                  width={"150px"}
                  mainItem={"הכול"} contrast={"black"}  marginLeft="8px">
                {SecondaryMuscles.map((res, i) => {
                  return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                    <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                  </MenuItem>
                })}
              </SelectCom>
              <SelectCom
                  value={numberOfWorkouts}
                  onChange={updateNumberOfWorkouts}
                  label={"מספר אימונים בשבוע"}
                  defaultValue={0}
                  width={"150px"}
                  mainItem={"הכול"} contrast={"black"}  marginLeft="8px">
                {Array.from(Array(7).keys()).map((_, i) => {
                  return <MenuItem key={i} value={i+1} sx={{display: "flex", alignItems: "center"}}>
                    <Typography sx={{marginLeft: "8px"}}>{i+1}</Typography>
                  </MenuItem>
                })}
              </SelectCom>
              <SelectCom
                  label={"רמת מתאמן"}
                  value={experienceLevel}
                  onChange={updateExperienceLevel}
                  mainItem={"הכול"}
                  width={"150px"}
                  contrast={"black"}
                  marginLeft="8px"
                  defaultValue={'ALL'}>
                {experienceLevels.map((res, i) => {
                  return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                    <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                  </MenuItem>
                })}

              </SelectCom>
                <Box sx={{backgroundColor: "#F9FAFB",
                  border: "1px solid #A9ABB0",
                  borderRadius: "8px",
                  maxHeight: "41px",
                  marginRight: "8px",
                  width: "auto",
                  display: "inline-flex",
                }}>
                  <IconButton disabled={true} sx={{ p: '12px', marginLeft: "6px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                      onChange={(newValue) => updateKeyword(newValue.target.value)}
                      value={keyword}
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="חיפוש לפי שם"
                  />
                </Box>
            </Box>
          </Box>
        </>
  );
};

export default TemplateSearchBar;
