import Dialog from "../shared/Dialog";
import {Button, CardContent, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";

const NoteDialog = ({isOpen, close, approve, initValue}) => {
  const [value, setValue] = useState({...initValue});

  useEffect(() => {
    setValue( isOpen ? {...initValue} : {});
  },[isOpen])

  const handleChange = (value) => {
    setValue(prv => {
      return {
        ...prv,
        message: value
      }
    })
  }

  return (
      <Dialog isOpen={isOpen} close={close} title={"הודעה"}>
        <CardContent sx={{paddingLeft: "104px", paddingRight: "104px", paddingTop: "80px"}}>
          <Typography>תוכן ההודעה</Typography>
          <TextField
              sx={{marginTop: "8px"}}
              fullWidth
              value={value.message}
              onChange={(e) => handleChange(e.target.value)}
              minRows={5}
              id="filled-textarea"
              multiline
              variant="outlined"
          />
        </CardContent>
        <CardContent sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Button variant={"outlined"} onClick={close}>
            בטל
          </Button>
          <Button
              onClick={() => approve({...value})}
              sx={{marginRight: "24px"}}
              variant={"contained"}>
            שמור
          </Button>
        </CardContent>
      </Dialog>
  );
};

export default NoteDialog;
