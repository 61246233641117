import httpService from "./HttpService";

export const getClientsStats = async  (companyId) => {
  const token = localStorage.getItem("auth");
  let url = `admin/trainee/statistics`;


  if (companyId != null) {
    url += `?companyId=${companyId}`
  }

  return await httpService.get(
      url,
      null,
      token);
}

export const changeAutomatic = async (traineeId, value) => {
  const token = localStorage.getItem("auth");

  return await httpService.put(
      `admin/trainee/${traineeId}/automatic/${value}`, null, token);
}

export const getClients = async (pageId = 0,
    pageSize = 20
    , keyword,
    trainer,
    gender,
    state,
    companyId) => {
  const token = localStorage.getItem("auth");

  let url = `admin/trainee?pageId=${pageId}&pageSize=${pageSize}`

  if (keyword) {
    url += `&keyword=${keyword}`
  }

  if (trainer !== 0) {
    url += `&trainerId=${trainer}`
  }

  if (gender !== 'ALL') {
    url += `&gender=${gender}`
  }

  if (state !== 'ALL'){
    url += `&state=${state}`
  }

  if (companyId !== null) {
    url += `&companyId=${companyId.id}`
  }
  return await httpService.get(
      url,
      null,
      token);
}

export const createTrainee = async (body, companyId) => {
  const token = localStorage.getItem("auth");

  let url = `admin/trainee`;

  if (companyId) {
    url += `?companyId=${companyId}`
  }
  return await httpService.post(
      url,
      body,
      token);
}

export const getClient = async (traineeId) => {
  const token = localStorage.getItem("auth");
  return await httpService.get(
      `admin/trainee/${traineeId}`, null, token);
}

export const enableClients = async (ids) => {
  const token = localStorage.getItem("auth");
  return await httpService.put(
      `admin/trainee/enable`,
      {
        ids: ids
      },
      token);
}

export const disableClients = async (ids) => {
  const token = localStorage.getItem("auth");
  return await httpService.put(
      `admin/trainee/disable`,
      {
        ids: ids
      },
      token);
}

export const uploadUserImage = async (file) => {
  const token = localStorage.getItem("auth");
  const formData = new FormData();

  // Update the formData object
  formData.append(
      "file",
      file,
      file.name
  );

  return await httpService.post(
      `admin/trainee/updateImage`,
      formData,
      token);
}

export const updateWeightTable = async (userId, row) => {
  const token = localStorage.getItem("auth");

  return await httpService.post(
      `admin/weights/${userId}`,
      row,
      token);
}

export const getClientWeightTable = async (userId, from, to) => {
  const token = localStorage.getItem("auth");

  let url = `admin/weights/table/${userId}?from=${from.unix()}&to=${to.unix()}`;

  return await httpService.get(
      url,
      null,
      token);
}

export const getClientWeights = async (userId) => {
  const token = localStorage.getItem("auth");

  return await httpService.get(
      `admin/weights/${userId}`,
null,
      token);
}

export const addClientNutrition = async (body, file) => {
  const token = localStorage.getItem("auth");

  const formData = new FormData();

  if (file) {
    await appendFile(file, formData, "file");
  }

  formData.append(
      "metaData",
      JSON.stringify(body)
  )

  return await httpService.post(
      `admin/nutrition`,
      formData,
      token);

}


const appendFile = async (file, formData, filedName) => {
  if (typeof file === 'string' || file instanceof String) {
    let blob = await fetch(file)
        .then(r => r.blob())
        .catch(e => {
          console.log("error")
        })

      if (blob) {
        // Update the formData object
        formData.append(filedName, blob, blob.name);
      }
    }
    else {
      formData.append(filedName, file);
    }
}


export const getClientNutrition = async (userId) => {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/nutrition/latest/${userId}`, null, token);
}

export const getClientNutritions = async (userId) => {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/nutrition/${userId}`, null, token);
}

export const deleteNutrition = async (menuId) => {
  const token = localStorage.getItem("auth");
  return httpService.delete(`admin/nutrition/${menuId}`, null, token);
}

export const updateTrainee = async (trainee) => {
  const token = localStorage.getItem("auth");
  return httpService.put(`admin/trainee`, trainee, token);
}

export const updateClientNote = async (body) => {
  const token = localStorage.getItem("auth");
  return httpService.put(`admin/notes`, body, token);
}

export const updateTrainer = async (treaineId, trainerId) => {
  const token = localStorage.getItem("auth");
  return httpService.put(`admin/trainee/updateTrainer/${treaineId}/${trainerId}`, null, token);
}

export const saveClientNote = async (body) => {
  const token = localStorage.getItem("auth");
  return httpService.post(`admin/notes`, body, token);
}
export const getClientNotes = async (userId) => {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/notes/${userId}`, null, token);
};

export const deleteNote = async (noteId) => {
  const token = localStorage.getItem("auth");
  return httpService.delete(`admin/notes/${noteId}`, null, token);
};

export const deleteTrainee = async (id) => {
  const token = localStorage.getItem("auth");
  return httpService.delete(`admin/trainee/${id}`, null, token);
};
