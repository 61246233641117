import {Box, Button, MenuItem, Typography} from "@mui/material";
import SelectCom from "./SelectCom";
import {useCompany} from "../contexts/CompanyContext";
import {useAuth} from "../contexts/AuthContext";
import {experienceLevels} from "../enums/ExperienceLevels";
import {useEffect, useState} from "react";
import {EquipmentTypes} from "../enums/EquipmentType";
import {getTemplates} from "../services/TemplatesService";
import {InjuryNotes} from "../enums/InjuryNotes";
import {getTrainers} from "../services/TrainerService";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";
import {mainMuscles} from "../enums/MainMuscles";
import {SecondaryMuscles} from "../enums/SecondaryMuscles";

const NewClientStep2 = ({trainee, updateField, nextStep, prvStep}) => {

  const {getCompaniesList} = useCompany();
  const {isSuperAdmin, isTrainer, isCompanyAdmin} = useAuth();

  const {notificationHandler} = useMessageContext();

  const [profileFields, setProfileFields] = useState({
    experienceLevel: "ALL",
    numOfWorkouts: "null",
    mainMuscle: "null",
    secondaryMuscle: "null",
    secondaryMuscle2: "null",
    equipmentType: "null",
  })

  const [availableTemplates, setAvailableTemplates] = useState([])

  const [availableTrainers, setAvailableTrainers] = useState([])

  const updateProfileField = (value, keyName) => {
    setProfileFields(prv => {
      return {
        ...prv,
        [keyName] : value
      };
    })
  }

  const canFetchTemplates = () => {
    // return profileFields.experienceLevel !== "null" ||
    //     profileFields.numOfWorkouts !== "null" ||
    //     profileFields.mainMuscle !== "null" ||
    //     profileFields.equipmentType !== "null";
    return true;
  }

  useEffect(() => {
      getTrainers(trainee.companyId)
          .then(res => setAvailableTrainers(res.data))
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
  }, [trainee.companyId])

  useEffect(() => {
    if (canFetchTemplates()){
      getTemplates(0, 50,
          profileFields.numOfWorkouts,
          profileFields.experienceLevel,
          null,
          profileFields.mainMuscle,
          profileFields.equipmentType,
          true,
          profileFields.secondaryMuscle,
          profileFields.secondaryMuscle2,
          trainee.gender)
          .then(res => setAvailableTemplates(res.data.content))
    }
  }, [profileFields])

  return (<Box>
         <Box
            sx={{
              display: "flex",
              alignItems: "top",
              justifyContent: "space-between",
              paddingTop: "40px",
              paddingLeft: "100px",
              paddingRight: "100px"
            }}>
          <Box sx={{width: "320px"}}>
            <Typography sx={{marginBottom: "8px"}}>
              *רמת מתאמן:
            </Typography>
            <SelectCom
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"רמת מתאמן"}
                contrast={"black"}
                height={"56px"}
                value={profileFields.experienceLevel}
                onChange={e => updateProfileField(e, "experienceLevel")}
                defaultValue={"ALL"}>
              {experienceLevels.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            <Typography sx={{marginTop: "24px", marginBottom: "8px"}}>
              *מספר אימונים בשבוע:
            </Typography>
            <SelectCom
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"מספר אימונים בשבוע"}
                contrast={"black"}
                height={"56px"}
                value={profileFields.numOfWorkouts}
                onChange={e => updateProfileField(e, "numOfWorkouts")}
                defaultValue={"null"}>
              {Array.from(Array(7).keys()).map((_, i) => {
                return <MenuItem key={i} value={i+1}>{i+1}</MenuItem>
              })}
            </SelectCom>
            <Typography sx={{marginTop: "24px", marginBottom: "8px"}}>
              *שריר ראשי:
            </Typography>
            <SelectCom
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"שריר"}
                contrast={"black"}
                height={"56px"}
                value={profileFields.mainMuscle}
                onChange={e => updateProfileField(e, "mainMuscle")}
                defaultValue={"null"}>
              {mainMuscles.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
          </Box>
          <Box sx={{marginRight: "80px", width: "320px"}}>
            <Typography sx={{marginBottom: "8px", }}>
              *ציוד קיים:
            </Typography>
            <SelectCom
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"ציוד קיים"}
                contrast={"black"}
                height={"56px"}
                value={profileFields.equipmentType}
                onChange={e => updateProfileField(e, "equipmentType")}
                defaultValue={"null"}>
              {EquipmentTypes.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            <Typography sx={{marginTop: "24px", marginBottom: "8px"}}>
              שריר ספציפי :
            </Typography>
            <SelectCom
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"שריר"}
                contrast={"black"}
                height={"56px"}
                value={profileFields.secondaryMuscle}
                onChange={e => updateProfileField(e, "secondaryMuscle")}
                defaultValue={"null"}>
              {SecondaryMuscles.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            <Typography sx={{marginTop: "24px", marginBottom: "8px"}}>
              שריר ספציפי 2:
            </Typography>
            <SelectCom
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"שריר"}
                contrast={"black"}
                height={"56px"}
                value={profileFields.secondaryMuscle2}
                onChange={e => updateProfileField(e, "secondaryMuscle2")}
                defaultValue={"null"}>
              {SecondaryMuscles.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            <Typography sx={{marginBottom: "8px", marginTop: "24px", }}>
              *סוג פרופיל:
            </Typography>
            <SelectCom
                disabled={!(profileFields.experienceLevel !== "null" ||
                    profileFields.numOfWorkouts !== "null" ||
                    profileFields.targetMuscle !== "null" ||
                    profileFields.equipmentType !== "null")}
                id={"lvl"}
                width={"320px"}
                mainItem={"לא נבחר"}
                label={"סוג פרופיל"}
                contrast={"black"}
                height={"56px"}
                value={trainee.templateId}
                onChange={e => updateField(e, "templateId")}
                defaultValue={"null"}>
              {availableTemplates.map((value, i) => {
                return <MenuItem key={i} value={value.id} sx={{display: "flex", alignItems: "center"}}>
                  <Box>
                    <Typography sx={{marginLeft: "8px"}}>{value.nameHeb}</Typography>
                    <Box sx={{display: "flex", alignItems: "right", justifyContent: "center"}}>
                      <Typography sx={{fontSize: "12px", color: "#57837e"}}>{value.numOfWorkouts}</Typography>
                      <Typography sx={{fontSize: "12px", color: "#57837e"}}>{value.gender === "FEMALE" && "נקבה"}
                        -
                        {value.gender === "MALE" && "זכר"}
                        {!value.gender && "אין מידע"}</Typography>
                      <Typography sx={{fontSize: "12px", color: "#57837e"}}>
                        -{value.experienceLevel ? experienceLevels.find(lvl => lvl.value === value.experienceLevel).name :  "אין מידע"}
                      </Typography>
                      <Typography sx={{fontSize: "12px", color: "#57837e"}}>
                        -{value.mainMuscle ? mainMuscles.find(lvl => lvl.value === value.mainMuscle).name :  "אין מידע"}
                      </Typography>
                      <Typography sx={{fontSize: "12px", color: "#57837e"}}>
                        -{value.secondaryMuscle ? SecondaryMuscles.find(lvl => lvl.value === value.secondaryMuscle).name :  "אין מידע"}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
              })}
            </SelectCom>
        </Box>
           <Box sx={{marginRight: "80px", width: "320px"}}>
             <Typography sx={{marginBottom: "8px"}}>
               *מגבלות/פציעות (אופציונלי):
             </Typography>
             <SelectCom
                 id={"lvl"}
                 width={"320px"}
                 mainItem={"לא נבחר"}
                 label={"סוג פרופיל"}
                 contrast={"black"}
                 height={"56px"}
                 value={trainee.notes}
                 onChange={e => updateField(e, "notes")}
                 defaultValue={"null"}>
               {InjuryNotes.map((value, i) => {
                 return <MenuItem key={i} value={value} sx={{display: "flex", alignItems: "center"}}>
                   <Typography sx={{marginLeft: "8px"}}>{value}</Typography>
                 </MenuItem>
               })}
             </SelectCom>
             {isSuperAdmin() && <Box sx={{marginTop: "30px"}}>
               <Typography>
                 *חברה:
               </Typography>
               <SelectCom
                   id={"company"}
                   width={"320px"}
                   mainItem={"לא נבחר"}
                   label={"חברה"}
                   contrast={"black"}
                   height={"56px"}
                   value={trainee.companyId}
                   onChange={e => updateField(e, "companyId")}
                   defaultValue={"null"}>
                 {getCompaniesList().map((company, i) => {
                   return <MenuItem key={i} value={company.id}
                                    sx={{display: "flex", alignItems: "center"}}>
                     <Typography sx={{marginLeft: "8px"}}>{company.name}</Typography>
                   </MenuItem>
                 })}
               </SelectCom>
             </Box>}
             {(isSuperAdmin() || isCompanyAdmin()) && <Box sx={{marginTop: "30px"}}>
               <Typography>
                 *מאמן:
               </Typography>
               <SelectCom
                   id={"trainer"}
                   width={"320px"}
                   mainItem={"לא נבחר"}
                   label={"מאמן"}
                   contrast={"black"}
                   height={"56px"}
                   value={trainee.trainerId}
                   onChange={e => updateField(e, "trainerId")}
                   defaultValue={"null"}>
                 {availableTrainers.map((trainer, i) => {
                   return <MenuItem key={i} value={trainer.id}
                                    sx={{display: "flex", alignItems: "center"}}>
                     <Typography sx={{marginLeft: "8px"}}>{trainer.name}</Typography>
                   </MenuItem>
                 })}
               </SelectCom>
             </Box>}
           </Box>
      </Box>
        <Box sx={{marginTop: "80px", paddingBottom: "40px", textAlign: "center"}}>
          <Button
              onClick={prvStep}
              sx={{marginRight: "24px", backgroundColor: "#F1F3F5", color: "black"}}
              variant={"contained"}>
            אחורה
          </Button>
          <Button
              disabled={trainee.templateId === "null" ||
                  trainee.templateId === undefined ||
                  (isSuperAdmin() && trainee.companyId === 'null') ||
                  (isSuperAdmin() && trainee.companyId === undefined) ||
                  (isSuperAdmin() && trainee.trainerId === 'null') ||
                  (isSuperAdmin() && trainee.trainerId === undefined)}
              onClick={nextStep}
              sx={{marginRight: "24px"}}
              variant={"contained"}>
            המשך
          </Button>
        </Box>
      </Box>);
}

export default NewClientStep2;
