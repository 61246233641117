import Dialog from "./shared/Dialog";
import {Box, Button, MenuItem, Switch, TextField, Typography} from "@mui/material";
import SelectCom from "./SelectCom";
import {useCompany} from "../contexts/CompanyContext";
import {useAuth} from "../contexts/AuthContext";
import {useFormik} from "formik";
import {createTrainer, promotToTrainer, updateTrainer} from "../services/TrainerService";
import {useNavigate} from "react-router-dom";
import {useMessageContext} from "../contexts/MessageContext";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useEffect, useState} from "react";
import {getUsersWithoutRoles} from "../services/UserService";

const NewTrainerDialog = ({open, close, trainer, reload}) => {

  const {getCompaniesList} = useCompany();
  const {isSuperAdmin} = useAuth();
  let navigate = useNavigate();
  const {notificationHandler} = useMessageContext();

  const [exsistingUser, setExsistingUser] = useState(!trainer);

  const [avilibleUsers, setAvilibleUsers] = useState([]);

  useEffect(() => {
    getUsersWithoutRoles("TRAINER").then(res => {
      setAvilibleUsers(res.data);
    })
  }, []);

  const isDisabled = () => {
    if (formik.values.userId != null) {
      return true;
    } else {
      return formik.values.name == null || formik.values.phoneNumber == null || formik.values.email == null;
    }
  }

  const formik = useFormik({
    initialValues: {
      name: trainer ? trainer.name : "",
      phoneNumber: trainer ? trainer.phoneNumber : "",
      email: trainer ? trainer.email : "",
      gender: trainer ? trainer.gender : "null",
      companyId: trainer ? trainer.companyId : "null",
      id: trainer ? trainer.id : null,
      userId: "null",
      password: "",
      secondPassword: ""
    },
    onSubmit: (values, {resetForm}) => {
      let body = {...values};
      console.log(body);
      console.log(trainer);

      body.gender = body.gender === "null" ? null : body.gender;
      body.userId = body.userId === "null" ? null : body.userId;
      body.companyId = body.companyId === "null" ? null : body.companyId;

      delete body.secondPassword;

      body.password = body.password === "" ? null : body.password;

      if (exsistingUser) {
        promotToTrainer(body.userId)
            .then((res) => {
          notificationHandler.success('מאמן עודכן בהצלחה');
          navigate(`/app/trainers/${res.data.id}`)
            }).catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
          formik.setFieldError("tmp","");
        })
      } else if (!trainer ) {
        createTrainer(body)
            .then((res) => {
              notificationHandler.success('מאמן נוצר בהצלחה');
              navigate(`/app/trainers/${res.data.id}`)
            }).catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
          formik.setFieldError("tmp","");
        })
      } else {
        updateTrainer(body)
            .then(() => notificationHandler.success('מאמן התעדכן בהצלחה'))
            .then(() => reload(prv => !prv))
            .then(handleClose)
            .catch(e => {
              notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
            })
      }

    },
    validate: values => {
      let errors = {};

      if (!trainer && !exsistingUser && !values.password) {
        errors.password = "שדה חובה";
      }

      if (values.password !== values.secondPassword) {
        errors.secondPassword = "סיסמאות לא זהה";
      }

      return errors;
    }});

  const handleClose = () => {
    formik.resetForm({values: ''});
    close();
  }

  return (
      <Dialog isOpen={open} close={handleClose} title={"מאמן"}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{display: formik.values.id ? "none" :  "flex", alignItems: "center", mt: "10px", mr: "15px" }}>
            <Typography>משתמש קיים:</Typography>
            <Switch checked={exsistingUser} onChange={() => setExsistingUser(prv => {
              formik.values.userId = "null";
              return !prv;
            })}/>
          </Box>
          {!exsistingUser &&
        <Box sx={{display: "flex", alignItems: "top", justifyContent: "space-between", padding: "40px"}}>
          <Box>
            <Typography>
              *שם מלא:
            </Typography>
            <TextField
                sx={{marginTop: "8px"}}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.name}
                onChange={formik.handleChange}
                id="name"
                variant="outlined"
            />
            {formik.errors.name && formik.touched.name && <Typography sx={{color: "#F16063"}}>{formik.errors.name}</Typography>}

            <Typography sx={{marginTop: "24px"}}>
              טלפון:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                id="phoneNumber"
                variant="outlined"
            />
            {formik.errors.phoneNumber && formik.touched.phoneNumber && <Typography sx={{color: "#F16063"}}>{formik.errors.phoneNumber}</Typography>}

            <Typography sx={{marginTop: "24px"}}>
              *אימייל:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                id="email"
                variant="outlined"
            />
            {formik.errors.email && formik.touched.email && <Typography sx={{color: "#F16063"}}>{formik.errors.email}</Typography>}

            <SelectCom
                id={"gender"}
                width={"100px"}
                mainItem={"לא נבחר"}
                label={"מגדר"}
                contrast={"black"}
                marginLeft="8px"
                onBlur={formik.handleBlur("gender")}
                value={formik.values.gender}
                onChangeReal={formik.handleChange("gender")}
                defaultValue={"null"}>
              <MenuItem value={"MALE"} sx={{display: "flex", alignItems: "center"}}>
                <Typography sx={{marginLeft: "8px"}}>זכר</Typography>
              </MenuItem>
              <MenuItem value={"FEMALE"} sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Typography sx={{marginLeft: "8px"}}>נקבה</Typography>
              </MenuItem>
            </SelectCom>
            {isSuperAdmin() && <Box sx={{marginTop: "20px"}}>
              <SelectCom
                  id={"companyId"}
                  width={"100px"}
                  mainItem={"לא נבחר"}
                  label={"חברה"}
                  contrast={"black"}
                  marginLeft="8px"
                  onBlur={formik.handleBlur("companyId")}
                  value={formik.values.companyId}
                  onChangeReal={formik.handleChange("companyId")}
                  defaultValue={"null"}>
                {getCompaniesList().map((company, i) => {
                  return <MenuItem key={i} value={company.id} sx={{display: "flex", alignItems: "center"}}>
                    <Typography sx={{marginLeft: "8px"}}>{company.name}</Typography>
                  </MenuItem>
                })}
              </SelectCom>
              {formik.errors.companyId && formik.touched.companyId && <Typography sx={{color: "#F16063"}}>{formik.errors.companyId}</Typography>}
            </Box>}
          </Box>

          <Box sx={{marginRight: "80px", paddingLeft: "40px"}}>
            <Typography>
              *סיסמה:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                type="password"
                onBlur={formik.handleBlur}
                value={formik.values.password}
                onChange={formik.handleChange}
                id="password"
                variant="outlined"
            />
            {formik.errors.password && formik.touched.password && <Typography sx={{color: "#F16063"}}>{formik.errors.password}</Typography>}

            <Typography>
              *סיסמה בשנית:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                type="password"
                onBlur={formik.handleBlur}
                value={formik.values.secondPassword}
                onChange={formik.handleChange}
                id="secondPassword"
                variant="outlined"
            />
            {formik.errors.secondPassword && formik.touched.secondPassword && <Typography sx={{color: "#F16063"}}>{formik.errors.secondPassword}</Typography>}
          </Box>
        </Box>}
          {exsistingUser && avilibleUsers &&
              <Box sx={{mb: "20px", mt: "20px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <SelectCom
                    id={"user"}
                    width={"200px"}
                    mainItem={"לא נבחר"}
                    label={"משתמש"}
                    contrast={"black"}
                    marginLeft="8px"
                    value={formik.values.userId}
                    onChangeReal={formik.handleChange("userId")}
                    defaultValue={"null"}>
                  {avilibleUsers.map((res, i) => {
                    return <MenuItem key={i} value={res.id} sx={{display: "flex", alignItems: "center"}}>
                      <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                    </MenuItem>
                  })}
                </SelectCom>
              </Box>
          }
        <Box sx={{paddingBottom: "40px", textAlign: "center"}}>
          <Button
              onClick={handleClose}
              sx={{marginRight: "24px", backgroundColor: "#F1F3F5", color: "black"}}
              variant={"contained"}>
            בטל
          </Button>
          <Button
              disabled={(exsistingUser && formik.values.userId === "null") ||
                  (!exsistingUser && (formik.values.name === '' || formik.values.email === ''))

          }
              type={"submit"}
              sx={{marginRight: "24px"}}
              variant={"contained"}>
            שמור
          </Button>
        </Box>
        </form>
      </Dialog>
  )
};

export default NewTrainerDialog;
