export const experienceLevels = [
  {
    name: "ללא ניסיון כלל",
    value: "BEGINNER"
  },
  {
    name: "מתחיל",
    value: "BEGINNER_ADVANCE"
  },
  {
    name: "בינוני",
    value: "MEDIUM"
  },
  {
    name: "מקצוען",
    value: "ADVANCE"
  },
]
