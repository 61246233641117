import httpService from "./HttpService";

let exerciseByTypeCache = {};

let exerciseByIdCache = {};

export const getByTypes = async (targetMuscle) => {
  const token = localStorage.getItem("auth");

  if (!exerciseByTypeCache[targetMuscle]) {
    let data = await httpService.get(
        `admin/exercise/type?targetMuscle=${targetMuscle}`,
        null,
        token);

    exerciseByTypeCache[targetMuscle] = data;
  }
  return exerciseByTypeCache[targetMuscle];
}

export const saveExercise = async (metaData, imageFile, coverImage, skeleton) => {
  exerciseByTypeCache = {};
  exerciseByIdCache = {};

  const token = localStorage.getItem("auth");
  const formData = new FormData();


  if (imageFile) {
    await appendFile(imageFile, formData, "image");
  }

  if (coverImage) {
    await appendFile(coverImage, formData, "cover");
  }

  if (skeleton) {
    await appendFile(skeleton, formData, "skeleton");
  }

  formData.append(
      "metaData",
      metaData
  )

  return await httpService.post(
      `admin/exercise`,
      formData,
      token);
}

const appendFile = async (file, formData, filedName) => {
  let blob = await fetch(file)
      .then(r => r.blob())
      .catch(e => {
      })

  if (blob) {
    // Update the formData object
    formData.append(filedName, blob, blob.name);
  }
}

export const getExercise = async (id) => {

  if (!exerciseByIdCache[id]) {
    const token = localStorage.getItem("auth");

    const data =  await httpService.get(
        `admin/exercise/${id}`,
        null,
        token);

    exerciseByIdCache[id] = data;
  }

  return exerciseByIdCache[id];
}

export const getExercises = async (pageId = 0, pageSize = 20,
    keyword,
    resistanceType,
    targetMuscle,
    specificMuscle) => {
  const token = localStorage.getItem("auth");

  let url = `admin/exercise?pageId=${pageId}&pageSize=${pageSize}`;

  if (keyword) {
    url += `&keyword=${keyword}`
  }

  if (resistanceType.value !== null){
    url += `&resistanceType=${resistanceType.value}`
  }

  if (targetMuscle.value !== null){
    url += `&targetMuscle=${targetMuscle.value}`
  }

  if (specificMuscle !== 'ALL'){
    url += `&specificMuscle=${specificMuscle}`
  }

  return await httpService.get(
      url,
      null,
      token);
}
