export const targetMuscles = [
  {
    value: "LEGS_QUADS",
    name: "רגליים-שרשרת קדמית"
  },
  {
    value: "LEGS_HAMSTRINGS",
    name: "רגליים-שרשרת אחורית"
  },
  {
    value: "LEGS_GLUTES",
    name: "רגליים-ישבן"
  },
  {
    value: "LEGS_ADDUCTORS",
    name: "רגליים-מקרבים"
  },
  {
    value: "LEGS_ABDUCTORS",
    name: "רגליים-מרחיקים"
  },
  {
    value: "LEGS_CALVES",
    name: "רגליים-תאומים"
  },
  {
    value: "BACK_ROWES",
    name: "גב-חתירות"
  },
  {
    value: "BACK_PULLS",
    name: "גב-משיכות"
  },
  {
    value: "BACK_LOWER_BACK",
    name: "גב-גב תחתון"
  },
  {
    value: "CHEST_UPPER",
    name: "חזה-עליון"
  },
  {
    value: "CHEST_MIDDLE",
    name: "חזה-אמצעי"
  },
  {
    value: "CHEST_LOWER",
    name: "חזה-תחתון"
  },
  {
    value: "ARMS_BICEPS",
    name: "ידיים-יד קדמית"
  },
  {
    value: "ARMS_TRICEPS",
    name: "ידיים-יד אחורית"
  },
  {
    value: "ARMS_FOREARMS",
    name: "ידיים-אמות"
  },
  {
    value: "SHOULDERS_FRONT_DELTS",
    name: "כתפיים-כתף קדמית"
  },
  {
    value: "SHOULDERS_LATERAL_DELTS",
    name: "כתפיים-כתף אמצעית"
  },
  {
    value: "SHOULDERS_REAR_DELTS",
    name: "כתפיים-כתף אחורית"
  },
  {
    value: "SHOULDERS_TRAPS",
    name: "כתפיים-טרפזים"
  },
  {
    value: "ABS_OBLIQUES",
    name: "בטן-אלכסונים"
  },
  {
    value: "ABS",
    name: "בטן"
  },
  {
    value: "CARDIO",
    name: "קרדיו"
  },
]
