import {Box, Button, CardContent, Hidden, TextField, Typography} from "@mui/material";
import {ReactComponent as CloseIcon} from "../assets/images/close.svg";
import {ReactComponent as LogoIcon} from "../assets/images/logo.svg";

const ForgottenPasswordEmailStep = ({close, email, setEmail, handleSendToken}) => {

  return (
      <>
      <Box sx={{display: "flex",
        flexDirection: "column"}}>
        <CardContent sx={{paddingBottom: 0,paddingLeft: "30px", display:"flex", justifyContent: "end"}}>
          <Box sx={{cursor: "pointer"}} onClick={close}>
            <CloseIcon/>
          </Box>
        </CardContent>
        <CardContent sx={{padding: 0, marginTop: "12px", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Typography sx={{fontWeight: {md: 700, xs: "600"}, fontSize: {md: "40px", xs: "28px"}, lineHeight: {md:"52px", xs: "38px"}}} color="primary.light">שכחת סיסמה</Typography>
        </CardContent>
        <CardContent>
          <Typography sx={{marginLeft: "auto", marginRight: "auto", color:"#718096", fontWeight: {md:400, xs: 600}, fontSize: {md: "20px", xs: "14px"}, lineHeight: {md: "136.02%", xs: "18.31px"}, textAlign: "center"}} maxWidth="402px">אופס, זה קורה לטובים שבינינו.
            תכניס את הכתובת מייל שלך בשביל לטפל בבעיה. </Typography>
        </CardContent>
        <CardContent >
          <Box sx={{width: "90%", maxWidth: "414px", marginRight: "auto", marginLeft: "auto"}}>
            <Typography color="primary.light">מייל</Typography>
            <TextField value={email} onChange={(event) => setEmail(event.target.value)} sx={{width: "100%"}}  placeholder="gmail.com@" id="email" variant="outlined" />
          </Box>
        </CardContent>
        <CardContent>
          <Box sx={{width: "90%", maxWidth: "414px", marginRight: "auto", marginLeft: "auto", pb: "40px"}}>
            <Button
                onClick={handleSendToken}
                disabled={email.length < 1}
                sx={{width: "100%"}} variant="contained">
              שלח
            </Button>
          </Box>
        </CardContent>
      </Box>
  <Hidden mdUp>
    <Box>
      <CardContent sx={{textAlign: "center"}}>
        <LogoIcon/>
      </CardContent>
    </Box>
  </Hidden>
      </>
  );
};

export default ForgottenPasswordEmailStep;
