import {useNavigate, useParams} from "react-router-dom";
import ClientPageHeader from "../componenets/ClientPageHeader";
import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import ClientTabs from "../componenets/ClientTabs";
import ClientPageMainSection from "../componenets/ClientPageMainSection";
import {ReactComponent as BackIcon} from '../assets/images/backIcon.svg';
import ClientPageProfile from "../componenets/ClientPageProfile";
import ClientPageNotes from "../componenets/client/ClientPageNotes";
import {getClient, getClientNotes} from "../services/TraineeService";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";

const ClientPage = () => {
  let navigate = useNavigate();

  const params = useParams();

  const [tabValue, setTabValue] = useState('one');
  const [client, setClient] = useState();

  const {notificationHandler} = useMessageContext();

  const [reload, setReload] = useState(false);

  const [hasNotes, setHasNotes] = useState(false);

  const handleReload = () => {
    setReload(prv => !prv);
  }

  useEffect(() => {
    getClient(params.userId)
        .then(res => {
          setClient(res.data);
        }).catch((e) => {
        notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
    });
  }, [reload])

  useEffect(() => {
    getClientNotes(params.userId).then(r => {
      setHasNotes(r.data.length > 0);
    }).catch(e => {
      notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
    })
  }, [reload]);


  return (
      <Box sx={{display: "flex", flexDirection: "row"}}>
        <Box sx={{flex: 9, marginRight: "20px"}}>
          {client && <ClientPageHeader client={client}/>}
          <ClientTabs hasNotes={hasNotes} tabValue={tabValue} setTabValue={setTabValue}/>
          {tabValue === "one" && <ClientPageMainSection userId={params.userId}/>}
          {tabValue === "two" && <ClientPageProfile handleReload={handleReload} client={client}/>}
          {tabValue === "three" && <ClientPageNotes client={client}/>}
        </Box>
        <Box sx={{marginTop: "20px", cursor: "pointer", marginLeft: "20px", marginRight: "20px"}} onClick={() => navigate(-1)}>
        <BackIcon/>
        </Box>
      </Box>
  );
};

export default ClientPage;
