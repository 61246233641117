import {Box, Divider, Paper, Typography} from "@mui/material";
import {ReactComponent as DocIcon} from '../assets/images/doc.svg';
import {ReactComponent as WeightIcon} from '../assets/images/weight.svg';
import {ReactComponent as LeftArrowIcon} from '../assets/images/left-arrow.svg';
import Chart from 'react-apexcharts'
import ClientBlocks from "./ClientBlocks";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getClientNutrition, getClientWeights} from "../services/TraineeService";
import moment from "moment";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";

const ClientPageMainSection = ({userId}) => {
  let navigate = useNavigate();

  const {notificationHandler} = useMessageContext();
  const [clientNutrition, setClientNutrition] = useState({
    loading: true,
    nutrition: {}
  });

  const [clientWeights, setClientWeights] = useState({
    loading: true,
    data: {}
  });

  useEffect(() => {
    getClientNutrition(userId).then(r => {
      setClientNutrition({
        loading: false,
        nutrition: r.data
      });
    }).catch(e => {
      notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
    });

    getClientWeights(userId).then(r => {
      setClientWeights({
        loading: false,
        data: r.data
      })
    }).catch(e => {
      notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
    })
  }, []);

  const getState = () => {
    return {
      series: [clientNutrition.nutrition.carbs , clientNutrition.nutrition.proteins, clientNutrition.nutrition.fats], options: {
        plotOptions: {
          pie: {
            expandOnClick: false, donut: {
              size: '40%'
            }
          }
        }, dataLabels: {
          dropShadow: {
            enabled: false,
          }, enabled: true, style: {
            colors: ['#FCFCFD', '#FCFCFD', '#4F535C'],
          },
        }, labels: ["פחמימה", "חלבון", "שומן"], markers: {
          width: 50, height: 50,
        }, legend: {
          show: false,
        }, colors: ["#57837E", "#69B8AF", "#B9D9D5"], chart: {
          type: 'donut',
        }
      },
    };
  }

  const getState2 = () => {
    return {
    series: [{
      name: "משקל",
      data: clientWeights.data.records.map(record => {
        return record.data;
      })
    }],
    options: {
      dataLabels: {
        enabled: false
      },
      colors:["#57837E"],
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0]
        },
      },
      chart: {
        toolbar: {
          show: false
        },
        type: 'line',
        zoom: {
          enabled: false
        },
      },
      xaxis: {
        categories: clientWeights.data.records.map(record => {
          return moment(record.date * 1000).format("DD/MM");
        })
        ,
      },
  }};
}

  return (
    <>
      {!clientNutrition.loading &&
    <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}, marginTop: "35px"}}>
      <Box sx={{flexBasis: "40%"}}>
        <Paper sx={{
          backgroundColor: "#FCFCFD",
          border: "1.5px solid #F2F4F7",
          minHeight: "297px",
          flexGrow: 0,
          borderRadius: "4px"
        }}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Box sx={{display: "flex", alignItems: "center", marginRight: "8px"}}>
              <DocIcon/>
              <Typography sx={{
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "27.3px",
                color: "#4F535C",
                padding: "10px"
              }}>תפריט תזונה</Typography>
            </Box>
            <Box sx={{marginLeft: "8px", cursor: "pointer"}}
                 onClick={() => navigate(`/app/clients/${userId}/nutrition`)}>
              <LeftArrowIcon/>
            </Box>
          </Box>
          <Divider/>
          <Box sx={{display: "flex", marginTop: "14px", justifyContent: "space-between"}}>
            <Box sx={{marginRight: "20px"}}>
              <Typography sx={{
                color: "#717784", fontWeight: 600, fontSize: "18px", lineHeight: "23px",
              }}>קלוריות: {clientNutrition.nutrition.calories}</Typography>
              <Typography sx={{
                fontWeight: 400,
                marginTop: "15px",
                fontSize: "14px",
                lineHeight: "23px",
                color: "#717784",
                textDecoration: "underline",
                textUnderlineOffset: "8px",
                textDecorationColor: "#57837E",
                textDecorationThickness: '3px'
              }}>פחמימה: {clientNutrition.nutrition.carbs}</Typography>
              <Typography sx={{
                fontWeight: 400,
                fontSize: "14px",
                marginTop: "15px",
                lineHeight: "23px",
                color: "#717784",
                textDecoration: "underline",
                textUnderlineOffset: "8px",
                textDecorationColor: "#69B8AF",
                textDecorationThickness: '3px'
              }}>חלבון: {clientNutrition.nutrition.proteins}</Typography>
              <Typography sx={{
                fontWeight: 400,
                fontSize: "14px",
                marginTop: "15px",
                lineHeight: "23px",
                color: "#717784",
                textDecoration: "underline",
                textUnderlineOffset: "8px",
                textDecorationColor: "#B9D9D5",
                textDecorationThickness: '3px'
              }}>שומן: {clientNutrition.nutrition.fats}</Typography>
            </Box>
            <Box sx={{marginLeft: "30px", marginTop: "10px"}}>
              {!clientNutrition.loading && <Chart options={getState().options} width={230} height={230} series={getState().series}
                     type="donut"/>}
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box sx={{
        marginRight: {md: "20px", xs: 0},
        flexBasis: "60%",
        flexGrow: 0,
        marginTop: {md: 0, xs: "20px"}
      }}>
        <Paper sx={{
          backgroundColor: "#FCFCFD",
          border: "1.5px solid #F2F4F7",
          minHeight: "241px",
          flexGrow: 0,
          borderRadius: "4px"
        }}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Box sx={{display: "flex", alignItems: "center", marginRight: "8px"}}>
              <WeightIcon/>
              <Typography sx={{
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "27.3px",
                color: "#4F535C",
                padding: "10px"
              }}>מעקב שקילות</Typography>
            </Box>
            <Box sx={{marginLeft: "8px", cursor: "pointer"}}
                 onClick={() => navigate(`/app/clients/${userId}/weight`)}>
              <LeftArrowIcon/>
            </Box>
          </Box>
          <Divider/>
          {!clientWeights.loading &&
          <Box sx={{
            display: "flex",
            flexDirection: {md: "row", xs: "column"},
            marginTop: "14px",
            justifyContent: "space-between"
          }}>
             <Box sx={{marginRight: "20px"}}>
              <Box sx={{display: "flex"}}>
                <Typography sx={{
                  color: "#717784", fontWeight: 400, fontSize: "14px", lineHeight: "23px",
                }}>משקל נוכחי:</Typography>
                <Typography sx={{
                  marginRight: "5px",
                  color: "#1E1E1E",
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "23px"
                }}>{clientWeights.data.weight}</Typography>
              </Box>
               <Box sx={{display: "flex"}}>
              <Typography sx={{
                color: "#717784", fontWeight: 400, fontSize: "14px", lineHeight: "23px",
              }}>משקל יעד:</Typography>
              <Typography sx={{
                marginRight: "5px",
                color: "#1E1E1E",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "23px"
              }}>{clientWeights.data.targetWeight}</Typography>
               </Box>
            </Box>
              <Box sx={{marginLeft: "30px", marginTop: "-20px"}}>
              <Chart options={getState2().options} series={getState2().series} width={600} height={240}
              type="area"/>
              </Box>
          </Box>}
        </Paper>
      </Box>
    </Box>}
    <ClientBlocks clientId={userId}/>
  </>
  );

};

export default ClientPageMainSection;
