import {Box, Divider, Hidden, Paper, Typography} from "@mui/material";
import {ReactComponent as ActiveIcon} from "../assets/images/active.svg";
import {ReactComponent as InactiveIcon} from "../assets/images/inactive.svg";
import {ReactComponent as WarnIcon} from "../assets/images/warn.svg";
import moment from "moment";

const ClientPageHeader = ({client}) => {
  return (
      <Paper sx={{backgroundColor: "#F2F4F7",
        marginTop: {md: "20px", xs: "10px"},
        borderRadius: "4px",
        display: "flex",
        justifyContent: "space-evenly",
        height: {md: "193px", xs: "auto"},
        paddingBottom: {xs: "10px", md: 0}}} elevation={0}>
        <Box sx={{marginTop: "35px" }}>
          <Hidden mdDown>
            <Typography sx={{fontWeight: 600, fontSize: "28px", color: "#000000"}}>{client.name}</Typography>
          </Hidden>

          <Box sx={{display: "flex", marginTop: {md: 0, xs: "35px"}}}>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center"}}>
              <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Typography sx={{marginLeft: "10px", textAlign: "right", fontWeight: 400, fontSize: "14px", color: "#6C6C6C"}}>{client.enabled ? "פעיל" : "חסום"}</Typography>
                {client.enabled ? <ActiveIcon/> : <InactiveIcon/>}
              </Box>
              <Typography sx={{fontWeight: 400, fontSize: "14px", color: "#6C6C6C"}}>גיל: {moment().year() - moment(moment.unix(client.dateOfBirth)).year()}</Typography>
              <Typography sx={{fontWeight: 400, fontSize: "14px", color: "#6C6C6C"}}>מגזר:       {client.gender === "FEMALE" && "נקבה"}
                {client.gender === "MALE" && "זכר"}
                {!client.gender && "אין מידע"}</Typography>
            </Box>

            <Box sx={{marginRight: "30px", display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center"}}>
              <Typography sx={{fontWeight: 400, fontSize: "14px", color: "#6C6C6C"}}>מייל: {client.email}</Typography>
              <Typography sx={{fontWeight: 400, fontSize: "14px", color: "#6C6C6C"}}>טלפון: {client.phoneNumber}</Typography>
              <Typography sx={{fontWeight: 400, fontSize: "14px", color: "#6C6C6C"}}>תאריך יצירה: {moment(moment.unix(client.createdDate)).format("DD/MM/YY")}</Typography>
            </Box>
          </Box>
        </Box>
        <Hidden mdDown>
          <Box sx={{marginTop: "35px", }}>
            <Paper elevation={0} sx={{width: "363px", backgroundColor: "#FFFFFF", borderRadius: "6px", height: "145px"}}>
              <Typography sx={{fontWeight: 500,
                fontSize: "14px",
                lineHeight: "14px",
                color: "#6A6A65",
                padding: "10px"}}>הודעות:</Typography>
              <Divider/>

                {client.alerts.slice(0, 3).map(alert => {
                  return <Box sx={{margin: "14px", display: "flex", alignItems: "center"}}>
                    <WarnIcon/>
                    <Typography sx={{marginRight: "10px", fontWeight: 400, fontSize: "12px", color: "#585858"}}>{alert}</Typography>
                  </Box>
                })}
            </Paper>
          </Box>
        </Hidden>
      </Paper>
  );
};
export default ClientPageHeader;
