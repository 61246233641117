import {Box, Chip, Typography} from "@mui/material";

const WeekChip = ({number, isDone}) => {
  return (
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "14px"}}>
        <Typography sx={{
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "18px",
          marginBottom: "5px",
        }}> שבוע {number} </Typography>
        <Chip sx={{width: "48px", height: "11px"}} color={isDone ? "primary" : "secondary"}  />
      </Box>
  );
};

export default WeekChip;
