import Dialog from "../shared/Dialog";
import {useEffect, useState} from "react";
import {getTrainers} from "../../services/TrainerService";
import {Autocomplete, Box, Button, TextField} from "@mui/material";
import {RTL} from "../../cache/CacheRtl";
import {getErrorMessage} from "../../enums/ErrorMessages";
import {useMessageContext} from "../../contexts/MessageContext";

const ChangeTrainerDialog = ({trainer, isOpen, close, handleSubmit}) => {

  const [currentTrainer, setCurrentTrainer] = useState({...trainer});

  const [trainers, setTrainers] = useState([])

  const {notificationHandler} = useMessageContext();

  useEffect(() => {
    getTrainers(trainer.companyId)
        .then(res => setTrainers(res.data))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));

    setCurrentTrainer({...trainer});

  }, [isOpen])

  return (
      <Dialog isOpen={isOpen} title={"מאמן"} close={close}>
        <Box sx={{display: "flex", alignItems: "top", justifyContent: "center", padding: "40px"}}>
          <Box>
            <RTL>
              {trainers && <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={trainer}
                  disableClearable
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                      setCurrentTrainer(value)
                  }}
                  options={trainers}
                  sx={{width: 200, mr: "10px", ml: "10px", "& .MuiInputBase-root" : {
                      height: "46px"
                    }}}
                  renderInput={(params) => <TextField
                      sx={{height: "42px"}}
                      {...params} label="מאמן" />}
              />}
            </RTL>
          </Box>
        </Box>
        <Box sx={{paddingBottom: "40px", textAlign: "center"}}>
          <Button
              onClick={close}
              sx={{marginRight: "24px", backgroundColor: "#F1F3F5", color: "black"}}
              variant={"contained"}>
            בטל
          </Button>

          <Button
              onClick={() => handleSubmit(currentTrainer.id)}
              disabled={!currentTrainer}
              sx={{marginRight: "24px"}}
              variant={"contained"}>
            שמור
          </Button>
        </Box>
      </Dialog>
  )
};

export default ChangeTrainerDialog;
