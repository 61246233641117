import {Box, Button} from "@mui/material";
import Stats from "./Stats";
import {ReactComponent as PlusIcon} from "../assets/images/plus.svg";
import PageHeader from "./shared/PageHeader";
import ClientFillterBar from "./client/ClientFillterBar";

const ClientsSearchBar = ({
  selectedClients,
  handleEnableClients,
  handleDisableClients,
  handleSendMessage,
  openNewClient,
  updateKeyword,
  trainer,
  updateTrainer,
  gender,
  updateGender,
  updateState,
  state}) => {
  return (
      <>
      <PageHeader title={"לקוחות"}>
        <Box sx={{marginTop: {xs: "0", md: "28px"}, display: "flex", justifyContent: {md: "space-between", xs: "start"}, alignItems: "center"}}>
          <Stats/>
          <Box sx={{display: {xs : "none", md: "inherit"}}}>
            <Button variant="outlined"
                    sx={{backgroundColor: "#F2F4F7", height: "44px", width: "166px", "& .MuiButton-startIcon" : {
                        marginRight: "-4px",
                        marginTop: "2px",
                        marginLeft: "8px",
                      }}} startIcon={<PlusIcon/>}
                    onClick={openNewClient}>
              הוסף לקוח חדש
            </Button>
          </Box>
        </Box>
      </PageHeader>
        <Box sx={{marginLeft: "20px", marginRight: {md: "35px", xs: "20px"}}}>
          <ClientFillterBar
              handleEnableClients={handleEnableClients}
              handleDisableClients={handleDisableClients}
              handleSendMessage={handleSendMessage}
              selectedClients={selectedClients}
              updateKeyword={updateKeyword}
              trainer={trainer}
              updateTrainer={updateTrainer}
              gender={gender}
              updateState={updateState}
              state={state}
              updateGender={updateGender}
          />
      </Box>
</>
  );
}

export default ClientsSearchBar;
