import {Box, Divider, Paper, Typography} from "@mui/material";
import {ReactComponent as PersonIcon} from "../../assets/images/person.svg";
import {ReactComponent as EditIcon} from "../../assets/images/editProfile.svg";
import {useEffect, useState} from "react";
import {getTrainer} from "../../services/TrainerService";
import ChangeTrainerDialog from "./ChangeTrainerDialog";
import {updateTrainer} from "../../services/TraineeService";
import {getErrorMessage} from "../../enums/ErrorMessages";
import {useMessageContext} from "../../contexts/MessageContext";


const ClientTrainerSection = ({client, refetch}) => {
  const [trainer, setTrainer] = useState();

  const [editOpen, setEditOpen] = useState(false);

  const {notificationHandler} = useMessageContext();

  useEffect(() => {
    getTrainer(client.trainerId).then(r => {
      setTrainer(r.data);
    }).catch(e => {
      notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
    })
  }, [client])

  const handleSubmit = (trainerId) => {
    updateTrainer(client.id, trainerId)
        .then(refetch)
        .then(() => setEditOpen(false))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
  }

  return (
      <>
      {trainer &&
      <Paper sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7",
        flexGrow: 0,
        borderRadius: "4px"}}>
        <ChangeTrainerDialog handleSubmit={handleSubmit} trainer={trainer} close={() => setEditOpen(false)} isOpen={editOpen} />
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Box sx={{display: "flex", alignItems: "center", marginRight: "8px"}}>
            <PersonIcon/>
            <Typography sx={{fontWeight: 600,
              fontSize: "20px",
              lineHeight: "27.3px",
              color: "#4F535C",
              padding: "10px"}}>מאמן</Typography>
          </Box>
          <Box onClick={() => setEditOpen(true)} sx={{marginLeft: "8px", cursor: "pointer"}}>
            <EditIcon/>
          </Box>
        </Box>
        <Divider/>
        <Box sx={{marginTop: "44px", paddingBottom: "45px", marginRight: "21px",marginLeft: "28px",display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
          <Box sx={{flex: 1}}>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>שם מאמן:</Typography>
              <Typography sx={{
                marginRight: "14px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{trainer.name}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>מגדר:</Typography>
              <Typography sx={{
                marginRight: "40px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{trainer.gender === "MALE" ?  "זכר" : "נקבה"}</Typography>
            </Box>
          </Box>
          <Box sx={{flex: 1}}>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>מייל:</Typography>
              <Typography sx={{
                marginRight: "26px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{trainer.email}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>טלפון:</Typography>
              <Typography sx={{
                marginRight: "14px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{trainer.phoneNumber}</Typography>
            </Box>
          </Box>
          <Box sx={{flex: 2}}>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>חברה:</Typography>
              <Typography sx={{
                marginRight: "22px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{trainer.companyName}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>מיקום:</Typography>
              <Typography sx={{
                marginRight: "14px",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{trainer.address}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>}
      </>
  );
};

export default ClientTrainerSection;
