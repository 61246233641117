import httpService from "./HttpService";

export async function getUser(token) {
  return httpService.get('user/me',null, token);
}

export async function getUserLoggedIn() {
  const token = localStorage.getItem("auth");
  return httpService.get('user/me',null, token);
}

export async function updateUser(user) {
  const token = localStorage.getItem("auth");
  return httpService.post('user',user, token);
}

export async function getUsersWithoutRoles(role, companyId) {
  const token = localStorage.getItem("auth");

  let url = 'admin/user?withoutRole=' + role;

  if (companyId) {
    url += '&companyId=' + companyId;
  }

  return httpService.get(url, null, token);
}
