import httpService from "./HttpService";

export const getStatistics = async (companyId) => {
  const token = localStorage.getItem("auth");

  let url = `admin/statistics`;

  if (companyId) {
    url += `?companyId=${companyId}`
  }

  return await httpService.get(
      url,
      null,
      token);
}
