import {Box, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {useState} from "react";

const SideNavItem = ({text, active, activeIcon, icon, hidden, isMiniNav}) => {
  const [showActive, setShowActive] = useState(false);

  return (
      <ListItem
          onMouseEnter={() => setShowActive(true)}
          onMouseLeave={() => setShowActive(false)}
          sx={{display: hidden ? "none" : "flex",
        justifyContent: "center",
        cursor: "pointer",
        mr: "5px",
        marginTop: "12px",
        width: isMiniNav ? "40px" : "100%",
        height: "40px",
        borderRadius: "6px",
        color: active ? "white" : "",
        background: active ? "#57837E" : '',
        "&.MuiListItem-root:hover": {
          background: active ? "" : '#57837E',
          color: active ? '' : "white"
          , "& .MuiSvgIcon-fontSizeMedium" : {
            color: "white"
          }
        }
      }}>
        <ListItemIcon  sx={{minWidth: 0, paddingLeft: isMiniNav ? 0 : 3, "& .MuiSvgIcon-fontSizeMedium" : {
            color: active ? "white" : ''
          }}}>
          {active || showActive ? activeIcon : icon}
        </ListItemIcon>
        <ListItemText style={{display: isMiniNav ? 'none' : 'flex', justifyContent:'flex-start', fontWeight: 700, fontSize: "14px"}} primary={text} />
      </ListItem>
  );
}

export default SideNavItem;
