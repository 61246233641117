import {Typography} from "@mui/material";
import TemplateWorkoutBlock from "./TemplateWorkoutBlock";

const TemplateBlockSection = ({editMode, setTemplate, template, numberOfWeeks, numberOfBlocks}) => {

  return (
      <>
        <Typography sx={{
          fontSize: "14px",
          marginTop: "40px",
          marginBottom: "28px",
          fontWeight: 600,
          lineHeight: "24px",
          color: "#1E1E1E"
        }}>שבועות המשך</Typography>

        {template.blocks?.sort((a, b) => a.index > b.index ? 1 : -1).at(0)?.weeks?.sort((a, b) => a.index > b.index ? 1 : -1).at(0).workouts?.sort((a, b) => a.index > b.index ? 1 : -1).map((workout, workoutIdx) => {
          return <TemplateWorkoutBlock setTemplate={setTemplate} template={template} perpWorkout={workout} numberOfBlocks={numberOfBlocks} numberOfWeeks={numberOfWeeks} workoutIdx={workoutIdx+1} editMode={editMode}/>
        })}
      </>
  );
};

export default TemplateBlockSection;
