export const TempoTypes = [
    {
        value: "T1_0_1_0",
        name: "1.0.1.0"
    },
    {
        value: "T1_0_0_2",
        name: "1.0.0.2"
    },
    {
        value: "T1_0_1_3",
        name: "1.0.1.3"
    },
    {
        value: "T1_1_1_1",
        name: "1.1.1.1"
    },
    {
        value: "T1_2_1_1",
        name: "1.2.1.1"
    },
    {
        value: "T2_0_1_0",
        name: "2.0.1.0"
    },
    {
        value: "T2_0_1_2",
        name: "2.0.1.2"
    },
    {
        value: "T2_0_1_3",
        name: "2.0.1.3"
    },
    {
        value: "T2_1_1_2",
        name: "2.1.1.2"
    },
    {
        value: "T2_1_1_3",
        name: "2.1.1.3"
    },
]
