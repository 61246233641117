import {Box, Button, Paper, Typography} from "@mui/material";
import {ReactComponent as WorkoutIcon} from '../assets/images/workout.svg';
import {ReactComponent as PlusIcon} from '../assets/images/plus.svg';
import Block from "./Block";
import {useEffect, useState} from "react";
import {addNewBlock, getCurrentPlan} from "../services/PlanSerice";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";
import Loader from "./Loader";

const ClientBlocks = ({clientId}) => {
  const [program, setProgram] = useState([]);

  const {notificationHandler} = useMessageContext();
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCurrentPlan(clientId).then(res => {
      setProgram(res.data);
    }).catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
  }, [refetch])

  const handleNewBlock = () => {
    setLoading(true)
    addNewBlock(program.id)
        .then(() => setRefetch(prv => !prv))
        .then(() => setLoading(false))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
        .catch(() => setLoading(false))
  }

  return (
      <>
        <Loader loading={loading}></Loader>
      <Paper sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7",
        borderRadius: "0",
        height: "60px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "20px"}}>
        <Box sx={{marginRight: "21px", display: "flex", alignItems: "center"}}>
          <WorkoutIcon/>

          <Typography sx={{color: "primary.light", marginRight: "16px", fontWeight: 600, fontSize: "20px"}}>תוכניות אימון</Typography>
        </Box>
        <Box sx={{marginLeft: "20px"}}>
          <Button variant={"contained"} onClick={handleNewBlock}>הוספת בלוק נוסף</Button>
        </Box>
      </Paper>
        {program && program.blocks?.sort((a, b) => b.index > a.index ? 1 : -1)
          .map((block, index) => {
          return <Block programId={program.id} key={index} block={block} index={block.index+1}/>
        })}
      </>
  );
};

export default ClientBlocks;
