import {Box, Hidden} from "@mui/material";
import {ReactComponent as LogoIcon} from '../assets/images/logo.svg';
import {useAuth} from "../contexts/AuthContext";
import {userLogin} from "../services/AuthService";
import {getUser} from "../services/UserService";
import {useState} from "react";
import {Navigate} from "react-router-dom";
import ForgottenPassword from "../componenets/ForgottenPassword";
import LoginForm from "../componenets/LoginForm";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";

const Login = () => {
    const [forgottenPassword, setForgottenPassword] = useState(false);

    const {login, currentUser} = useAuth();

    const {notificationHandler} = useMessageContext();

    const [failedLogin, setFailedLogin] = useState(false);

    const openForgottenPassword = () => {
      setForgottenPassword(true);
    }

    const closeForgottenPassword = () => {
      setForgottenPassword(false);
    }

  const handleRestLogin = (event) => {
    event.preventDefault();
    const { email, password } = document.forms[0];
    userLogin(email.value, password.value)
        .then(res => {
          const token = res.data.body;
          getUser(token)
              .then(user => {
                login(user.data.body, token);
              }).catch(() => setFailedLogin(true))
        }).catch(() => {
      setFailedLogin(true);
    })
  }

  return  (
      <>
        <ForgottenPassword closeForgottenPassword={closeForgottenPassword} forgottenPassword={forgottenPassword}/>
        {currentUser != null && <Navigate to="/" replace />}
      <Box sx={{display: "flex", height: "100dvh"}}>
        <Hidden mdDown>
          <Box component="img"
               sx={{height: "100vh"}}
               src="login-image.webp">
          </Box>
        </Hidden>
        <Box sx={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
          <Box>
            <LoginForm openForgottenPassword={openForgottenPassword}
                       handleRestLogin={handleRestLogin}
                       failedLogin={failedLogin} />
          </Box>
          <Box sx={{textAlign: "center", marginBottom: "20px", marginTop: {md: 0, xs: "20px"}}}>
            <LogoIcon/>
          </Box>
        </Box>

      </Box>
        </>
  );
};

export default Login;
