import Dialog from "../shared/Dialog";
import {Box, Button, FormControl, MenuItem, Select, Typography} from "@mui/material";
import {RTL} from "../../cache/CacheRtl";
import {useEffect, useState} from "react";
import {typeSets} from "../../enums/TypeSets";

const IntervalPage = ({editMode, close, isOpen, setTemplate, exerciseName, blockIndex, weekIndex, workoutIdx, exerciseIdx, template}) => {

  const newWeekIdx = blockIndex === 0 ? weekIndex+1 : weekIndex;

  const [intervals, setIntervals] = useState([])

  useEffect(() => {
    setIntervals(template.blocks
        .at(blockIndex).weeks
        .at(newWeekIdx).workouts
        .at(workoutIdx-1).exercises
        .at(exerciseIdx-1)?.intervals)
  }, [close])


  const updateNumberOfSets = (newVal) => {
    setIntervals(prv => {
      let newIntervals = prv ? [...prv] : [];

      while (newVal > newIntervals.length) {
        newIntervals.push({
          index: newIntervals.length,
          typeSet: "REGULAR_SET",
        })
      }

      while (newVal < newIntervals.length) {
        newIntervals.pop();
      }
      return newIntervals;
    });
  }

  const updateIntervalTypeSet = (index, value) => {
    setIntervals(prv => {
      let newIntervals = [...prv];

      newIntervals.at(index).typeSet = value;

      return newIntervals;
    })
  }

  const saveSets = () => {
    setTemplate(prv => {
      let newTemplate = {...prv};

      console.log(newTemplate);

      newTemplate.blocks.at(blockIndex).weeks
          .at(newWeekIdx).workouts
          .at(workoutIdx-1).exercises
          .at(exerciseIdx-1).intervals = intervals ? [...intervals] : [];


      return newTemplate;
    })

    close()
  }

  return (
      <Dialog close={close} isOpen={isOpen} title={exerciseName}>
          <Box sx={{padding: "40px", }}>
        <Box sx={{textAlign: "center"}}>
        <Typography sx={{marginTop: "10px"}}>  שבוע  {newWeekIdx}  בלוק {blockIndex+1}</Typography>
      <Typography>בחר מספר סטים</Typography>
      <RTL>
        <FormControl>
          <Select
              disabled={!editMode}
              sx={{minWidth: "64px",
                marginTop: "8px"}}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(e) => updateNumberOfSets(e.target.value)}
              value={intervals?.length}
          >
            {Array.from(Array(10).keys()).map((_, i) => {
              return <MenuItem key={i} value={i}>{i}</MenuItem>
            })}
          </Select>
        </FormControl>
      </RTL>
        </Box>
      {intervals?.map((interval, i) => {
        return (
            <Box sx={{display: "flex", justifyContent: "center"}}>
          <Box key={i} sx ={{display: "flex", justifyContent: "center", alignItems: "center",
            marginTop: "40px",
          }}>
            <Typography sx={{color: "#69B8AF",
              paddingBottom: "8px",
              width: "100px",
              fontSize: "20px",
              marginTop: "24px",
              fontWeight: 600,
              lineHeight: "27px",
            }}> סט {i+1}</Typography>
            <Box>
              <Typography>*סוג סט</Typography>
            <RTL>
              <FormControl fullWidth>
                <Select
                    sx={{height: "48px", width: "200px"}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disabled={!editMode}
                    value={interval.typeSet}
                    onChange={(e) => updateIntervalTypeSet(i, e.target.value)}
                    displayEmpty>
                  {typeSets.map((value, i) => {
                    return <MenuItem key={i} value={value}>{value}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </RTL>
            </Box>
          </Box>
            </Box>
        )
      })}

        <Box sx={{textAlign: "center", marginTop: "40px"}}>
          <Button
              onClick={close}
              sx={{backgroundColor: "#F1F3F5", color: "black"}}
              variant={"contained"}>
            בטל
          </Button>
          <Button
              disabled={!editMode}
              onClick={saveSets}
              sx={{marginRight: "24px"}}
              variant={"contained"}>
            שמור
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default IntervalPage;
