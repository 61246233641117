import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";

import {ReactComponent as CancelIcon} from "../assets/images/cancel.svg";

const DeleteDialog = ({handleClickOpen, handleClose, open}) => {

  return (
      <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{textAlign: "center"}}>
            <CancelIcon/>
            <DialogTitle id="alert-dialog-title">
              {"האם אתה בטוח?"}
            </DialogTitle>
            <DialogContentText id="alert-dialog-description">
              אתה בטוח רוצה למחוק את הלקוח? פעולה זו לא נינת לבטל
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Button variant={"outlined"} onClick={handleClose}>בטל</Button>
            <Button sx={{mr: "10px", backgroundColor: "#F16063"}} variant={"contained"} onClick={handleClickOpen} autoFocus>
              מחק
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );

};

export default DeleteDialog;
