import {Box, Divider, Paper, Typography} from "@mui/material";

const TrainerStatusSection = ({trainer}) => {

  return (
      <Paper sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7",
        flexGrow: 0,
        borderRadius: "4px"}}>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Box sx={{display: "flex", alignItems: "center", marginRight: "8px"}}>
            <Typography sx={{fontWeight: 600,
              fontSize: "20px",
              lineHeight: "27.3px",
              color: "#4F535C",
              padding: "10px"}}>סטטוס מתאמנים</Typography>
          </Box>
        </Box>
        <Divider/>
        <Box sx={{marginTop: "44px", paddingBottom: "45px", marginRight: "21px",marginLeft: "28px",display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#717784"
            }}>מספר לקוחות:</Typography>
            <Typography sx={{
              marginRight: "14px",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#1E1E1E"
            }}>{trainer.numberOfClients}</Typography>
          </Box>
        </Box>
      </Paper>
  );
};

export default TrainerStatusSection;
