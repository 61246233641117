export const resistanceTypes = [
  {
    value: "BARBBELL",
    name: "מוט"
  },
  {
    value: "DUMBBELL",
    name: "משקולת יד"
  },
  {
    value: "MACHINE_PLATE_LOADED",
    name: "מכונת פלטות"
  },
  {
    value: "MACHINE_SELECTORIZED",
    name: "מכונת פינים"
  },
  {
    value: "CABLE_CROSS",
    name: "כבל קרוס"
  },
  {
    value: "BODY_WEIGHT",
    name: "משקל גוף"
  },
  {
    value: "BAND",
    name: "גומיה"
  },
  {
    value: "SMITH_MACHINE",
    name: "סמית' משין"
  },
  {
    value: "TREADMILL",
    name: "הליכון"
  },
  {
    value: "RUNNING",
    name: "ריצה"
  },
  {
    value: "BICYCLE_MACHINE",
    name: "אופניים"
  },
  {
    value: "ELLIPTICAL_MACHINE",
    name: "אליפטי"
  },
  {
    value: "STEP_MACHINE",
    name: "מדרגות"
  },
  {
    value: "WALKING",
    name: "הליכה"
  },
]
