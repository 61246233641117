import {Box, Paper, Typography} from "@mui/material";
import {ReactComponent as LeftArrowIcon} from '../../assets/images/left-arrow.svg';
import {NavLink} from "react-router-dom";

const StatBox = ({link, icon, name, data}) => {

  return (
      <Paper sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7", minHeight: "143px"}}>
        <Box sx={{margin: "24px"}}>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        {icon}
          <NavLink to={link}>
            <LeftArrowIcon/>
          </NavLink>
        </Box>
          <Box sx={{marginTop: "20px"}}>
          <Typography sx={{
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "14px",
            color: "#717784"
          }}>{name}</Typography>
            <Typography sx={{
              fontSize: "28px",
              fontWeight: 600,
              lineHeight: "38px",
              color: "#717784"
            }}>{data}</Typography>
          </Box>
        </Box>
      </Paper>
  );
};

export default StatBox;
