import {
  Box, Button, IconButton, InputAdornment, MenuItem, TextField, Typography
} from "@mui/material";
import SelectCom from "./SelectCom";
import {DateField} from "@mui/x-date-pickers";
import {ReactComponent as GiftIcon} from "../assets/images/gift.svg";
import {ReactComponent as HeightIcon} from "../assets/images/height.svg";
import {ReactComponent as WeightIcon} from "../assets/images/weight.svg";

const NewClientStep1 = ({closeNewClient, availableUsers, selectUser, updateField, nextStep, trainee}) => {

  return (
      <Box>
      <Box
      sx={{display: "flex", alignItems: "top", justifyContent: "space-between", paddingTop: "40px",
      paddingLeft: "100px", paddingRight: "100px"}}>
    <Box sx={{width: "320px"}}>
      <Box sx={{mb: "10px"}}>
      <SelectCom
          id={"user"}
          width={"320px"}
          mainItem={"משתמש חדש"}
          label={"משתמש קיים"}

          contrast={"black"}
          value={trainee.existingUser}
          onChange={res => selectUser(res)}
          defaultValue={"null"}>
        {availableUsers.map((res, i) => {
          return <MenuItem key={i} value={res} sx={{display: "flex", alignItems: "center"}}>
            <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
          </MenuItem>
        })}
      </SelectCom>
      </Box>
      <Typography>
        *שם מלא:
      </Typography>
      <TextField
          sx={{marginTop: "8px"}}
          fullWidth
          value={trainee.name}
          onChange={(e) => updateField(e.target.value, "name")}
          id="name"
          variant="outlined"
      />
      <Typography sx={{marginTop: "24px"}}>
        מייל:
      </Typography>
      <TextField
          sx={{marginTop: "8px", marginBottom: "20px"}}
          fullWidth
          value={trainee.email}
          onChange={(e) => updateField(e.target.value, "email")}
          id="email"
          variant="outlined"
      />
      <Box sx={{width: "320px", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Box>
        <Typography sx={{marginTop: "24px"}}>
          *תאריך לידה:
        </Typography>
            <DateField
                InputProps={{
                  endAdornment: (<InputAdornment position="end">
                    <IconButton
                        disabled
                        aria-label="height"
                        edge="start"
                    >
                      <GiftIcon/>
                    </IconButton>
                  </InputAdornment>),
                }}

                value={trainee.dateOfBirth}
                onChange={e => updateField(e, "dateOfBirth")} format={"DD/MM/YYYY"}  />
        </Box>
        <Box sx={{marginTop: "45px"}}>
          <SelectCom
              id={"gender"}
              width={"100px"}
              mainItem={"לא נבחר"}
              label={"*מגדר"}
              contrast={"black"}
              marginLeft="33px"
              height={"56px"}
              value={trainee.gender}
              onChange={e => updateField(e, "gender")}
              defaultValue={"null"}>
            <MenuItem value={"MALE"} sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{marginLeft: "8px"}}>זכר</Typography>
            </MenuItem>
            <MenuItem value={"FEMALE"}
                      sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <Typography sx={{marginLeft: "8px"}}>נקבה</Typography>
            </MenuItem>
          </SelectCom>
        </Box>
      </Box>
      <Box sx={{width: "320px", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Box>
          <Typography sx={{marginTop: "24px"}}>
            תאריך סיום:
          </Typography>
          <DateField
              sx={{width: "320px"}}
              value={trainee.expirationDate} onChange={e => updateField(e, "expirationDate")} format={"DD/MM/YYYY"}  />
        </Box>
      </Box>
    </Box>
    <Box sx={{mt: "50px", marginRight: "80px", width: "320px"}}>
      <Typography>
        *טלפון:
      </Typography>
      <TextField
          sx={{marginTop: "8px", marginBottom: "20px"}}
          fullWidth
          value={trainee.phoneNumber}
          onChange={(e) => updateField(e.target.value, "phoneNumber")}
          id="phoneNumber"
          variant="outlined"
      />
      <Typography>
        גובה cm
      </Typography>
      <TextField
          sx={{marginTop: "8px", marginBottom: "20px",
            '& input[type=number]': {
              '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0
            }
          }}
          fullWidth
          value={trainee.height}
          onChange={(e) => updateField(e.target.value, "height")}
          id="height"
          type={"number"}
          variant="outlined"
          InputProps={{
             endAdornment: (<InputAdornment position="end">
               <IconButton
                   disabled
                   aria-label="height"
                   edge="start"
               >
                 <HeightIcon/>
               </IconButton>
             </InputAdornment>),
           }}
      />
      <Typography sx={{marginTop: "20px"}}>
        משקל kg
      </Typography>
      <TextField
          sx={{marginTop: "8px", marginBottom: "20px",
            '& input[type=number]': {
              '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0
            }
          }}
          fullWidth
          value={trainee.bodyWeight}
          onChange={(e) => updateField(e.target.value, "bodyWeight")}
          id="bodyWeight"
          type={"number"}
          variant="outlined"
          InputProps={{
            endAdornment: (<InputAdornment position="end">
              <IconButton
                  disabled
                  aria-label="height"
                  edge="start"
              >
                <WeightIcon/>
              </IconButton>
            </InputAdornment>),
          }}
      />
    </Box>
  </Box>
  <Box sx={{marginTop: "80px", paddingBottom: "40px", textAlign: "center"}}>
    <Button
        onClick={closeNewClient}
        sx={{marginRight: "24px", backgroundColor: "#F1F3F5", color: "black"}}
        variant={"contained"}>
      בטל
    </Button>
    <Button
        disabled={!trainee.name ||
            !trainee.phoneNumber ||
            !trainee.email ||
            !trainee.dateOfBirth ||
            !trainee.gender ||
            trainee.gender === "null"}
        onClick={nextStep}
        sx={{marginRight: "24px"}}
        variant={"contained"}>
      המשך
    </Button>
  </Box>
      </Box>
  )
}
export default NewClientStep1;
