export const specificMuscles = [
  {
    value: "OUADS_1",
    name: "ארבע ראשי-1",
  },
    {
      value: "OUADS_2",
      name: "ארבע ראשי-2",
    },
      {
        value: "OUADS_3",
        name: "ארבע ראשי-3",
      },
      {
        value: "OUADS_4",
        name: "ארבע ראשי-4",
      },
      {
        value: "OUADS_5",
        name: "ארבע ראשי-5",
      },
      {
        value: "OUADS_6",
        name: "ארבע ראשי-6",
      },
      {
        value: "HAMSTRING_1",
        name: "המסטרינג-1",
      },
      {
        value: "HAMSTRING_2",
        name: "המסטרינג-2",
      },
      {
        value: "HAMSTRING_3",
        name: "המסטרינג-3",
      },
      {
        value: "GLUTES_1",
        name: "ישבן-1",
      },
      {
        value: "GLUTES_2",
        name: "ישבן-2",
      },
      {
        value: "ABDUCTORS",
        name: "מרחיקים",
      },
      {
        value: "ADDUCTORS",
        name: "מקרבים",
      },
      {
        value: "CALVES",
        name: "תאומים",
      },
      {
        value: "MIDDLE_CHEST_1",
        name: "חזה-אמצעי 1",
      },
      {
        value: "MIDDLE_CHEST_2",
        name: "חזה-אמצעי 2",
      },
      {
        value: "MIDDLE_CHEST_3",
        name: "חזה-אמצעי 3",
      },
      {
        value: "UPPER_CHEST_1",
        name: "חזה-עליון 1",
      },
      {
        value: "UPPER_CHEST_2",
        name: "חזה-עליון 2",
      },
      {
        value: "UPPER_CHEST_3",
        name: "חזה-עליון 3",
      },
      {
        value: "BACK_PULLS_1",
        name: "גב-משיכות 1",
      },
      {
        value: "BACK_PULLS_2",
        name: "גב-משיכות 2",
      },
      {
        value: "BACK_PULLS_3",
        name: "גב-משיכות 3",
      },
      {
        value: "BACK_ROWES_1",
        name: "גב-חתירות 1",
      },
      {
        value: "BACK_ROWES_2",
        name: "גב-חתירות 2",
      },
    {
      value: "SHOULDERS_LATERAL_DELTS_1",
      name: "כתפיים-כתף אמצעית 1",
    },
      {
        value: "SHOULDERS_LATERAL_DELTS_2",
        name: "כתפיים-כתף אמצעית 2",
      },
      {
        value: "SHOULDERS_FRONT_DELTS_1",
        name: "כתפיים-כתף קדמית 1",
      },
  {
    value: "SHOULDERS_REAR_DELTS_1",
    name: "כתפיים-כתף אחורית 1",
  },
  {
    value: "SHOULDERS_TRAPS_1",
    name: "כתפיים-טרפזים 1",
  },
  {
    value: "ARMS_BICEPS_1",
    name: "ידיים-יד קדמית 1",
  },
  {
    value: "ARMS_BICEPS_2",
    name: "ידיים-יד קדמית 2",
  },
  {
    value: "ARMS_BICEPS_3",
    name: "ידיים-יד קדמית 3",
  },
  {
    value: "ARMS_BICEPS_4",
    name: "ידיים-יד קדמית 4",
  },
  {
    value: "ARMS_TRICEPS_1",
    name: "ידיים-יד אחורית 1",
  },
  {
    value: "ARMS_TRICEPS_2",
    name: "ידיים-יד אחורית 2",
  },
  {
    value: "ARMS_TRICEPS_3",
    name: "ידיים-יד אחורית 3",
  },
  {
    value: "ABS_1",
    name: "בטן-1",
  },
      {
        value: "ABS_2",
        name: "בטן-2",
      },
  {
    value: "ABS_OBLIQUES_1",
    name: "בטן -אלכסונים 1",
  },
  {
    value: "CARDIO",
    name: "קרדיו",
  },
]
