import {Box, Typography} from "@mui/material";
import Image from "../../assets/images/header-image.svg";

const PageHeader = ({title, children}) => {

  return (
      <Box sx={{position: "sticky", zIndex: "1200",
        paddingRight: {md: "28px", xs: "14px"},
        paddingLeft: "0",
        backgroundColor: "white",
        top: {md: 0, xs: 70},
        paddingBottom: "12px",
        paddingTop: "0"}}>
        <Box
            sx={{
              display: {md: "block", xs: "none"},
              padding: "10px",
              height: "177px",
              background: "linear-gradient(270deg, #FFFFFF 0%, rgba(249, 249, 249, 0.729167) 40.93%, rgba(119, 119, 119, 0) 95.84%),"
                  + `url(${Image})`,
            }}>


          <Typography
              sx={{
                display: {xs: 'none', md: "inherit"},
                fontWeight: "700",
                fontSize: "36px",
                lineHeight: "50px",
                textDecoration: "underline",
                textUnderlineOffset: "10px",
                textDecorationColor: "#57837E",
                textDecorationThickness: '3px'}}>
            {title}
          </Typography>
          {children}
        </Box>
      </Box>
  );
};

export default PageHeader;
