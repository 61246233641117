import PageHeader from "../componenets/shared/PageHeader";
import {
  Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";
import {ReactComponent as PlusIcon} from "../assets/images/plus.svg";
import {useEffect, useState} from "react";
import {getTrainers} from "../services/TrainerService";
import MiniTrainers from "../componenets/trainers/MiniTrainers";
import NewTrainerDialog from "../componenets/NewTrainerDialog";
import {useCompany} from "../contexts/CompanyContext";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";

const Trainers = () => {
  const [trainers, setTrainers] = useState([]);
  const {company} = useCompany();
  const [newTrainer, setNewTrainer] = useState(false);

  const {notificationHandler} = useMessageContext();

  useEffect(() => {
    getTrainers(company?.id)
        .then(r => {
          setTrainers(r.data);
        }).catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
    })
  },[company]);

  return (
      <>
        <NewTrainerDialog open={newTrainer} close={() => setNewTrainer(false)}/>
        <PageHeader title={"מאמנים"}>
          <Box sx={{marginTop: {xs: "0", md: "28px"}, display: "flex", justifyContent: {md: "flex-end", xs: "start"}, alignItems: "center"}}>
            <Box sx={{display: {xs : "none", md: "inherit"}}}>
              <Button
                  onClick={() => setNewTrainer(true)}
                  variant="outlined"
                      sx={{backgroundColor: "#F2F4F7", height: "44px", width: "166px", "& .MuiButton-startIcon" : {
                          marginRight: "-4px",
                          marginTop: "2px",
                          marginLeft: "8px",
                        }}} startIcon={<PlusIcon/>}>
                הוסף מאמן
              </Button>
            </Box>
          </Box>
        </ PageHeader>
        <Box sx={{marginLeft: "40px", marginRight: "40px"}}>
          <TableContainer>
            <Table sx={{"&.MuiTable-root" : {
                borderCollapse: "separate",
                borderSpacing: "0 15px",
              }}}>
              <TableHead>
                <TableRow>
                  <TableCell align="right">
                    שם מלא
                  </TableCell>
                  <TableCell align="center">
                    מגדר
                  </TableCell>
                  <TableCell align="center">
                    תאריך יצירה
                  </TableCell>
                  <TableCell align="left">
                    פעיל
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <MiniTrainers trainers={trainers}/>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
  );
};

export default Trainers;
