export const typeSets = [
  "REGULAR_SET",
  "MAX_REPS",
  "SUPER_SET",
  "DROP_SET",
  "CLUSTER_SET",
  "REST_PAUSE",
  "MYO_REPS",
  "RM_SET"
]
