import httpService from "./HttpService";

export async function getAlerts(companyId) {
  const token = localStorage.getItem("auth");
  let url = 'admin/alert';

  if (companyId) {
    url += `?companyId=${companyId}`
  }
  return httpService.get(url, null, token);
}

export async function deleteAlert(id) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`admin/alert/${id}`, null, token);
}
