import PageHeader from "../componenets/shared/PageHeader";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useEffect, useState} from "react";
import AlertCard from "../componenets/alerts/AlertCard";
import {getAlerts} from "../services/AlertService";
import {useCompany} from "../contexts/CompanyContext";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";
import {useAuth} from "../contexts/AuthContext";

const AlertsPage = () => {

  const [alerts, setAlerts] = useState([]);

  const {company} = useCompany();

  const {isSuperAdmin} = useAuth();

  const {notificationHandler} = useMessageContext();

  useEffect(() => {
    if (isSuperAdmin()) {
      getAlerts(company?.id)
          .then(res => setAlerts(res.data))
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
    } else if (company !== null) {
      getAlerts(company.id)
          .then(res => setAlerts(res.data))
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
    }
  }, [company]);

  return (
      <>
        <PageHeader title={"התראות"}/>
        <Box
            sx={{ margin: { md: "15px", xs: "15px" }}}
        >
          <TableContainer>
            <Table sx={{"&.MuiTable-root" : {
                borderCollapse: "separate",
                borderSpacing: "0 15px",
              }}}>
              <TableHead>
                <TableRow>
                  <TableCell align="right">שם מתאמן</TableCell>
                  <TableCell align="center">ההתראה</TableCell>
                  <TableCell align="center">תאריך יצירה</TableCell>
                  <TableCell align="left">מחיקה</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alerts.map((alert, i) => (
                    <AlertCard
                        alert={alert}
                        key={alert.id}
                    />))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
  );
};

export default AlertsPage;
