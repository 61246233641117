import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TemplateBlockExercise from "./TemplateBlockExercise";

const TemplateWorkoutBlock = ({workoutIdx, numberOfWeeks, template, setTemplate, perpWorkout, numberOfBlocks, editMode}) => {
  return <Accordion variant={"elevation"} sx={{backgroundColor: "#FCFCFD", minHeight: "64px"}}>
    <AccordionSummary
        sx={{"&.MuiAccordionSummary-root" : {
            height: "64px"
          }}}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
    >
      <Typography> אימון {workoutIdx}</Typography>
    </AccordionSummary>

    <AccordionDetails>
      {perpWorkout.exercises.filter(x => {
        return x.durationTime === null || x.durationTime === undefined
      }).map((exercise, exerciseIdx) => {
        return <TemplateBlockExercise editMode={editMode} setTemplate={setTemplate} workoutIdx={workoutIdx} template={template} exercise={exercise} numberOfBlocks={numberOfBlocks} numberOfWeeks={numberOfWeeks} exerciseIdx={exerciseIdx + 1}/>
      })}
    </AccordionDetails>
  </Accordion>
};

export default TemplateWorkoutBlock;
