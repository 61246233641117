import {
  Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography
} from "@mui/material";
import PageHeader from "../componenets/shared/PageHeader";
import StatBox from "../componenets/dashboard/StatBox";
import {ReactComponent as MissingIcon} from '../assets/images/missing.svg';
import {ReactComponent as QuestionMarkIcon} from '../assets/images/QuestionMark.svg';
import {ReactComponent as ScheduleIcon} from '../assets/images/schedule.svg';
import {ReactComponent as MissingRedIcon} from '../assets/images/missing_red.svg';
import GenderStas from "../componenets/dashboard/GenderStas";
import ClientStats from "../componenets/dashboard/ClientStats";
import {useEffect, useState} from "react";
import {getStatistics} from "../services/DashboatrdService";
import {useCompany} from "../contexts/CompanyContext";
import Loader from "../componenets/Loader";
import CompanyStats from "../componenets/dashboard/CompanyStats";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";
import {useAuth} from "../contexts/AuthContext";

const Dashboard = () => {

  const [stats, setStats] = useState();

  const [loading, setLoading] = useState(false);

  const {company} = useCompany();

  const {isSuperAdmin} = useAuth();

  const {notificationHandler} = useMessageContext();

  const colors = ["#446864", "#69B8AF", "#B9D9D5", "#57837E"];

  useEffect(() => {
    setLoading(true);

    if (isSuperAdmin()) {
      console.log(company);
      getStatistics(company?.id)

          .then(res => {
            setStats(res.data);
          })
          .then(() => setLoading(false))
          .catch(e => {
            notificationHandler.error(getErrorMessage(e.response.data?.errorCode))
            setLoading(false);
          })
    } else if (company !== null) {
      console.log(company);
      getStatistics(company.id)
          .then(res => {
            setStats(res.data);
          })
          .then(() => setLoading(false))
          .catch(e => {
            notificationHandler.error(getErrorMessage(e.response.data?.errorCode))
            setLoading(false);
          })
    }
  },[company])

  const data = company ? stats?.clientsPerTrainer : stats?.clientsPerCompany;

  return (
      <>
        {loading && <Loader loading={loading}/> }
        {stats &&
            <>
            <PageHeader title={"דשבורד"}/>
        <Grid container spacing={2}>
          <Grid sx={{marginTop: "12px", marginLeft: "42px", marginRight: "42px"}} item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <StatBox link={"/app/clients?state=ACTIVE"} name={"לקוחות פעילים"} icon={<MissingIcon/>} data={stats.activeTrainees}/>
              </Grid>
              <Grid item xs={3}>
                <StatBox link={"/app/clients?state=TRAIL"} name={"בתקופת ניסיון"} icon={<QuestionMarkIcon/>} data={stats.inTrail}/>
              </Grid>
              <Grid item xs={3}>
                <StatBox link={"/app/clients?state=CLOSE_TO_END"} name={"עומדים להסתיים"} icon={<ScheduleIcon/>} data={stats.aboutToFinish}/>
              </Grid>
              <Grid item xs={3}>
                <StatBox link={"/app/clients?state=MISSING"} name={" לקוחות נעדרים"} icon={<MissingRedIcon/>} data={stats.missingClients}/>
              </Grid>
              <Grid item xs={7.5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <GenderStas female={stats.females} male={stats.males} total={stats.totalNumberOfTrainees}/>
                  </Grid>
                  <Grid item xs={12}>
                    <ClientStats statsData={stats.usersByDate}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4.5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7",minHeight: "550px"}}>
                      <Typography sx={{mb: "50px",mt: "25px", mr: "25px", fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "18px",
                        color: "#4F535C"}}>חלוקת לקוחות</Typography>
                      {data?.length > 0 && <>
                      <Box sx={{display: "flex", justifyContent: "center"}}>
                      {<CompanyStats company={company} colors={colors} data={data} />}
                      </Box>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align={"right"}>
                              שם מאמן
                            </TableCell>
                            <TableCell align={"left"}>
                              <Typography sx={{ml: "30px",}}>לקוחות</Typography>

                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.map((data, i) => {
                            return <TableRow key={i}>
                              <TableCell align={"right"}>{data.name}</TableCell>
                              <TableCell  align={"left"}>
                                <Box sx={{ml: "20px", display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                                  <Typography >{data.value}</Typography>
                                  <Box sx={{mr: "18px", width: "15px", height: "15px", borderRadius: "20px", backgroundColor: colors.at(i)}}/>
                                </Box>
                              </TableCell>
                            </TableRow>})}
                        </TableBody>
                      </Table>
                          </>}
                        {data?.length === 0 && <>
                          <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Typography sx={{fontSize: "24px",
                              fontWeight: 600,
                              lineHeight: "24px",
                              color: "#4F535C"}}>אין לקוחות</Typography>
                          </Box>
                        </>}
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
            </>}
      </>
  )
};

export default Dashboard;
