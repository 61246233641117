import {
  Box, Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {ReactComponent as EditIcon} from "../../assets/images/editProfile.svg";
import {ReactComponent as DeleteIcon} from "../../assets/images/delete-round.svg";
import moment from "moment";
import {addOwner, deleteOwner, editOwner} from "../../services/CompanyService";
import {useState} from "react";
import AddOwnerDialog from "./AddOwnerDialog";
import {useMessageContext} from "../../contexts/MessageContext";
import {getErrorMessage} from "../../enums/ErrorMessages";

const CompanyManagersSection = ({compantId, companyOwners, setLoadCompany}) => {

  const {notificationHandler} = useMessageContext();

  const handleDeleteOwner = (id) => {
    deleteOwner(id)
        .then(r => {
          setLoadCompany(prv => !prv)
        })
        .then(() => notificationHandler.success("מנהל חברה נמחק בהצלחה"))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
  }

  const [openEditOwner, setOpenEditOwner] = useState({
    open: false,
    manager: null
  });


  const closeEditOwner = () => {
    setOpenEditOwner({
      open: false,
      manager: null
    })
  }

  const handleSaveOwner = (manager) => {
    delete manager["secondPassword"];
    if (manager.id) {
      editOwner(manager)
          .then(r => {
            setLoadCompany(prv => !prv)
          })
          .then(() => notificationHandler.success("מנהל חברה נשמר בהצלחה"))
          .then(closeEditOwner)
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
    } else {
      addOwner(manager)
          .then(r => {
            setLoadCompany(prv => !prv)
          })
          .then(closeEditOwner)
          .then(() => notificationHandler.success("מנהל חברה עודכן בהצלחה"))
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
    }
  }

  const addManger = () => {
    setOpenEditOwner({
      open: true,
      manager: {}
    });
  }

  const updateOwner = (manager) => {
    setOpenEditOwner({
      open: true,
      manager: manager,
    })
  }

  return (
      <Paper variant={"outlined"} sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7",
        flexGrow: 0,
        borderRadius: "4px"}}>
        {openEditOwner.manager && <AddOwnerDialog owner={openEditOwner.manager} open={openEditOwner.open} companyId={compantId} close={closeEditOwner} handleSubmit={handleSaveOwner}/>}
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Box sx={{display: "flex", alignItems: "center", marginRight: "8px"}}>
            <Typography sx={{fontWeight: 600,
              fontSize: "20px",
              lineHeight: "27.3px",
              color: "#4F535C",
              padding: "10px"}}>מנהלים</Typography>
          </Box>
        </Box>
        <Divider/>
        <Box sx={{margin: "20px"}}>
        <TableContainer>
          <Table>
            <TableHead sx={{backgroundColor: "#F9FAFB"}}>
              <TableRow>
                <TableCell align={"right"} sx={{borderBottom: 0,
                  padding: "8px", color: "#717784",
                  backgroundColor: "#FCFCFD"}}>שם מלא:
                </TableCell>
                <TableCell align={"center"} sx={{borderBottom: 0,padding: "8px", color: "#717784",
                  backgroundColor: "#FCFCFD"}}>טלפון:
                </TableCell>
                <TableCell align={"center"} sx={{borderBottom: 0,padding: "8px", color: "#717784",
                  backgroundColor: "#FCFCFD"}}>מייל:
                </TableCell>
                <TableCell align={"center"} sx={{borderBottom: 0,padding: "8px", color: "#717784",
                  backgroundColor: "#FCFCFD"}}>תאריך יצירה:
                </TableCell>
                <TableCell align={"left"} sx={{borderBottom: 0,padding: "8px", color: "#717784",
                  backgroundColor: "#FCFCFD"}}>פעולות
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyOwners.map((manager, i) => {
                return <TableRow key={i}>
                  <TableCell align={"right"} sx={{padding: "8px", backgroundColor: "#F3F4F7"}}>
                    <Typography sx={{
                      fontsize: "16px",
                      fontWeight: 600,
                      lineHeight: "25px",
                      color: "#4F535C"
                    }}>{manager.name}
                    </Typography>
                  </TableCell>
                  <TableCell align={"center"} sx={{padding: "8px", backgroundColor: "#F3F4F7"}}>
                    <Typography sx={{
                      fontsize: "16px",
                      fontWeight: 600,
                      lineHeight: "25px",
                      color: "#4F535C"
                    }}>{manager.phoneNumber}</Typography>
                  </TableCell>
                  <TableCell align={"center"} sx={{padding: "8px", backgroundColor: "#F3F4F7"}}>
                    <Typography sx={{
                      fontsize: "16px",
                      fontWeight: 600,
                      lineHeight: "25px",
                      color: "#4F535C"
                    }}>{manager.email}</Typography>
                  </TableCell>
                  <TableCell align={"center"} sx={{padding: "8px", backgroundColor: "#F3F4F7"}}>
                    <Typography sx={{
                      fontsize: "16px",
                      fontWeight: 600,
                      lineHeight: "25px",
                      color: "#4F535C"
                    }}>{moment(manager.createDate).format("DD/MM/YY")}</Typography>
                  </TableCell >
                  <TableCell align={"left"} sx={{padding: "8px", backgroundColor: "#F3F4F7"}}>
                    <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Box
                        onClick={() => updateOwner(manager)}
                        sx={{marginLeft: "8px", cursor: "pointer"}}>
                      <EditIcon/>
                    </Box>
                    <Box
                        sx={{cursor: "pointer"}}
                        onClick={() => handleDeleteOwner(manager.id)}>
                      <DeleteIcon/>
                    </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
          <Button
              onClick={addManger}
              sx={{border: "1px solid #E7E9EE",
                marginTop: "18px",
                "&:hover" : {
                  backgroundColor: "#FCFCFD", color: "#4F535C"
                },
                boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
                borderRadius: "8px",
                backgroundColor: "#FCFCFD", color: "#4F535C"}}
              variant={"contained"}>
            הוסף מנהל +
          </Button>
        </Box>
      </Paper>
  );
};
export default CompanyManagersSection;
