import {Box, Button} from "@mui/material";
import {ReactComponent as DeleteIcon} from "../assets/images/delete.svg";
import {useEffect, useState} from "react";
import {deleteTrainer, disableTrainer, enableTrainer, getTrainer} from "../services/TrainerService";
import {useNavigate, useParams} from "react-router-dom";
import {ReactComponent as BackIcon} from "../assets/images/backIcon.svg";
import TrainerProfileSection from "../componenets/trainers/TrainerProfileSection";
import TrainerStatusSection from "../componenets/trainers/TrainerStatusSection";
import NewTrainerDialog from "../componenets/NewTrainerDialog";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";
import {useAuth} from "../contexts/AuthContext";

const TrainerPage = () => {
  const params = useParams();
  let navigate = useNavigate();
  const {notificationHandler} = useMessageContext();
  const {isTrainer} = useAuth();

  const [trainer, setTrainer] = useState();
  const [editTrainer, setEditTrainer] = useState(false);

  const [reload, setReload] = useState(false);

  useEffect(() => {
    getTrainer(params.trainerId)
        .then(r => {
          setTrainer(r.data);
        }).catch(e => {
        notificationHandler.error(getErrorMessage(e.response.data?.errorCode))
    })
  }, [reload])

  const handleDeleteTrainer = () => {
    deleteTrainer(params.trainerId)
        .then(() => navigate(-1))
        .then(() => notificationHandler.success("מאמן נמחק בהצלחה"))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
  }

  const handleDisableEnableTrainer = () => {
    if (trainer.enabled){
      disableTrainer(trainer.id)
          .then(() => setReload(prv => !prv))
          .then(() => notificationHandler.success("מאמן בוטל בהצלחה"))
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
    } else {
      enableTrainer(trainer.id)
          .then(() => setReload(prv => !prv))
          .then(() => notificationHandler.success("מאמן הופעל בהצלחה"))
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
    }
  }

  return (
      <>
        {trainer && <NewTrainerDialog reload={setReload} trainer={trainer} open={editTrainer} close={() => setEditTrainer(false)}/>}
        <Box sx={{display: "flex", justifyContent: "flex-end", marginLeft: "20px", marginTop: "30px", cursor: "pointer"}}
             onClick={() => navigate(-1)}>
          <BackIcon/>
        </Box>

        {trainer &&
            <>
              <Box sx={{margin: "20px"}}>
            <TrainerProfileSection setEditTrainer={setEditTrainer} trainer={trainer}/>
                <Box sx={{marginTop: "20px"}}>
            <TrainerStatusSection trainer={trainer}/>
                </Box>
              </Box>
          {!isTrainer() && <Box sx={{margin: "28px", display: "flex", justifyContent: "flex-start"}}>
            <Button variant="outlined"
                    onClick={handleDeleteTrainer}
                    sx={{backgroundColor: "#FCFCFD", color: "#27272E", height: "44px", width: "78px", "& .MuiButton-endIcon" : {
                        marginLeft: "-4px",
                        marginTop: "2px",
                        borderRadius: "8px",
                        marginRight: "8px",
                      }}} endIcon={<DeleteIcon/>}
            >
              מחק
            </Button>
            <Button variant="text"
                    onClick={handleDisableEnableTrainer}
                    sx={{color: trainer.enabled ? "#F16063" : "#57835b", height: "44px", width: "78px"}}>
              {trainer.enabled ? "חסום" : "הפעל"}
            </Button>
          </Box>}
            </>
        }
      </>
  );
};

export default TrainerPage;
