import httpService from "./HttpService";

export const sendEmail = async (email) => {
  const token = localStorage.getItem("auth");

  return await httpService.post(
      "admin/message/email",
      email,
      token);
};

export const sendNotification = async (notification) => {
  const token = localStorage.getItem("auth");

  return await httpService.post(
      "admin/message/notification",
      notification,
      token);
};
