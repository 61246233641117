import httpService from "./HttpService";

export async function getCompany(id) {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/company/${id}`,null, token);
}

export async function getRegularCompany(id) {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/company/company/${id}`,null, token);
}


export async function getCompanies() {
  const token = localStorage.getItem("auth");
  return httpService.get(`admin/company`,null, token);
}

export async function deleteOwner(id) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`admin/company/companyOwner/${id}`,null, token);
}

export async function addOwner(data) {
  const token = localStorage.getItem("auth");
  return httpService.post(`admin/company/companyOwner`,data, token);
}

export async function editOwner(data) {
  const token = localStorage.getItem("auth");
  return httpService.put(`admin/company/companyOwner`,data, token);
}

export async function saveCompany(metaData, file) {
  const token = localStorage.getItem("auth");
  const formData = new FormData();

  if (file) {
    let blob = await fetch(file).then(r => r.blob());

    // Update the formData object
    formData.append(
        "file",
        blob,
        blob.name
    );
  }


  formData.append(
      "metaData",
      metaData
  )

  return await httpService.post(
      `admin/company`,
      formData,
      token);
}

export const enableCompany = async (id) => {
  const token = localStorage.getItem("auth");
  return await httpService.put(
      `admin/company/enable/${id}`,
      null,
      token);
}

export const isCompanyNameValid = async (name) => {
  const token = localStorage.getItem("auth");

  return await httpService.get(
      `admin/company/nameValidate/${name}`,
      null,
      token);
}

export const disableComapny = async (id) => {
  const token = localStorage.getItem("auth");
  return await httpService.put(
      `admin/company/disable/${id}`,
      null,
      token);
}

export const deleteCompany = async (id) => {
  const token = localStorage.getItem("auth");
  return await httpService.delete(
      `admin/company/${id}`,
      null,
      token);
}

export const uploadImage = async (id, file) => {
  const token = localStorage.getItem("auth");
  const formData = new FormData();

  // Update the formData object
  formData.append(
      "file",
      file,
      file.name
  );

  return await httpService.post(
      `admin/company/updateImage/${id}`,
      formData,
      token);
}
