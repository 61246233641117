import {
  Box, Button, Hidden, IconButton, InputBase, Menu, MenuItem, Typography
} from "@mui/material";
import {ReactComponent as SearchIcon} from "../../assets/images/search.svg";
import {ReactComponent as ActiveIcon} from "../../assets/images/active.svg";
import {ReactComponent as MailIcon} from "../../assets/images/mail.svg";
import {ReactComponent as InActiveIcon} from "../../assets/images/inactive.svg";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SelectCom from "../SelectCom";
import {useEffect, useState} from "react";
import {getTrainers} from "../../services/TrainerService";
import {useAuth} from "../../contexts/AuthContext";

const ClientFilterBar = ({handleEnableClients, handleDisableClients, handleSendMessage, selectedClients, updateKeyword, updateTrainer, trainer, gender, updateGender, state, updateState}) => {
  const [trainers, setTrainers] = useState([]);

  const {isAdmin} = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isAdmin()) {
      getTrainers().then(res => {
        setTrainers(res.data);
      })
    }
  }, [])

  return (
      <>
        <Hidden mdUp>
          <Box sx={{backgroundColor: "#F9FAFB",
            border: "1px solid #A9ABB0",
            marginTop: "8px",
            borderRadius: "8px",
            width: "100%",
            display: "inline-flex",
          }}>
            <IconButton disabled={true} sx={{ p: '12px', marginLeft: "6px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="חיפוש לפי שם"
            />
          </Box>
        </Hidden>
          <Box sx={{marginTop: "30px",display: "flex"}}>
            <Hidden mdDown>
              <Box sx={{backgroundColor: "#F9FAFB",
                border: "1px solid #A9ABB0",
                borderRadius: "8px",
                maxHeight: "41px",
                marginLeft: "4px",
                width: "auto",
                display: "inline-flex",
              }}>
                <IconButton disabled={true} sx={{ p: '12px', marginLeft: "6px" }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                    onChange={(newValue) => updateKeyword(newValue.target.value)}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="חיפוש לפי שם"
                />
              </Box>
              <SelectCom
                  defaultValue={"ALL"}
                  value={state}
                  label={"מצב"}
                  onChange={updateState}
                  mainItem={"הכול"} contrast={"black"} marginLeft="8px">
                <MenuItem value={'ACTIVE'} sx={{display: "flex", alignItems: "center"}}>
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <Box sx={{borderRadius: "20px", mt: "3px", width: "10px", height: "10px", backgroundColor: "#57837E"}}/>
                    <Typography sx={{marginLeft: "8px"}}>פעיל</Typography>
                  </Box>
                </MenuItem>
                <MenuItem value={'TRAIL'} sx={{display: "flex", alignItems: "center"}}>
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <Box sx={{borderRadius: "20px", mt: "3px", width: "10px", height: "10px", backgroundColor: "#0E63B3"}}/>
                    <Typography sx={{marginLeft: "8px"}}>תקופת ניסיון</Typography>
                  </Box>
                </MenuItem>
                <MenuItem value={'CLOSE_TO_END'} sx={{display: "flex", alignItems: "center"}}>
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <Box sx={{borderRadius: "20px", mt: "3px", width: "10px", height: "10px", backgroundColor: "#F7936F"}}/>
                    <Typography sx={{marginLeft: "8px"}}>עומד להסתיים</Typography>
                  </Box>
                </MenuItem>
                <MenuItem value={'MISSING'} sx={{display: "flex", alignItems: "center"}}>
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <Box sx={{borderRadius: "20px", mt: "3px", width: "10px", height: "10px", backgroundColor: "#F16063"}}/>
                    <Typography sx={{marginLeft: "8px"}}>נעדרים</Typography>
                  </Box>
                </MenuItem>
                <MenuItem value={'IN_ACTIVE'} sx={{display: "flex", alignItems: "center"}}>
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <Box sx={{borderRadius: "20px", mt: "3px", width: "10px", height: "10px", backgroundColor: "#E7E9EE"}}/>
                    <Typography sx={{marginLeft: "8px"}}>לא פעיל</Typography>
                  </Box>
                </MenuItem>
              </SelectCom>
            </Hidden>
            <SelectCom
                mainItem={"הכול"}
                label={"מגדר"}
                contrast={"black"}
                marginLeft="8px"
                value={gender}
                onChange={updateGender}
                defaultValue={'ALL'}>
              <MenuItem value={"MALE"} sx={{display: "flex", alignItems: "center"}}>
                <Typography sx={{marginLeft: "8px"}}>זכר</Typography>
              </MenuItem>
              <MenuItem value={"FEMALE"} sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Typography sx={{marginLeft: "8px"}}>נקבה</Typography>
              </MenuItem>
            </SelectCom>
            {isAdmin() && <Box sx={{marginLeft: "8px"}}> <SelectCom
                defaultValue={0}
                onChange={updateTrainer}
                value={trainer}
                label={"מאמן"}
                mainItem={"הכול"} contrast={"black"}  marginLeft="8px">
              {trainers.map((trainer, id) => {
                return <MenuItem key={id} value={trainer.id} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{trainer.name}</Typography>
                </MenuItem>
              })}
            </SelectCom> </Box>}
            <Box>
              <Button
                  id="basic-button"
                  disabled={selectedClients.length === 0}
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  sx={{"& .MuiButton-endIcon" : {
                      marginLeft: "-6px",
                      marginTop: "6px",
                      borderRadius: "8px",
                      marginRight: "8px",
                      }, "&.MuiButton-containedSizeMedium" : {
                      padding: "8px",
                      paddingLeft: "16px",
                      paddingRight: "16px"
                    }}}
                  variant={"contained"}
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon/>}
              >
                פעולות
              </Button>
              <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
              >
                <MenuItem onClick={() => {
                  handleEnableClients();
                  handleClose();
                }}> <ActiveIcon/> הפוך פעיל </MenuItem>
                <MenuItem onClick={() => {
                  handleDisableClients();
                  handleClose();
                }}><InActiveIcon/> הפוך ללא פעיל </MenuItem>
                <MenuItem onClick={() => {
                  handleSendMessage();
                  handleClose();
                }}><Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}><Box sx={{marginLeft: "4px"}}><MailIcon/></Box><Typography sx={{marginTop: "-4px"}}> שלח הודעה  </Typography></Box></MenuItem>
              </Menu>
            </Box>
        </Box>
      </>

  );


};

export default ClientFilterBar;
