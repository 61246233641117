import React, {useContext, useState} from "react";

const MenuContext = React.createContext({ undefined });

export function useMenu() {
  return useContext(MenuContext);
}

export function MenuProvider({ children }) {
  const [menuText, setMenuText] = useState("");
  const [showBack, setShowBack] = useState(false);


  const menuInit = (menuText, showBack) => {
    setMenuText(menuText);
    setShowBack(showBack);
  }

  const value = {
    menuInit,
    menuText,
    showBack,
  };

  return (
      <MenuContext.Provider value={value}>
        {children}
      </MenuContext.Provider>
  );
}
