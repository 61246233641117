import {Box, Button, Typography} from "@mui/material";
import {ReactComponent as PlusIcon} from "../assets/images/plus.svg";
import PageHeader from "../componenets/shared/PageHeader";
import {useState} from "react";
import {getExercises, saveExercise} from "../services/ExcersiceService";
import MiniExercises from "../componenets/exercises/MiniExercises";
import ExerciseSearchBar from "../componenets/ExerciseSearchBar";
import ExerciseDialog from "../componenets/exercises/ExerciseDialog";
import Loader from "../componenets/Loader";

const Exercises = () => {

  const [newExercise, setNewExercises] = useState(false);

  const [loading, setLoading] = useState(false);

  const [exerciseState, setExerciseState] = useState({
    exercises: [],
    numberOfElements: 0,
    keyword: "",
    resistanceType: {
      name: "",
      value: null
    },
    targetMuscle: {
      name: "",
      value: null
    },
    specificMuscle: "ALL",
    pageId: 0,
    hasMoreData: true,
    isLoading: true,
  });

  const updateKeyword = (keyword) => {
    setExerciseState((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        keyword: keyword,
        pageId: 0,
        exercises: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const reload = () => {
    setExerciseState(() => {
      return {
        exercises: [],
        numberOfElements: 0,
        keyword: "",
        resistanceType: {
          name: "",
          value: null
        },
        targetMuscle: {
          name: "",
          value: null
        },
        specificMuscle: "ALL",
        pageId: 0,
        hasMoreData: true,
        isLoading: true,
      }
    })
  }

  const updateResistanceType = (resistanceType) => {
    setExerciseState((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        resistanceType: resistanceType,
        pageId: 0,
        exercises: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const updateTargetMuscle = (targetMuscle) => {
    setExerciseState((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        targetMuscle: targetMuscle,
        pageId: 0,
        exercises: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const updateSpecificMuscle = (specificMuscle) => {
    setExerciseState((prv) => {
      return {
        ...prv,
        totalNumberOfElements: 0,
        specificMuscle: specificMuscle,
        pageId: 0,
        exercises: [],
        hasMoreData: true,
        isLoading: true,
      };
    });
  };

  const fetchNextPage = () => {
    getExercises(
        exerciseState.pageId,
        20,
        exerciseState.keyword,
        exerciseState.resistanceType,
        exerciseState.targetMuscle,
        exerciseState.specificMuscle
    )
        .then((res) => {
          const content = res.data.content;

          setExerciseState((prevState) => {
            let newExercises = [...prevState.exercises, ...content];

            if (exerciseState.pageId === 0) {
              newExercises = content;
            }

            return {
              ...prevState,
              exercises: newExercises,
              numberOfElements: res.data.numberOfElements,
              pageId: prevState.pageId + 1,
              hasMoreData: content.length !== 0,
              isLoading: false,
            };
          });
        })
        .catch((e) => {
          setExerciseState((prvState) => {
            return { ...prvState, isLoading: false, hasMoreData: false };
          });
          console.log("Error: ", e);
        });
  };

  const handleClose = () => {
    setNewExercises(false)
  }

  return (
      <>
        <Loader loading={loading} />
        <ExerciseDialog isOpen={newExercise} close={handleClose} setReload={reload} />
        <PageHeader title={"תרגילים"}>
          <Box sx={{marginTop: {xs: "0", md: "28px"}, display: "flex", justifyContent: {md: "flex-end", xs: "start"}, alignItems: "center"}}>
            <Box sx={{display: {xs : "none", md: "inherit"}}}>
              <Button variant="outlined"
                      onClick={() => setNewExercises(true)}
                      sx={{backgroundColor: "#F2F4F7", height: "44px", width: "166px", "& .MuiButton-startIcon" : {
                          marginRight: "-4px",
                          marginTop: "2px",
                          marginLeft: "8px",
                        }}} startIcon={<PlusIcon/>}>
                הוסף תרגיל
              </Button>
            </Box>
          </Box>
          </ PageHeader>
        <ExerciseSearchBar
          updateKeyword={updateKeyword}
          keyword={exerciseState.keyword}
          updateSpecificMuscle={updateSpecificMuscle}
          specificMuscle={exerciseState.specificMuscle}
          updateResistanceType={updateResistanceType}
          resistanceType={exerciseState.resistanceType}
          updateTargetMuscle={updateTargetMuscle}
          targetMuscle={exerciseState.targetMuscle}
        />
        <MiniExercises reload={reload} exerciseState={exerciseState} fetchNextPage={fetchNextPage}/>
      </>
  );
};

export default Exercises;
