export const GoalTypes = [
  {
    name: "ירידה במשקל",
    value: "LOSE_WEIGHT",
    trend: -1,
  },
  {
    name: "מיני קאט",
    value: "MINI_CUT",
    trend: -1,
  },
  {
    name: "חיטוב",
    value: "CUTTING",
    trend: -1,
  },
  {
    name: "מסה",
    value: "GAIN_WEIGHT",
    trend: 1,
  },
  {
    name: "רוורס דיאט",
    value: "REVERSE_DIET",
    trend: 1,
  },
  {
    name: "תחזוקה",
    value: "MAINTENANCE",
    trend: 0
  }
]
