import {Box, Typography} from "@mui/material";

const ClientWeekChip = ({disable, index, active, setActiveTab}) => {

  return (
      <Box sx={{
        cursor: disable ? '' : "pointer",
        backgroundColor: active ? "#57837E" : "#F9FAFB",
        borderRadius: "8px 8px 0px 0px",
        padding: "8px",
        marginRight: "4px",
        marginLeft: "4px",
      }} onClick={() => disable ? {} : setActiveTab(index)}>
        <Typography sx={{color: active ? "#FCFCFD" : "#717784",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "14px"
        }}> אימון {index}</Typography>
      </Box>
  );
};

export default ClientWeekChip;
