import {Box, FormControl, MenuItem, Select, Typography} from "@mui/material";
import {RTL} from "../../cache/CacheRtl";

const TemplateNumbers = ({numberOfWorkouts, updateNumberOfWeeks, numberOfWeeks, updateNumberOfWorkouts, numberOfBlocks, setNumberOfBlocks, editMode}) => {

  return (
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
          <Typography sx={{fontSize: "12px",
            marginTop: "40px",
            color: "#4F535C",
            fontWeight: "400",
            lineHeight: "13px"}}>מספר בלוקים</Typography>
          <RTL>
            <FormControl>
              <Select
                  disabled={!editMode}
                  sx={{minWidth: "64px", marginTop: "8px"}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={numberOfBlocks}
                  onChange={e => setNumberOfBlocks(e.target.value)}
              >
                {Array.from(Array(11).keys()).map((_, i) => {
                  return  <MenuItem key={i} value={i+1}>{i+1}</MenuItem>
                })}
              </Select>
            </FormControl>
          </RTL>
        </Box>

          <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", marginRight: "48px"}}>
          <Typography sx={{fontSize: "12px",
            marginTop: "40px",
            color: "#4F535C",
            fontWeight: "400",
            lineHeight: "13px"}}> מספר שבועות בבלוק</Typography>
          <RTL>
            <FormControl>
              <Select
                  disabled={!editMode || numberOfBlocks === 0}
                  sx={{minWidth: "64px", marginTop: "8px"}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={numberOfWeeks}
                  onChange={e => updateNumberOfWeeks(e.target.value)}
              >
                {Array.from(Array(10).keys()).map((_, i) => {
                  return  <MenuItem key={i} value={i+1}>{i+1}</MenuItem>
                })}
              </Select>
            </FormControl>
          </RTL>
        </Box>
        <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", marginRight: "48px"}}>
          <Typography sx={{fontSize: "12px",
            marginTop: "40px",
            color: "#4F535C",
            fontWeight: "400",
            lineHeight: "13px"}}>מספר אימונים בשבוע</Typography>
          <RTL>
            <FormControl>
              <Select
                  disabled={!editMode || numberOfWeeks === 0}
                  sx={{minWidth: "64px", marginTop: "8px"}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => updateNumberOfWorkouts(e.target.value)}
                  value={numberOfWorkouts}
              >
                {Array.from(Array(7).keys()).map((_, i) => {
                  return <MenuItem key={i} value={i+1}>{i+1}</MenuItem>
                })}
              </Select>
            </FormControl>
          </RTL>
        </Box>

      </Box>
  );
};

export default TemplateNumbers;
