export const WeeklyRanges = [
  {
    name: "-(0.1-0.3)",
    value: "X_SMALL_MINUS",
    min: -0.1,
    max: -0.3,
  },
  {
    name: "-(0.2-0.4)",
    value: "SMALL_MINUS",
    min: -0.2,
    max: -0.4,
  },
  {
    name: "-(0.3-0.5)",
    value: "MEDIUM_MINUS",
    min: -0.3,
    max: -0.5,
  },
  {
    name: "-(0.4-0.6)",
    value: "X_MEDIUM_MINUS",
    min: -0.4,
    max: -0.6,
  },
  {
    name: "-(0.5-0.8)",
    value: "LARGE_MINUS",
    min: -0.5,
    max: -0.8,
  },
  {
    name: "-(0.7-1)",
    value: "X_LARGE_MINUS",
    min: -0.7,
    max: -1,
  },
  {
    name: "+(0.1-0.3)",
    value: "X_SMALL_PLUS",
    min: 0.1,
    max: 0.3,
  },
  {
    name: "+(0.2-0.4)",
    value: "SMALL_PLUS",
    min: 0.2,
    max: 0.4,
  },
  {
    name: "+(0.3-0.5)",
    value: "MEDIUM_PLUS",
    min: 0.3,
    max: 0.5,
  },
  {
    name: "+(0.4-0.6)",
    value: "X_MEDIUM_PLUS",
    min: 0.4,
    max: 0.6,
  },
  {
    name: "+(0.5-0.8)",
    value: "LARGE_PLUS",
    min: 0.5,
    max: 0.6,
  },
  {
    name: "+(0.7-1)",
    value: "X_LARGE_PLUS",
    min: 0.7,
    max: 1,
  },
]
