import {useNavigate} from "react-router-dom";
import {TableCell, TableRow} from "@mui/material";
import moment from "moment";
import {experienceLevels} from "../../enums/ExperienceLevels";
import {SecondaryMuscles} from "../../enums/SecondaryMuscles";
import {mainMuscles} from "../../enums/MainMuscles";
import {TemplateGoals} from "../../enums/TemplateGoals";

const TemplateCard = ({template, index}) => {

  let navigate = useNavigate();

  return (
      <TableRow key={index}
          onClick={() => navigate(`/app/templates/${template.id}`)}
          sx={{"&.MuiTableRow-root" : {
              border: "1px solid #E7E9EE",
              height: "100px",
              backgroundColor: "#F9FAFB",
              cursor: "pointer",
              "&:hover" : {
                backgroundColor: "rgba(185,217,213,0.41)"
              },
              boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06)"
            }}}>
        <TableCell align="right">
          {template.nameHeb}
        </TableCell>
        <TableCell align="center">
          {template.gender === "FEMALE" && "נקבה"}
          {template.gender === "MALE" && "זכר"}
          {!template.gender && "אין מידע"}
        </TableCell>
        <TableCell align="center">
          {TemplateGoals[template.goals] || "אין מידע"}
        </TableCell>
        <TableCell align="center">
          {template.numOfWorkouts}
        </TableCell>
        <TableCell align="center">
          {template?.workoutMinDuration}
          -
          {template?.workoutMaxDuration}
        </TableCell>
        <TableCell align="center">
          {experienceLevels.find(lvl => lvl.value === template.experienceLevel)?.name }
        </TableCell>
        <TableCell align="center">
          {mainMuscles.find(tm => tm.value === template.mainMuscle)?.name}
        </TableCell>
        <TableCell align="center">
          {SecondaryMuscles.find(tm => tm.value === template.secondaryMuscle)?.name}
        </TableCell>
        <TableCell align="center">
          {SecondaryMuscles.find(tm => tm.value === template.secondaryMuscle2)?.name}
        </TableCell>
        <TableCell align="left">
          {moment(moment.unix(template.createdDate)).format("DD/MM/YY")}
        </TableCell>
      </TableRow>
  );
};

export default TemplateCard;
