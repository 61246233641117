import {Box, Button, CardContent, Chip, Stack, TextField, Typography} from "@mui/material";
import Dialog from "./shared/Dialog";
import {useEffect, useState} from "react";
import {addClientNutrition} from "../services/TraineeService";
import Loader from "./Loader";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";

const NewNutritionDialog = ({isNew, newFile, reload, traineeId, close}) => {

  const [pdfFile, setPdfFile] = useState(null);

  const [loading, setLoading] = useState(false);

  const {notificationHandler} = useMessageContext();

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log("dude");
    setPdfFile(file);
  }

  const [data, setData] = useState({});


  useEffect(() => {
    setPdfFile(() => !isNew ? newFile.menu?.fileUrl : null);

    setData(() => {
      return {
        id: !isNew ? newFile.menu.id : null,
        name: !isNew ? newFile.menu.name : '',
        calories: !isNew ? newFile.menu.calories : '',
        carbs:  !isNew ? newFile.menu.carbs : '',
        proteins: !isNew ? newFile.menu.proteins : '',
        traineeId: traineeId,
        fats: !isNew ? newFile.menu.fats : '',
    }});
  }, [newFile.isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const {name, calories, carbs, proteins, fats} = document.forms[0];

    const body = {
      id: data.id,
      name: name?.value,
      calories: calories?.value,
      carbs: carbs?.value,
      proteins: proteins?.value,
      traineeId: traineeId,
      fats: fats?.value
    }

    name.value = "";
    calories.value = "";
    carbs.value = "";
    proteins.value = "";
    fats.value = ""

    addClientNutrition(body, pdfFile)
        .then(() => setLoading(false))
        .then(reload)
        .then(close)
        .then(() => notificationHandler.success("תפריט הוזן בהצלחה"))
        .catch(e => {
          setLoading(false);
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
        })
  }

  const updateName = (name) => {
    setData(prv => {
      return  {
        ...prv,
        name
      }
    })
  }

  const updateCalories = (calories) => {
    setData(prv => {
      return  {
        ...prv,
        calories
      }
    })
  }

  const updateCarbs = (carbs) => {
    setData(prv => {
      return  {
        ...prv,
        carbs
      }
    })
  }

  const updateProteins = (proteins) => {
    setData(prv => {
      return  {
        ...prv,
        proteins
      }
    })
  }

  const updateFats = (fats) => {
    setData(prv => {
      return  {
        ...prv,
        fats
      }
    })
  }

  const isValidUrl = urlString=> {
    try {
      return Boolean(new URL(urlString));
    }
    catch(e){
      return false;
    }
  }

  return (
      <Dialog isOpen={newFile.isOpen} title={"יצירת תפריט"} close={close}>
        <Loader loading={loading}/>
        <CardContent component={"form"} sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Box sx={{width: "70%"}}>
            <Typography sx={{marginTop: "60px",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "24px",
              color: "#4F535C"
            }}>שם התפריט</Typography>
            <TextField
                onChange={(e) => updateName(e.target.value)}
                value={data.name}
                sx={{width: "100%", alignSelf: "center"}}
                       placeholder="שם התפריט" id="name"
                       variant="outlined"/>
          </Box>
          <Box>
          <Typography sx={{marginTop: "28px",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#4F535C"
          }}>קלוריות:</Typography>
          <TextField
              onChange={e => updateCalories(e.target.value)}
              value={data.calories}
              type={"number"} id={"calories"} sx={{width: "80px",
            '& input[type=number]': {
              '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0
            }
          }} />
          </Box>
          <Box sx={{display: "flex"}}>
          <Box>
            <Typography sx={{marginTop: "28px",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "24px",
              color: "#4F535C"
            }}>פחמימה:</Typography>
            <TextField
                onChange={e => updateCarbs(e.target.value)}
                value={data.carbs}
                type={"number"} id={"carbs"} sx={{width: "80px",
              '& input[type=number]': {
                '-moz-appearance': 'textfield'
              },
              '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
              },
              '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
              }
            }} />
          </Box>
            <Box sx={{marginRight: "28px",}}>
              <Typography sx={{marginTop: "28px",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "24px",
                color: "#4F535C"
              }}>חלבון:</Typography>
              <TextField
                  value={data.proteins}
                  onChange={e => updateProteins(e.target.value)}
                  type={"number"} id={"proteins"} sx={{width: "80px",
                '& input[type=number]': {
                  '-moz-appearance': 'textfield'
                },
                '& input[type=number]::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0
                },
                '& input[type=number]::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0
                }
              }} />
            </Box>
            <Box sx={{marginRight: "28px",}}>
              <Typography sx={{marginTop: "28px",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "24px",
                color: "#4F535C"
              }}>שומן:</Typography>
              <TextField
                  value={data.fats}
                  onChange={e => updateFats(e.target.value)}
                  type={"number"} id={"fats"} sx={{width: "80px",
                '& input[type=number]': {
                  '-moz-appearance': 'textfield'
                },
                '& input[type=number]::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0
                },
                '& input[type=number]::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0
                }
              }} />
            </Box>
          </Box>
          <Button
              variant="outlined"
              component="label"
              sx={{marginTop: "33px"}}
          >
            {pdfFile ? "החלף pdf" : "העלה PDF"}
            <input
                accept=".pdf"
                type="file"
                hidden
                onChange={handleFileUpload}
            />
          </Button>
          <Stack direction="row" spacing={1} sx={{marginTop: "8px"}}>
            {pdfFile && <Chip
                  sx={{
                    width: "100%",
                    boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
                    borderRadius: "8px",
                    border: "1px solid #E7E9EE", backgroundColor: "#FCFCFD"}} label={isValidUrl(pdfFile) ? 'pdf' : pdfFile.name} onDelete={() => setPdfFile(null)} />
            }
          </Stack>
          <Box sx={{display: "flex", alignItems: "center", marginTop: "33px", }}>
            <Button
                onClick={close}
                variant="outlined"
                component="label">
              בטל
            </Button>
            <Button
                variant="contained"
                type="submit"
                onClick={(e) => handleSubmit(e)}
                sx={{marginRight: "24px"}}
            >
              יצירה
              <input
                  type="file"
                  hidden
              />
            </Button>
          </Box>
        </CardContent>
      </Dialog>
  );
};

export default NewNutritionDialog;
