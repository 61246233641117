import {
  Box,
  Button,
  Divider,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {ReactComponent as PlanIcon} from "../../assets/images/templates.svg";
import {ReactComponent as DownArrowIcon} from "../../assets/images/down_arrow.svg";
import {ReactComponent as UpArrowIcon} from "../../assets/images/up_arrow.svg";
import {useEffect, useState} from "react";
import {changeAutomatic} from "../../services/TraineeService";
import {changeProgram, getHistory} from "../../services/PlanSerice";
import moment from "moment";
import {getErrorMessage} from "../../enums/ErrorMessages";
import {useMessageContext} from "../../contexts/MessageContext";
import ChangeProgramDialog from "../ChnageProgramDialog";
import {mainMuscles} from "../../enums/MainMuscles";
import {SecondaryMuscles} from "../../enums/SecondaryMuscles";
import {useNavigate} from "react-router-dom";

const ClientPlansSection = ({client, handleReload}) => {

  const [planOpen, setPlanOpen] = useState(false);

  const {notificationHandler} = useMessageContext();

  const [historyPlans, setHistoryPlans] = useState();

  const [refetchHistory, setRefetchHistory] = useState(false);

  const [changeProgramState, setChangeProgramState] = useState(false);

  let navigate = useNavigate();

  const handleChangeProgram = (templateId) => {
    changeProgram(client.id, templateId)
        .then(notificationHandler.success("תוכנית שונתה בהצלחה"))
        .then(() => setChangeProgramState(false))
        .then(() => setRefetchHistory(prv => !prv))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
  }

  useEffect(() => {
    getHistory(client.id)
        .then(res => setHistoryPlans(res.data))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
  }, [refetchHistory])

  const handleChangeAutomatic = () => {
    changeAutomatic(client.id, !client.automatic)
        .then(handleReload)
        .then(() => notificationHandler.success("מתאמן שונה בהצלחה"))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
  }

  return (
      <Paper sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7",
        flexGrow: 0,
        borderRadius: "4px"}}>
        <ChangeProgramDialog trainee={client} handleChangeProgram={handleChangeProgram} isOpen={changeProgramState} close={() => setChangeProgramState(false)}/>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Box sx={{display: "flex", alignItems: "center", marginRight: "8px"}}>
            <PlanIcon/>
            <Typography sx={{fontWeight: 600,
              fontSize: "20px",
              lineHeight: "27.3px",
              color: "#4F535C",
              padding: "10px"}}>היסטורית אימונים</Typography>
          </Box>
          <Box sx={{mt: "8px", cursor: "pointer"}} onClick={() => setPlanOpen(prv => !prv)}>
            {planOpen ? <UpArrowIcon/> : <DownArrowIcon/>}
          </Box>
        </Box>
        {planOpen && <Box sx={{mb: "20px", display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
          <Typography sx={{mr: "20px", fontSize: "14px", color: "#717784"}}>סוג:</Typography>
          <Typography sx={{ml: "10px", marginRight: "10px", color: "#4F535C", fontSize: "16px", fontWeight: 600}}>אוטומוט</Typography>
          <Box>
            <Switch checked={client.automatic} onChange={handleChangeAutomatic} />
          </Box>
        </Box>}
        <Divider/>
        <Box sx={{display: "flex", justifyContent: "flex-end", mt: "10px", ml: "10px"}}>
          <Button
              onClick={() => setChangeProgramState(true)}
          variant={"contained"}
          >
            שנה תוכנית +
          </Button>
        </Box>
        <TableContainer>
          <Table>
            <TableHead sx={{backgroundColor: "#F9FAFB"}}>
              <TableRow>
                <TableCell align={"right"} sx={{borderBottom: 0,
                  padding: "8px", color: "#717784",
                  backgroundColor: "#FCFCFD"}}>שם פרופיל:
                </TableCell>
                <TableCell align={"center"} sx={{borderBottom: 0,
                  padding: "8px", color: "#717784",
                  backgroundColor: "#FCFCFD"}}>מגדר:
                </TableCell>
                <TableCell align={"center"} sx={{borderBottom: 0,
                  padding: "8px", color: "#717784",
                  backgroundColor: "#FCFCFD"}}>דגש ראשי:
                </TableCell>
                <TableCell align={"center"} sx={{borderBottom: 0,
                  padding: "8px", color: "#717784",
                  backgroundColor: "#FCFCFD"}}>שריר ספציפי:
                </TableCell>
                <TableCell align={"center"} sx={{borderBottom: 0,
                  padding: "8px", color: "#717784",
                  backgroundColor: "#FCFCFD"}}>מספר אימונים:
                </TableCell>
                <TableCell align={"right"} sx={{borderBottom: 0,padding: "8px", color: "#717784",
                  backgroundColor: "#FCFCFD"}}>תאריך יצירה:
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historyPlans && historyPlans.map((plan, i) => {
                return <TableRow
                    onClick={() => navigate(`/app/clients/${client.id}/${plan.id}/week/0/0`)}
                    key={i} sx={{backgroundColor: plan.active ? "#F2F4F7" : "#FCFCFD",
                  cursor: "pointer",
                  "&:hover" : {
                    backgroundColor: "rgba(185,217,213,0.41)"
                  },
                }}>
                  <TableCell align={"right"} sx={{padding: "8px"}}>
                    <Typography sx={{
                      fontsize: "16px",
                      fontWeight: 600,
                      lineHeight: "25px",
                      color: "#4F535C"
                    }}>{plan.nameHeb}</Typography>
                  </TableCell>
                  <TableCell align={"center"} sx={{padding: "8px"}}>
                    <Typography sx={{
                      fontsize: "16px",
                      fontWeight: 600,
                      lineHeight: "25px",
                      color: "#4F535C"
                    }}>                    {plan.gender === "FEMALE" && "נקבה"}
                      {plan.gender === "MALE" && "זכר"}
                      {!plan.gender && "אין מידע"}</Typography>
                  </TableCell>
                  <TableCell align={"center"} sx={{padding: "8px"}}>
                    <Typography sx={{
                      fontsize: "16px",
                      fontWeight: 600,
                      lineHeight: "25px",
                      color: "#4F535C"
                    }}> {mainMuscles.find(tm => tm.value === plan.mainMuscle)?.name}</Typography>
                  </TableCell>
                  <TableCell align={"center"} sx={{padding: "8px"}}>
                    <Typography sx={{
                      fontsize: "16px",
                      fontWeight: 600,
                      lineHeight: "25px",
                      color: "#4F535C"
                    }}>{SecondaryMuscles.find(tm => tm.value === plan.secondaryMuscle)?.name}</Typography>
                  </TableCell>
                  <TableCell align={"center"} sx={{padding: "8px"}}>
                    <Typography sx={{
                      fontsize: "16px",
                      fontWeight: 600,
                      lineHeight: "25px",
                      color: "#4F535C"
                    }}>{plan.numOfWorkouts}</Typography>
                  </TableCell>
                  <TableCell align={"right"} sx={{padding: "8px"}}>
                    <Typography sx={{
                      fontsize: "16px",
                      fontWeight: 600,
                      lineHeight: "25px",
                      color: "#4F535C"
                    }}>{moment(moment.unix(plan.createdDate)).format("DD/MM/YY")}</Typography>
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
  );
};

export default ClientPlansSection;
