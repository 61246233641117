import {Box, FormControl, MenuItem, Select, TableCell, TableRow, TextField} from "@mui/material";
import {ReactComponent as DeleteIcon} from "../assets/images/delete_program.svg";
import {RTL} from "../cache/CacheRtl";
import {TempoTypes} from "../enums/TempoTypes";
import {typeSets} from "../enums/TypeSets";

const WeekIntervalRow = ({exercise, setEditWorkout, editMode, interval, prvInterval}) => {

  const deleteInterval = () => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};

      newWorkout.programExercises = newWorkout.programExercises.sort((a, b) => a.index > b.index ? 1 : -1);

      let programExercise = newWorkout.programExercises.at(exercise.index);


      let idx = 0;

      programExercise.intervals = programExercise.intervals
          .sort((a, b) => a.index > b.index ? 1 : -1)
          .filter(x => x.index !== interval.index)
          .map(val => {
            val.index = idx;
            idx += 1;
            return val;
          })

      return newWorkout;
    })
  }

  const handleRecommendedWeight = (value) => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};

      newWorkout.programExercises = newWorkout.programExercises.sort((a, b) => a.index
      > b.index ? 1 : -1);

      let programExercise = newWorkout.programExercises.at(exercise.index);

      programExercise.intervals.at(interval.index).recommendedWeight = value;
      return newWorkout;
    });
  }

  const handleRecommendedReps = (value) => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};

      newWorkout.programExercises = newWorkout.programExercises.sort((a, b) => a.index
      > b.index ? 1 : -1);

      let programExercise = newWorkout.programExercises.at(exercise.index);

      programExercise.intervals.at(interval.index).recommendedReps = value;
      return newWorkout;
    });
  }

  const handleReps = (value) => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};

      newWorkout.programExercises = newWorkout.programExercises.sort((a, b) => a.index
      > b.index ? 1 : -1);

      let programExercise = newWorkout.programExercises.at(exercise.index);

      programExercise.intervals.at(interval.index).reps = value;
      return newWorkout;
    });
  }

  const handleWeight = (value) => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};

      newWorkout.programExercises = newWorkout.programExercises.sort((a, b) => a.index
      > b.index ? 1 : -1);

      let programExercise = newWorkout.programExercises.at(exercise.index);

      programExercise.intervals.at(interval.index).weight = value;
      return newWorkout;
    });
  }

  const handleRir = (value) => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};

      newWorkout.programExercises = newWorkout.programExercises.sort((a, b) => a.index
      > b.index ? 1 : -1);

      let programExercise = newWorkout.programExercises.at(exercise.index);

      programExercise.intervals.at(interval.index).rir = value;
      return newWorkout;
    });
  }

  const updateTypeSet = (value) => {
    setEditWorkout(prv => {
      let newWorkout = {...prv};

      newWorkout.programExercises = newWorkout.programExercises.sort((a, b) => a.index
      > b.index ? 1 : -1);

      let programExercise = newWorkout.programExercises.at(exercise.index);

      programExercise.intervals.at(interval.index).typeSet = value;
      return newWorkout;
    });
  }

  return (
      <TableRow key={interval.index}>
        <TableCell
            sx={{borderBottom: "1px solid #A9ABB0",
              textAlign: "center",
              width: "5%",
              padding: "2px", color: "#717784",
              backgroundColor: "#F2F4F7"}}
            component="th" scope="row">
          {interval.index+1}
        </TableCell>
        <TableCell
            sx={{borderBottom: "1px solid #A9ABB0",
              textAlign: "center",
              width: "5%",
              padding: "2px", color: "#717784",
              backgroundColor: "#FCFCFD"}}
            align={"center"}>{editMode ?
            <TextField
                value={interval.recommendedReps}
                variant={"standard"}
                type={"number"}
                onChange={e => handleRecommendedReps(e.target.value)}
            /> : `${prvInterval?.recommendedReps == null ? '' : '(' + prvInterval?.recommendedReps + ')'} ${interval?.recommendedReps == null ? '' : interval?.recommendedReps}`}</TableCell>
        <TableCell
            sx={{borderBottom: "1px solid #A9ABB0",
              textAlign: "center",
              width: "5%",
              padding: "2px", color: "#717784",
              backgroundColor: "#F2F4F7"}}
            align={"center"}>{editMode ?
            <TextField
                value={interval.reps}
                variant={"standard"}
                type={"number"}
                onChange={e => handleReps(e.target.value)}
            /> : `${prvInterval?.reps == null ? '' : '(' + prvInterval?.reps + ')'} ${interval?.reps == null ? '' : interval?.reps}` }</TableCell>
        <TableCell
            sx={{borderBottom: "1px solid #A9ABB0",
              textAlign: "center",
              width: "5%",
              padding: "2px", color: "#717784",
              backgroundColor: "#FCFCFD"}}
            align={"center"}>{ editMode ?
            <RTL>
              <FormControl fullWidth>
                <Select
                    sx={{minWidth: "100px", height: "48px"}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={interval.typeSet || ""}
                    onChange={(e) => updateTypeSet(e.target.value)}
                    displayEmpty
                >
                  {typeSets.map((res,i) => {
                    return <MenuItem key={i} value={res}>{res}</MenuItem>
                  })
                  })}
                </Select>
              </FormControl>
            </RTL> : interval.typeSet}</TableCell>
        <TableCell
            sx={{borderBottom: "1px solid #A9ABB0",
              textAlign: "center",
              width: "5%",
              padding: "2px", color: "#717784",
              backgroundColor: "#F2F4F7"}}
            align={"center"}>{editMode ?
            <TextField
                value={interval.recommendedWeight}
                variant={"standard"}
                type={"number"}
                onChange={e => handleRecommendedWeight(e.target.value)}
            /> : `${prvInterval?.recommendedWeight == null ? '' : '(' + prvInterval?.recommendedWeight + ')'} ${interval?.recommendedWeight == null ? '' : parseFloat(interval?.recommendedWeight)?.toFixed(2)}` }</TableCell>
        <TableCell
            sx={{borderBottom: "1px solid #A9ABB0",
              textAlign: "center",
              width: "5%",
              padding: "2px", color: "#717784",
              backgroundColor: "#FCFCFD"}}
            align={"center"}>{editMode ?
            <TextField
                value={interval.weight}
                variant={"standard"}
                type={"number"}
                onChange={e => handleWeight(e.target.value)}
            /> : `${prvInterval?.weight == null ? '' : '(' + prvInterval?.weight + ')'} ${interval?.weight == null ? '' : interval?.weight}` }</TableCell>
        <TableCell
            sx={{borderBottom: "1px solid #A9ABB0",
              textAlign: "center",
              width: "5%",
              padding: "2px", color: "#717784",
              backgroundColor: "#F2F4F7"}}
            align={"center"}>{editMode ?
            <TextField
                value={interval.rir}
                variant={"standard"}
                type={"number"}
                onChange={e => handleRir(e.target.value)}
            /> : `${prvInterval?.rir == null ? '' : '(' + prvInterval?.rir + ')'} ${interval?.rir == null ? '' : interval?.rir}` }</TableCell>
        {editMode && <TableCell
            sx={{borderBottom: "1px solid #A9ABB0",
              textAlign: "center",
              width: "5%",
              padding: "2px", color: "#717784",
              backgroundColor: "#FCFCFD"}}
            align={"center"}>
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Box sx={{cursor: "pointer"}} onClick={deleteInterval}>
              <DeleteIcon/>
            </Box>
          </Box>
        </TableCell>}
      </TableRow>
  )
};

export default WeekIntervalRow;
