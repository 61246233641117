import ClientCard from "./ClientCard";
import {
  Box,
  Card,
  CircularProgress,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";

const MiniClients = ({addOrRemoveClient, selectedClients, clientsState, fetchNextPage}) => {

  return (<Card elevation={0} sx={{overflow: "auto",
    overflowY: "hidden",
    marginTop: {md: "20px", xs: "70px"}}}>
    <InfiniteScroll
        initialLoad={true}
        hasMore={clientsState.hasMoreData}
        loadMore={fetchNextPage}
        loader={
          <Box
              key={0}
              sx={{ display: "flex", justifyContent: "center", margin: "16px" }}
          >
            <CircularProgress size={150} sx={{ color: "primary.main" }} />
          </Box>
        }
    >
      {clientsState.clients.length || clientsState.isLoading ? (
          <Box
              sx={{ margin: "15px"}}
          >
            <TableContainer>
              <Table sx={{"&.MuiTable-root" : {
                borderCollapse: "separate",
                borderSpacing: "0 15px",
                }}}>
                <TableHead>
                  <TableRow>
                    <TableCell align="right">
                      לקוחות ({clientsState.totalNumberOfElements})
                    </TableCell>
                    <TableCell align="center">
                      שם מלא
                    </TableCell>
                    <TableCell align="center">
                      גיל
                    </TableCell>
                    <TableCell align="center">
                      מגדר
                    </TableCell>
                    <TableCell align="center">
                      תאריך יצירה
                    </TableCell>
                    <TableCell align="center">
                      תאריך סיום
                    </TableCell>
                    <TableCell align="left">
                      פעיל
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
            {clientsState.clients.map((client) => (
                <ClientCard
                    client={client}
                    key={client.id}
                    selectedClients={selectedClients}
                    addOrRemoveClient={addOrRemoveClient}
                />
            ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
      ) : (
          <Box
              sx={{ margin: { md: "15px auto", xs: "15px" }}}
          >
            <Typography sx={{ fontSize: "20px",
              fontWeight: "700",
              color: "primary.main",
              textAlign: "center",}}>
              אין תוצאות
            </Typography>
          </Box>
      )}
    </InfiniteScroll>
  </Card>)

};

export default MiniClients;
