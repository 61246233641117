import {
  Box,
  Button, CircularProgress,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery
} from "@mui/material";
import {ReactComponent as RightArrowIcon} from '../../assets/images/right-arrow.svg';
import {ReactComponent as LeftArrowIcon} from '../../assets/images/left-arrow.svg';
import {ReactComponent as CompaniesIcon} from '../../assets/images/companies.svg';
import {ReactComponent as CompaniesActiveIcon} from '../../assets/images/companies_white.svg';
import {ReactComponent as DisconnectIcon} from '../../assets/images/disconnect.svg';
import {ReactComponent as DashboardIcon} from '../../assets/images/dashboard.svg';
import {ReactComponent as DashboardActiveIcon} from '../../assets/images/dashobardActive.svg';
import {ReactComponent as ExercisesIcon} from '../../assets/images/exercises.svg';
import {ReactComponent as ExercisesActiveIcon} from '../../assets/images/Exercises_active.svg';
import {ReactComponent as TrainersIcon} from '../../assets/images/trainers.svg';
import {ReactComponent as TrainersActiveIcon} from '../../assets/images/trainers_active.svg';
import {ReactComponent as SettingIcon} from '../../assets/images/setting.svg';
import {ReactComponent as SettingActiveIcon} from '../../assets/images/setting_active.svg';
import {ReactComponent as TemplatesIcon} from '../../assets/images/templates.svg';
import {ReactComponent as TemplatesActiveIcon} from '../../assets/images/templates_active.svg';
import {ReactComponent as ProgramRenewIcon} from '../../assets/images/program_renew.svg';
import {ReactComponent as ProgramRenewWithMessageIcon} from '../../assets/images/program_renew_notification.svg';
import {ReactComponent as ProgramRenewActiveIcon} from '../../assets/images/program_renew_active.svg';
import {ReactComponent as NotificationsIcon} from '../../assets/images/notifications.svg';
import {ReactComponent as NotificationsWithMessageIcon} from '../../assets/images/alert_message.svg';
import {
  ReactComponent as NotificationsActiveIcon
} from '../../assets/images/notifications_active.svg';
import {ReactComponent as LogoIcon} from '../../assets/images/logo.svg';
import PersonIcon from '@mui/icons-material/Person';
import SideNavItem from "./SideNavItem";
import {NavLink, useLocation} from "react-router-dom";
import {RTL} from "../../cache/CacheRtl";
import {useAuth} from "../../contexts/AuthContext";
import {useCompany} from "../../contexts/CompanyContext";
import {ReactComponent as ImageSkeletonIcon} from "../../assets/images/image-skelaton.svg";
import {ReactComponent as EditIcon} from "../../assets/images/editProfile.svg";
import {uploadUserImage} from "../../services/TraineeService";
import {useEffect, useState} from "react";
import {getErrorMessage} from "../../enums/ErrorMessages";
import {useMessageContext} from "../../contexts/MessageContext";
import {getAlerts} from "../../services/AlertService";
import {getPendingPrograms} from "../../services/PlanSerice";

const SideNav = ({isMiniNav, changeMiniNavDir, isMenuOpen, changeIsMenuOpen}) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const {isSuperAdmin, isCompanyAdmin, currentUser, refetchUser, logout} = useAuth();
  const {getCompaniesList, company, changeCompany} = useCompany();
  const isMiniAndFullScreen = isMiniNav && !isSmallScreen;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [penddingPrograms, setPenddingPrograms] = useState([]);

  const {notificationHandler} = useMessageContext();

  useEffect(() => {
    getAlerts(company?.id)
        .then(res => setNotifications(res.data))

    getPendingPrograms(company?.id)
        .then(res => setPenddingPrograms(res.data))
  },[location.pathname])

  const handleFileUpload = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    uploadUserImage(file)
        .then(refetchUser)
        .then(() => setLoading(false))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
          setLoading(false);
        });
  }

  const disconnect = () => {
    logout()
  }
  return (<>
    <Drawer
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "-2px 4px 6px 1px #f2f2f2",
            overflowX: "hidden",
            backgroundColor: "#FFFFFF !important",
            width: isSmallScreen ? '70%' : (isMiniAndFullScreen ? "104px" : "345px"),
            display: 'flex',
            left:"auto !important",
            justifyContent: 'space-between'
          }
        }}
        anchor='left'
        onClose={changeIsMenuOpen}
        open={isSmallScreen ? isMenuOpen : true}
        variant={isSmallScreen ? 'temporary' : 'permanent'}
    >
      <Box sx={{display: "flex", flexDirection: "column",justifyContent: "space-between"}}>
        <Box sx={{margin: isMiniAndFullScreen ? "auto" : "0 28px 0 28px"}}>
          <Box sx={{marginTop: "30px", display: isSmallScreen ? 'none' : "flex", justifyContent: isMiniNav ? "center" : "end",
            cursor: "pointer"}} onClick={changeMiniNavDir}>
            <Box>
              {!isMiniNav && <RightArrowIcon />}
              {isMiniNav && <LeftArrowIcon/>}
            </Box>
          </Box>
          <Box>
            <Box sx={{marginTop: "12px",
              display: isMiniNav || isSmallScreen ? "none" : "flex",
              justifyContent: "center"}}>
              <Box sx={{position: "relative"}}>
                <Button
                    variant="text"
                    component="label"
                    sx={{marginTop: "33px"}}
                >
              {loading ? <CircularProgress size={130} sx={{ color: "primary.main" }} />
              : currentUser && currentUser.photoUrl ? <img
                  width={130}
                  height={130}
                  src={currentUser.photoUrl}
                  loading="lazy"
              /> : <ImageSkeletonIcon/>}
                <input
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleFileUpload}
                      type="file"
                      hidden
                  />
                  <Box sx={{position: "absolute", top: "80%", right: "98px", cursor: "pointer"}}>
                    <EditIcon/>
                  </Box>
                </Button>
              </Box>
            </Box>
            <Typography color="primary.light"
                        sx={{marginTop: "26px",
                          marginBottom: "26px",
                          display: isMiniAndFullScreen ? "none" : "flex",
                          justifyContent: "center",
                          fontWeight: 600,
                          fontSize: "18px",
                          lineHeight: "24px"}}>{currentUser.name}</Typography>
          </Box>

          {(isSuperAdmin() || isCompanyAdmin()) && <NavLink onClick={changeIsMenuOpen} to={"/app/dashboard"} style={{textDecoration: "none", color: "black"}}>
            <SideNavItem isMiniNav={isMiniAndFullScreen} text="דשבורד" active={location.pathname.includes("dashboard")} icon={ <DashboardIcon/>} activeIcon={ <DashboardActiveIcon/>} />
          </NavLink> }

          <NavLink onClick={changeIsMenuOpen} to={"/app/clients"} style={{textDecoration: "none", color: "black"}}>
            <SideNavItem isMiniNav={isMiniAndFullScreen} text="לקוחות" active={location.pathname.includes("clients")} icon={ <PersonIcon/>} activeIcon={ <PersonIcon/>} />
          </NavLink>

          {(isSuperAdmin() || isCompanyAdmin()) && <NavLink onClick={changeIsMenuOpen} to={"/app/trainers"} style={{textDecoration: "none", color: "black"}}>
            <SideNavItem isMiniNav={isMiniAndFullScreen} text="מאמנים" active={location.pathname.includes("trainers")} icon={ <TrainersIcon/>} activeIcon={ <TrainersActiveIcon/>} />
          </NavLink>}

          {isSuperAdmin() &&
          <NavLink onClick={changeIsMenuOpen} to={"/app/templates"} style={{textDecoration: "none", color: "black"}}>
            <SideNavItem isMiniNav={isMiniAndFullScreen} text="תוכניות אימון" active={location.pathname.includes("templates")} icon={ <TemplatesIcon/>} activeIcon={ <TemplatesActiveIcon/>} />
          </NavLink>}

          <NavLink onClick={changeIsMenuOpen} to={"/app/program_renew"} style={{textDecoration: "none", color: "black"}}>
            <SideNavItem isMiniNav={isMiniAndFullScreen} text="עדכון תוכניות" active={location.pathname.includes("program_renew")} icon={penddingPrograms.length !== 0 ?  <ProgramRenewWithMessageIcon/> : <ProgramRenewIcon/>} activeIcon={ <ProgramRenewActiveIcon/>} />
          </NavLink>

          <NavLink onClick={changeIsMenuOpen} to={"/app/alerts"} style={{textDecoration: "none", color: "black"}}>
            <SideNavItem isMiniNav={isMiniAndFullScreen} text="התראות" active={location.pathname.includes("alerts")} icon={ notifications.length !== 0 ? <NotificationsWithMessageIcon/> : <NotificationsIcon/>} activeIcon={ <NotificationsActiveIcon/>} />
          </NavLink>

          {isSuperAdmin() &&
          <NavLink onClick={changeIsMenuOpen} to={"/app/exercises"} style={{textDecoration: "none", color: "black"}}>
            <SideNavItem isMiniNav={isMiniAndFullScreen} text="תרגילים" active={location.pathname.includes("exercises")} icon={ <ExercisesIcon/>} activeIcon={ <ExercisesActiveIcon/>} />
          </NavLink>}

          <Box sx={{display: isSuperAdmin() ? "block" : "none"}}>
          <NavLink onClick={changeIsMenuOpen} to={"/app/companies"} style={{textDecoration: "none", color: "black"}}>
            <SideNavItem isMiniNav={isMiniAndFullScreen} text="חברות" active={location.pathname.includes("companies")} icon={ <CompaniesIcon/>} activeIcon={ <CompaniesActiveIcon/>} />
          </NavLink>
          </Box>

          <NavLink onClick={changeIsMenuOpen} to={"/app/setting"} style={{textDecoration: "none", color: "black"}}>
            <SideNavItem isMiniNav={isMiniAndFullScreen} text="הגדרות" active={location.pathname.includes("setting")} icon={ <SettingIcon/>} activeIcon={ <SettingActiveIcon/>} />
          </NavLink>

          <NavLink onClick={disconnect} to={"/app/login"} style={{textDecoration: "none", color: "black"}}>
            <SideNavItem isMiniNav={isMiniAndFullScreen} text="התנתקות" active={location.pathname.includes("login")} icon={ <DisconnectIcon/>} activeIcon={ <DisconnectIcon/>} />
          </NavLink>

          <Box sx={{display: isSuperAdmin() ? "flex" : "none", justifyContent: "flex-start", marginTop: "25px"}}>
            <RTL>
          <FormControl sx={{ m: 1}}>
            {!isMiniNav && <InputLabel id="demo-select-small">שם חברה</InputLabel>}
            <Select
                variant={"outlined"}
                sx={{color: "#57837E",
                  height: isMiniNav ? "32px" : "56px",
                  minWidth: isMiniNav ? "40px" : "125px",
                  borderRadius: "8px",
                  backgroundColor: "#F2F4F7",
                  border: 0,
                }}
                value={company?.id}
                label={isMiniNav ? "" : "שם חברה"}
                onChange={e => changeCompany(e.target.value)}
            >
              <MenuItem value={0}>הכול
              </MenuItem>
              {getCompaniesList().map(cmp => {
                return <MenuItem key={cmp.id} value={cmp.id}>{cmp.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          </RTL>
          </Box>

        </Box>
      </Box>

      <Box sx={{mt: "10px", marginBottom: "26px", marginRight: "128px", marginLeft: "28px", display: "flex", alignItems: "center"}}>
      <LogoIcon/>
      </Box>
    </Drawer>
    </>);
};

export default SideNav;
