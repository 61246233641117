import {Tab, Tabs} from "@mui/material";
import FmdBadIcon from '@mui/icons-material/FmdBad';

const ClientTabs = ({hasNotes, setTabValue, tabValue}) => {

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
      <Tabs
          variant="fullWidth"
          value={tabValue}
          onChange={handleChange}
          sx={{"& .MuiTabs-indicator": {
              height: "5px",
              borderRadius: "4px"
            }}}
      >
        <Tab
            sx={{borderBottom: 5, color: "#A9ABB0", borderColor: "#E6E6E6", fontSize: "20px", fontWeight: 600, lineHeight: "25px"}}
            value="one"
            label="ראשי"
            wrapped
        >
        </Tab>
        <Tab

            sx={{borderBottom: 5, color: "#A9ABB0", borderColor: "#E6E6E6",fontSize: "20px", fontWeight: 600, lineHeight: "25px"}}
            value="two" label="פרופיל" />
        <Tab
            sx={{borderBottom: 5, color: hasNotes ? "#c94343" : "#A9ABB0", borderColor: "#E6E6E6",fontSize: "20px", fontWeight: 600, lineHeight: "25px"}}
            value="three" label="פתקים" iconPosition={"end"} icon={hasNotes? <FmdBadIcon/> : ''} >
        </Tab>

      </Tabs>
  );
};

export default ClientTabs;
