import Dialog from "../shared/Dialog";
import {Box, Button, MenuItem, TextField, Typography} from "@mui/material";
import SelectCom from "../SelectCom";
import {useEffect, useState} from "react";
import {targetMuscles} from "../../enums/TargetMuscles";
import {specificMuscles} from "../../enums/SpecificMuscle";
import {resistanceTypes} from "../../enums/resistanceTypes";
import {ReactComponent as ImageSkeletonIcon} from "../../assets/images/image-skelaton.svg";
import {ReactComponent as EditIcon} from "../../assets/images/editProfile.svg";
import * as Yup from "yup";
import {useFormik} from "formik";
import {saveExercise} from "../../services/ExcersiceService";
import {useMessageContext} from "../../contexts/MessageContext";
import {getErrorMessage} from "../../enums/ErrorMessages";

const ExerciseDialog = ({isOpen, close, exercise, setReload}) => {

  const [imageFile, setImageFile] = useState(null);

  const [coverFile, setCoverFile] = useState(null);

  const [skeletonFile, setSkeletonFile] = useState(null);

  const {notificationHandler} = useMessageContext();

  const schema = Yup.object().shape({
    name: Yup.string()
        .required('שדה חובה'),
    nameHeb: Yup.string()
        .required('שדה חובה'),
    description: Yup.string()
        .required('שדה חובה'),
    descriptionHeb: Yup.string()
        .required('שדה חובה'),
    targetMuscle: Yup.string()
        .test("Not null", "שדה חובה", (value) => value !== "null")
        .required('שדה חובה'),
    specificMuscle: Yup.string()
        .test("Not null", "שדה חובה", (value) => value !== "null")
        .required('שדה חובה'),
    resistanceType: Yup.string()
        .test("Not null", "שדה חובה", (value) => value !== "null")
        .required('שדה חובה'),
  });

  const formik = useFormik({
    initialValues: {
      id: exercise ? exercise.id : null,
      name: exercise ? exercise.name : "",
      nameHeb: exercise ? exercise.nameHeb : "",
      targetMuscle: exercise ? exercise.targetMuscle : "null",
      specificMuscle: exercise ? exercise.specificMuscle : "null",
      resistanceType: exercise ? exercise.resistanceType : "null",
      description: exercise ? exercise.description : "",
      descriptionHeb: exercise ? exercise.descriptionHeb : "",
      videoUrl: exercise ? exercise.videoUrl : "",
    },
    onSubmit: (values, {resetForm}) => {
      const res = {...values, imageUrl: imageFile, cover: coverFile, skeleton: skeletonFile}
      delete res.tmp;

      saveExercise(JSON.stringify(res), imageFile, coverFile, skeletonFile)
          .then(setReload)
          .then(() => notificationHandler.success("תרגיל נשמר בהצלחה"))
          .then(handleClose)
          .catch(e => {
            console.log(e);
            notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
            formik.setFieldError("tmp","");
          });

      resetForm({values: ''});

    },
    validationSchema: schema});

  useEffect(() => {
    if (exercise?.imageUrl) {
      setImageFile(exercise.imageUrl)
    } else {
      setImageFile(null);
    }

    if (exercise?.coverUrl) {
      setCoverFile(exercise.coverUrl);
    } else  {
      setCoverFile(null);
    }

    if (exercise?.skeletonUrl) {
      setSkeletonFile(exercise.skeletonUrl);
    } else {
      setSkeletonFile(null);
    }
  }, [isOpen])


  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setImageFile(URL.createObjectURL(file));
    formik.setFieldValue("tmp", Math.random());
  }

  const handleCoverUpload = (e) => {
    const file = e.target.files[0];
    setCoverFile(URL.createObjectURL(file));
    formik.setFieldValue("tmp", Math.random());
  }

  const handleSkeletonUpload = (e) => {
    const file = e.target.files[0];
    setSkeletonFile(URL.createObjectURL(file));
    formik.setFieldValue("tmp", Math.random());
  }

  const handleClose = () => {
    formik.resetForm();
    close();
  }

  return (
      <Dialog title={"שם התרגיל"} close={handleClose} isOpen={isOpen}>
        <form onSubmit={formik.handleSubmit}>
        <Box sx={{display: "flex", alignItems: "top", justifyContent: "space-between", paddingLeft: "80px",
          paddingRight: "80px", paddingTop: "40px"}}>
          <Box>
            <Typography>
              *שם:
            </Typography>
            <TextField
                sx={{marginTop: "8px", minWidth: "280px"}}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.nameHeb}
                onChange={formik.handleChange}
                id="nameHeb"
                variant="outlined"
            />
            {formik.errors.nameHeb && formik.touched.nameHeb && <Typography sx={{color: "#F16063"}}>{formik.errors.nameHeb}</Typography>}
            <Typography sx={{marginTop: "24px"}}>
              *שם באנגלית:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.name}
                onChange={formik.handleChange}
                id="name"
                variant="outlined"
            />
            {formik.errors.name && formik.touched.name && <Typography sx={{color: "#F16063"}}>{formik.errors.name}</Typography>}
            <Box sx={{marginTop: "36px"}}>
            <SelectCom
                id={"targetMuscle"}
                width={"280px"}
                mainItem={"לא נבחר"}
                label={"*שריר"}
                contrast={"black"}
                marginLeft="8px"
                onBlur={formik.handleBlur("targetMuscle")}
                value={formik.values.targetMuscle}
                onChangeReal={formik.handleChange("targetMuscle")}
                defaultValue={"null"}>
              {targetMuscles.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
              {formik.errors.targetMuscle && formik.touched.targetMuscle && <Typography sx={{color: "#F16063"}}>{formik.errors.targetMuscle}</Typography>}
            </Box>
            <Box sx={{marginTop: "36px"}}>
            <SelectCom
                id={"specificMuscle"}
                width={"280px"}
                mainItem={"לא נבחר"}
                label={"*שריר ספציפי"}
                onBlur={formik.handleBlur("specificMuscle")}
                value={formik.values.specificMuscle}
                onChangeReal={formik.handleChange("specificMuscle")}
                contrast={"black"}
                marginLeft="8px"
                defaultValue={"null"}>
              {specificMuscles.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
              {formik.errors.specificMuscle && formik.touched.specificMuscle && <Typography sx={{color: "#F16063"}}>{formik.errors.specificMuscle}</Typography>}
            </Box>

            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography>תמונת שלד:</Typography>
              <Box sx={{position: "relative", marginRight: "80px"}}>
                <Button
                    variant="text"
                    component="label"
                    sx={{marginTop: "33px"}}
                >
                  {skeletonFile ? <img
                      width={116}
                      height={116}
                      src={skeletonFile}
                      loading="eager"
                  /> :  <ImageSkeletonIcon/>}
                  <input
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleSkeletonUpload}
                      type="file"
                      hidden
                  />
                  <Box sx={{position: "absolute", top: "80%", right: "98px", cursor: "pointer"}}>
                    <EditIcon/>
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{marginRight: "80px", marginTop: "30px"}}>
            <SelectCom
                id={"resistanceType"}
                width={"280px"}
                height={"56px"}
                mainItem={"לא נבחר"}
                label={"*סוג התנגדות"}
                contrast={"black"}
                marginLeft="8px"
                onBlur={formik.handleBlur("resistanceType")}
                value={formik.values.resistanceType}
                onChangeReal={formik.handleChange("resistanceType")}
                defaultValue={"null"}>
              {resistanceTypes.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            {formik.errors.resistanceType && formik.touched.resistanceType && <Typography sx={{color: "#F16063"}}>{formik.errors.resistanceType}</Typography>}
            <Typography sx={{marginTop: "24px"}}>
              *תיאור:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                multiline
                minRows={4}
                onBlur={formik.handleBlur}
                value={formik.values.descriptionHeb}
                onChange={formik.handleChange}
                id="descriptionHeb"
                variant="outlined"
            />
            {formik.errors.descriptionHeb && formik.touched.descriptionHeb && <Typography sx={{color: "#F16063"}}>{formik.errors.descriptionHeb}</Typography>}
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography>תמונה:</Typography>
              <Box sx={{position: "relative", marginRight: "80px"}}>
                <Button
                    variant="text"
                    component="label"
                    sx={{marginTop: "33px"}}
                >
                  {imageFile ? <img
                      width={116}
                      height={116}
                      src={imageFile}
                      loading="eager"
                  /> :  <ImageSkeletonIcon/>}
                  <input
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleFileUpload}
                      type="file"
                      hidden
                  />
                  <Box sx={{position: "absolute", top: "80%", right: "98px", cursor: "pointer"}}>
                    <EditIcon/>
                  </Box>
                </Button>
              </Box>
            </Box>

          </Box>
          <Box sx={{marginRight: "80px", paddingLeft: "40px"}}>
            <Typography>
              לינק URL לסרטון:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px", minWidth: "280px"}}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.videoUrl}
                onChange={formik.handleChange}
                id="videoUrl"
                variant="outlined"
            />
            <Typography>
              *תיאור באנגלית:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                multiline
                minRows={4}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                onChange={formik.handleChange}
                id="description"
                variant="outlined"
            />
            {formik.errors.description && formik.touched.description && <Typography sx={{color: "#F16063"}}>{formik.errors.description}</Typography>}

            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography>תמונת כיסוי:</Typography>
              <Box sx={{position: "relative", marginRight: "80px"}}>
                <Button
                    variant="text"
                    component="label"
                    sx={{marginTop: "33px"}}
                >
                  {coverFile ? <img
                      width={116}
                      height={116}
                      src={coverFile}
                      loading="eager"
                  /> :  <ImageSkeletonIcon/>}
                  <input
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleCoverUpload}
                      type="file"
                      hidden
                  />
                  <Box sx={{position: "absolute", top: "80%", right: "98px", cursor: "pointer"}}>
                    <EditIcon/>
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{marginTop: "40px", paddingBottom: "40px", textAlign: "center"}}>
          <Button
              onClick={handleClose}
              sx={{marginRight: "24px", backgroundColor: "#F1F3F5", color: "black"}}
              variant={"contained"}>
            בטל
          </Button>
          <Button
              disabled={!formik.dirty || !formik.isValid}
              type={"submit"}
              sx={{marginRight: "24px"}}
              variant={"contained"}>
            שמור
          </Button>
        </Box>
        </form>
      </Dialog>
  );
};

export default ExerciseDialog;
