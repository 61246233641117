import {Backdrop, CircularProgress} from "@mui/material";

const Loader = ({loading}) => {
  return (
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10000 }}
          open={loading}
      >
        <CircularProgress size={150} sx={{ color: "primary.main" }} />
      </Backdrop>
  );
}

export default Loader;
