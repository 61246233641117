import {
  Box,
  Button, Checkbox,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import PageHeader from "../componenets/shared/PageHeader";
import {useEffect, useState} from "react";
import {approvePendingProgram, getPendingPrograms} from "../services/PlanSerice";
import {useCompany} from "../contexts/CompanyContext";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";
import {ReactComponent as SearchIcon} from "../assets/images/search.svg";
import {ReactComponent as GreenArrow} from "../assets/images/green-arrow-down.svg";
import {ReactComponent as RedArrow} from "../assets/images/red-arrow-up.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Loader from "../componenets/Loader";

const ProgramRenew = () => {

  const [pendingPrograms, setPendingPrograms] = useState({
    pendingPrograms: [],
    hasNext: false,
    total: 0
  });
  const {notificationHandler} = useMessageContext();
  const [keyword, setKeyword] = useState(null);
  const [reload, setReload] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);

  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const {company} = useCompany();

  useEffect(() => {
    setLoading(true)
    getPendingPrograms(company?.id, keyword, page)
        .then(res => {
          setPendingPrograms(res.data)
        }).catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
        .finally(() => setLoading(false));
  },[reload, keyword, page])

  const addOrRemoveClient = (client) => {
    if (selectedClients.some(item => item.id === client.id)){
      setSelectedClients(prv => {
        return prv.filter((item) => item.id !== client.id);
      });
    } else {
      setSelectedClients(prv => {
        let newList = [...prv];
        newList.push(client);
        return newList;
      })
    }
  }

  const handleOnClick = (program) => {
    if (program.finished){
      navigate(`/app/clients/${program.traineeId}`)
    } else {
      navigate(`/app/clients/${program.traineeId}/week/${program.blockIndex}/${program.weekIndex}`)
    }
  }

  const approveProgram = (pendingId) => {
    approvePendingProgram(pendingId)
        .then(() => setReload(prv => !prv))
        .then(() => notificationHandler.success("תוכנית אושרה בהצלחה"))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
  }

  return (
      <>
        <Loader loading={loading} />
      <PageHeader title={"עדכון תוכנית"} />
  <Box sx={{marginLeft: "40px", marginRight: "40px"}}>
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "right"}}>
      <Box sx={{backgroundColor: "#F9FAFB",
        marginTop: "20px",
        border: "1px solid #A9ABB0",
        borderRadius: "8px",
        maxHeight: "41px",
        marginRight: "8px",
        width: "auto",
        display: "inline-flex",
      }}>
        <IconButton disabled={true} sx={{ p: '12px', marginLeft: "6px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
            onChange={(newValue) => setKeyword(newValue.target.value)}
            value={keyword}
            sx={{ ml: 1, flex: 1 }}
            placeholder="חיפוש לפי שם"
        />
      </Box>
      <Box>
        <Button
            id="basic-button"
            disabled={selectedClients.length === 0}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            sx={{"& .MuiButton-endIcon" : {
                marginLeft: "-6px",
                marginTop: "6px",
                borderRadius: "8px",
                marginRight: "8px",
              }, "&.MuiButton-containedSizeMedium" : {
                padding: "8px",
                paddingLeft: "16px",
                paddingRight: "16px"
              }}}
            variant={"contained"}
            aria-expanded={open ? 'true' : undefined}
            // onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon/>}
        >
          פעולות
        </Button>
      </Box>
    </Box>
    <TableContainer>
      <Table sx={{"&.MuiTable-root" : {
          borderCollapse: "separate",
          borderSpacing: "0 15px",
        }}}>
        <TableHead>
          <TableRow>
            <TableCell align="right">
              לקוחות ({pendingPrograms.total})
            </TableCell>
            <TableCell align="right">
              שם מלא
            </TableCell>
            <TableCell align="center">
             מגדר
            </TableCell>
            <TableCell align="center">
              שם תוכנית
            </TableCell>
            <TableCell align="center">
              מגמת שקילות
            </TableCell>
            <TableCell align="center">
              תאריך בקשה
            </TableCell>
            <TableCell align="center">
              מס שבוע
            </TableCell>
            <TableCell align="left">
              יצירה ועדכון בלוק
            </TableCell>
            <TableCell align="left">
              יצירת בלוק
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pendingPrograms.pendingPrograms
              .sort((a, b) => a.createdDate > b.createdDate ? -1 : 1)
              .map((program, i)=> {
            return <TableRow key={i}
                             onClick={() => navigate(`/app/clients/${program.traineeId}`)}
                             sx={{"&.MuiTableRow-root" : {
                                 border: "1px solid #E7E9EE",
                                 height: "100px",
                                 backgroundColor: "#F9FAFB",
                                 cursor: "pointer",
                                 "&:hover" : {
                                   backgroundColor: "rgba(185,217,213,0.41)"
                                 },
                                 boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06)"
                               }}}>
              <TableCell  align="right">
                <Checkbox checked={selectedClients.some(item => item.id === program.traineeId)} onClick={(e) => {
                  e.stopPropagation();
                  addOrRemoveClient({
                    id: program.traineeId,
                    name: program.traineeName
                  });
                }}  />
              </TableCell>
              <TableCell align="right">
                {program.traineeName}
              </TableCell>
              <TableCell align="center">
                {program.traineeGender === "FEMALE" && "נקבה"}
                {program.traineeGender === "MALE" && "זכר"}
                {!program.traineeGender && "אין מידע"}
              </TableCell>
              <TableCell>
                <Typography  align="center">
                  {program.programName} {program.finished ? <Typography color={"red"}>(סיים תוכנית) </Typography> : ''}
                </Typography>
              </TableCell>
              <TableCell align="center">
                {program.progress} {program.progress > 0 ? <GreenArrow/> : <RedArrow/>}
              </TableCell>
              <TableCell align="center">
                {program.createdDate ? moment(moment.unix(program.createdDate)).format("DD/MM/YY") : 'אין מידע'}
              </TableCell>
              <TableCell align="center">
                {program.totalWeeks} / {program.finished ? program.totalWeeks : program.weekIndex +1 }
              </TableCell>
              <TableCell align="left" width={"10%"}>
                <Button
                    onClick={handleOnClick}
                    sx={{backgroundColor: "#F1F3F5", color: "#27272E"}} variant={"contained"}>
                  יצירה ועדכון
                </Button>
              </TableCell>
              <TableCell align="left" width={"10%"}>
                <Button variant={"contained"} onClick={() => program.finished ? handleOnClick(program) : approveProgram(program.id)}>
                  יצירה
                </Button>
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <Box sx={{mb: "24px", display: "flex", justifyContent: "center", alignItems: "center"}}>
    <Button sx={{ml: "8px"}} onClick={() => setPage(prv => prv-1)} disabled={page < 1} variant={"contained"}>הקודם</Button>
    <Typography sx={{ml: "8px"}}> עמוד: {page}</Typography>
    <Button  onClick={() => setPage(prv => prv+1)} disabled={!pendingPrograms.hasNext} variant={"contained"}>הבא</Button>
    </Box>
  </Box>
      </>
  );
};

export default ProgramRenew;
