import {Checkbox, TableCell, TableRow, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ReactComponent as ActiveIcon} from "../assets/images/active.svg";
import {ReactComponent as InactiveIcon} from "../assets/images/inactive.svg";
import moment from "moment";

const ClientCard = ({addOrRemoveClient, selectedClients, client}) => {
  let navigate = useNavigate();
  return (
  <TableRow
      onClick={(e) => {
        navigate(`/app/clients/${client.id}`)
      }}
      sx={{"&.MuiTableRow-root" : {
      border: "1px solid #E7E9EE",
      height: "100px",
      backgroundColor: "#F9FAFB",
      cursor: "pointer",
      "&:hover" : {
        backgroundColor: "rgba(185,217,213,0.41)"
      },
      boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06)"
    }}}>
    <TableCell align="right">
      <Checkbox checked={selectedClients.some(item => item.id === client.id)} onClick={(e) => {
        e.stopPropagation();
        addOrRemoveClient({...client});
        }}  />
    </TableCell>
    <TableCell align="center">
      <Typography sx={{
        fontsize: "18px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#4F535C"
      }}>
        {client.name}
      </Typography>
    </TableCell>
    <TableCell align="center">
      <Typography sx={{
        fontsize: "18px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#4F535C"
      }}>
      {client.dateOfBirth ? moment().year() - moment(moment.unix(client.dateOfBirth)).year() : "אין מידע"}
      </Typography>
    </TableCell>
    <TableCell align="center">
      <Typography sx={{
        fontsize: "18px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#4F535C"
      }}>
      {client.gender === "FEMALE" && "נקבה"}
      {client.gender === "MALE" && "זכר"}
      {!client.gender && "אין מידע"}
      </Typography>
    </TableCell>
    <TableCell align="center">
      <Typography sx={{
        fontsize: "18px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#4F535C"
      }}>
      {moment(moment.unix(client.createdDate)).format("DD/MM/YY")}
      </Typography>
    </TableCell>
    <TableCell align="center">
      <Typography sx={{
        fontsize: "18px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#4F535C"
      }}>
      {client.expirationDate ? moment(moment.unix(client.expirationDate)).format("DD/MM/YY") : 'אין מידע'}
      </Typography>
      </TableCell>
    <TableCell align="left">
      {client.enabled ? <ActiveIcon/> : <InactiveIcon/>}
    </TableCell>
  </TableRow>
  );
};

export default ClientCard;
