import {Box, Button, TextField, Typography} from "@mui/material";
import {useState} from "react";
import moment from "moment";

const NewClientStep3 = ({trainee, updateField, prvStep, handleSubmit}) => {

  const [secondPassword, setSecondPassword] = useState("");

  return (<Box>
    <Box
        sx={{
          display: "flex",
          alignItems: "top",
          justifyContent: "space-between",
          paddingTop: "40px",
          paddingLeft: "100px",
          paddingRight: "100px"
        }}>
      <Box sx={{width: "320px"}}>
        <Typography sx={{marginBottom: "8px",
          fontSize: "18px",
          fontWeight: 600,
          lineHeight: "24px",
          color: "#1E1E1E"
        }}>
          יצירת סיסמה
        </Typography>
        <Typography sx={{marginBottom: "8px",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "23px",
          color: "#4F535C"
        }}>
          הסיסמה צריכה להיות בין 8-16 תווים
        </Typography>
        <Typography sx={{marginTop: "24px", marginBottom: "8px"}}>
          *בחר סיסמה:
        </Typography>
        <TextField
            disabled={trainee.existingUser !== "null"}
            sx={{marginTop: "8px", marginBottom: "20px"}}
            fullWidth
            value={trainee.password}
            onChange={(e) => updateField(e.target.value, "password")}
            id="password"
            type="password"
            variant="outlined"
        />
        <Typography sx={{marginTop: "24px", marginBottom: "8px"}}>
          *סיסמה בשנית:
        </Typography>
        <TextField
            disabled={trainee.existingUser !== "null"}
            sx={{marginTop: "8px", marginBottom: "20px"}}
            fullWidth
            value={secondPassword}
            onChange={(e) => setSecondPassword(e.target.value)}
            id="phoneNumber"
            type="password"
            variant="outlined"
        />
      </Box>
      <Box sx={{marginRight: "80px", width: "320px"}}>
        <Typography sx={{marginBottom: "8px",
          fontSize: "18px",
          fontWeight: 600,
          lineHeight: "24px",
          color: "#1E1E1E"
        }}>
          {trainee.name}</Typography>
        <Typography sx={{marginBottom: "8px",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "23px",
          color: "#4F535C"
        }}>
          גיל:       {moment().year() - moment(trainee.dateOfBirth?.$d)?.year()}
         </Typography>
        <Typography sx={{marginBottom: "8px",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "23px",
          color: "#4F535C"
        }}>
          מייל:       {trainee.email}
        </Typography>
        <Typography sx={{marginBottom: "8px",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "23px",
          color: "#4F535C"
        }}>
          טלפון:       {trainee.phoneNumber}
        </Typography>
      </Box>
    </Box>
    <Box sx={{marginTop: "80px", paddingBottom: "40px", textAlign: "center"}}>
      <Button
          onClick={prvStep}
          sx={{marginRight: "24px", backgroundColor: "#F1F3F5", color: "black"}}
          variant={"contained"}>
        קודם
      </Button>
      <Button
          disabled={(trainee.existingUser === "null") && (trainee.password !== secondPassword || trainee.password === "")}
          onClick={handleSubmit}
          sx={{marginRight: "24px"}}
          variant={"contained"}>
        יצירת משתמש חדש
      </Button>
    </Box>
  </Box>);
};

export default NewClientStep3;
