import Dialog from "../shared/Dialog";
import {
  Box, Button, Chip, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography
} from "@mui/material";
import {RTL} from "../../cache/CacheRtl";
import {useEffect, useState} from "react";
import {sendEmail, sendNotification} from "../../services/MessageService";
import {getErrorMessage} from "../../enums/ErrorMessages";
import {useMessageContext} from "../../contexts/MessageContext";

const MessageDialog = ({isOpen, close, setSelectedClients, selectedClients}) => {
  const [usersToSend, setUsersToSend] = useState([]);
  const [messageType, setMessageType] = useState("EMAIL")
  const {notificationHandler} = useMessageContext();

  useEffect(() => {
    setUsersToSend([...selectedClients])
  }, [isOpen])

  const deleteUser = (userId) => {
    if (usersToSend.length === 1){
      close();
    } else {
    setUsersToSend(prv =>
      prv.filter((user) => user.id !== userId)
    )}
  };

  const submitMessage = (event) => {
    event.stopPropagation();
    const {subject, content} = document.forms[0];

    const body = {
      subject: subject.value,
      content: content.value,
      ids: usersToSend.map(user => {
        return user.id
      })
    }

    if (messageType === "EMAIL") {
      sendEmail(body)
          .then(() => {
            setSelectedClients([]);
            close();
          }).then(() => notificationHandler.success("הודעה נשלחה בהצלחה"))
          .catch(e => {
            notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
          })

    } else {
      sendNotification(body)
          .then(() => {
            setSelectedClients([]);
            close();
          }).then(() => notificationHandler.success("הודעה נשלחה בהצלחה"))
          .catch(e => {
            notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
          })
    }

    subject.value = "";
    content.value = "";
  }

  return (
      <Dialog title={"הודעה חדשה"} close={close} isOpen={isOpen}>
        <Box component={"form"} sx={{ marginRight: "100px"}}>
          <Typography sx={{
            marginTop: "80px",
            fontSize: "14",
            fontWeight: 600,
            lineHeight: "24px"
          }}>נמענים</Typography>
          <RTL>
          <Stack direction="row" spacing={1} sx={{marginTop: "8px"}}>
            {usersToSend.map((user) => {
              return <Chip
                  sx={{
                boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
                borderRadius: "8px",
                border: "1px solid #E7E9EE", backgroundColor: "#FCFCFD"}} label={user.name} onDelete={() => deleteUser(user.id)} />
            })}
          </Stack>
            <Box sx={{marginTop: "32px"}}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">סוג הודעה</InputLabel>
              <Select
                  sx={{minWidth: "280px"}}
                  labelId="demo-simple-select-label"
                  id="type"
                  label="סוג הודעה"
                  onChange={e => setMessageType(e.target.value)}
                  value={messageType}
              >
                <MenuItem value={"NOTIFICATION"}>התראה</MenuItem>
                <MenuItem value={"EMAIL"}>הודעת E-Mail</MenuItem>
                <MenuItem disabled value={"SMS"}>הודעת SMS</MenuItem>
              </Select>
            </FormControl>
            </Box>
            <TextField multiline
                       id={"subject"}
                       sx={{marginTop: "20px", minWidth: "550px", minHeight: "145p"}}
                       label="נושא"></TextField>
            <Box sx={{paddingRight: "104px", marginTop: "28px"}}>
              <TextField multiline
                         id={"content"}
                         sx={{minWidth: "550px", minHeight: "145p"}}
                         minRows={6}
                         label="תוכן ההודעה"
                         placeholder={"הודעה"}></TextField>
            </Box>
          </RTL>
        </Box>
        <Box sx={{marginTop: "80px", paddingBottom: "40px", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Button variant={"outlined"} onClick={close}>
            בטל
          </Button>
          <Button
              type={"submit"}
              onClick={submitMessage}
              sx={{marginRight: "24px"}}
              variant={"contained"}>
            שליחה
          </Button>
        </Box>
      </Dialog>
  );
};

export default MessageDialog;
