import {TableCell, TableRow} from "@mui/material";
import {useState} from "react";
import ExerciseDialog from "./ExerciseDialog";
import {saveExercise} from "../../services/ExcersiceService";
import Loader from "../Loader";
import {targetMuscles} from "../../enums/TargetMuscles";
import {resistanceTypes} from "../../enums/resistanceTypes";
import {specificMuscles} from "../../enums/SpecificMuscle";
import {getErrorMessage} from "../../enums/ErrorMessages";
import {useMessageContext} from "../../contexts/MessageContext";

const ExerciseCard = ({exercise, reload}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
      <TableRow
          onClick={handleOpen}
          sx={{"&.MuiTableRow-root" : {
              border: "1px solid #E7E9EE",
              height: "100px",
              backgroundColor: "#F9FAFB",
              cursor: "pointer",
              "&:hover" : {
                backgroundColor: "rgba(185,217,213,0.41)"
              },
              boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06)"
            }}}>
        <TableCell align="right">
          {exercise && <ExerciseDialog setReload={reload} exercise={exercise} close={handleClose} isOpen={isOpen}/>}
          {exercise.nameHeb}
        </TableCell>
        <TableCell align="right">
          {targetMuscles.find(tm => tm.value === exercise.targetMuscle)?.name}
        </TableCell>
        <TableCell align="right">
          {specificMuscles.find(x => x.value === exercise.specificMuscle)?.name}
        </TableCell>
        <TableCell align="right">
          {resistanceTypes.find(rt => rt.value === exercise.resistanceType)?.name }
        </TableCell>
        <TableCell align={"left"}>
          <img
              width={64}
              height={64}
              src={exercise.imageUrl}
              loading="lazy"
          />
        </TableCell>
      </TableRow>
  );
};

export default ExerciseCard;
