export const EquipmentTypes = [
  {
    name: "חדר כושר מסחרי",
    value: "GYM"
  },
  {
    name: "חדר כושר קטן",
    value: "SMALL_GYM"
  },
  {
    name: "סטודיו",
    value: "STUDIO"
  },
  {
    name: "חדר כושר בייתי",
    value: "HOME_GYM"
  },
];
