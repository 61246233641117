import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

const MyDialog = ({children, title, isOpen, close, actions}) => {

  return (
      <Dialog open={isOpen} onClose={close} sx={{"& .MuiDialog-paper": {minWidth: "70%"}}}>
        <DialogTitle sx={{backgroundColor: "#57837E",
          fontWeight: 600,
          fontSize: "28px",
          lineHeight: "136.02%",
          color: "white"
        }}>{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    // <Backdrop
    //     onClick={close}
    //     open={isOpen} sx={{zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    //   <Card sx={{minWidth: "618px"}} onClick={e => e.stopPropagation()}>
    //     <CardContent sx={{backgroundColor: "#57837E"}}>
    //       <Box sx={{display: "flex", alignItems: "center",
    //         justifyContent: "space-between",
    //         marginLeft: "28px",}}>
    //         <Typography sx={{marginLeft: "180px",
    //           fontWeight: 600,
    //           fontSize: "28px",
    //           lineHeight: "136.02%",
    //           color: "white"
    //         }}>{title}</Typography>
    //         <Box sx={{cursor: "pointer"}} onClick={close}>
    //           <CloseIcon/>
    //         </Box>
    //       </Box>
    //     </CardContent>
    //     {children}
    //   </Card>
    // </Backdrop>
  );
};

export default MyDialog;
