import {Box, Divider, Paper, Typography} from "@mui/material";
import {ReactComponent as EditIcon} from "../../assets/images/editProfile.svg";
import {ReactComponent as DeleteIcon} from "../../assets/images/delete-round.svg";
import {useState} from "react";
import NoteDialog from "./NoteDialog";
import moment from "moment";
import {deleteNote, updateClientNote} from "../../services/TraineeService";
import {getErrorMessage} from "../../enums/ErrorMessages";
import {useMessageContext} from "../../contexts/MessageContext";

const ClientNote = ({note, reload}) => {
  const [editDialog, setEditDialog] = useState(false);

  const {notificationHandler} = useMessageContext();

  const handleDeleteNote = () => {
    deleteNote(note.id)
        .then(reload)
        .then(() => setEditDialog(false))
        .then(() => notificationHandler.success("פתק נמחק בהצלחה"))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
        })
  }

  const handleUpdateNote = (newNote) => {
    const body = {
      id: newNote.id,
      message: newNote.message,
    };

    updateClientNote(body)
        .then(reload)
        .then(() => setEditDialog(false))
        .then(() => notificationHandler.success("פתק נשמק בהצלחה"))
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
        })
  }

  return (
      <>
        <NoteDialog approve={handleUpdateNote} initValue={note} close={() => setEditDialog(false)} isOpen={editDialog} />
        {note && <Paper sx={{marginTop: "24px", backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7",
        flexGrow: 0,
        borderRadius: "4px"}}>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Box sx={{display: "flex", alignItems: "center", marginRight: "8px"}}>
            <Typography sx={{fontWeight: 400,
              fontSize: "20px",
              lineHeight: "27.3px",
              color: "#4F535C",
              padding: "10px"}}>{moment(moment.unix(note.createdDate)).format("DD/MM/YY")}</Typography>
          </Box>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Box sx={{marginLeft: "8px", cursor: "pointer"}} onClick={() => setEditDialog(true)}>
              <EditIcon/>
            </Box>
            <Box
                onClick={handleDeleteNote}
                sx={{marginLeft: "8px", cursor: "pointer"}}>
              <DeleteIcon/>
            </Box>
          </Box>
        </Box>
        <Divider/>
        <Box>
          <Typography sx={{
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "27.3px",
            color: "#4F535C",
            paddingTop: "40px",
            paddingBottom: "40px",
            paddingLeft: "80px",
            paddingRight: "80px",
          }}>{note.message}</Typography>
        </Box>
      </Paper>}
      </>
  );
};

export default ClientNote;
