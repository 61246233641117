import {useEffect, useState} from "react";
import Dialog from "../shared/Dialog";
import {Box, Button, MenuItem, Switch, TextField, Typography} from "@mui/material";
import SelectCom from "../SelectCom";
import {getUsersWithoutRoles} from "../../services/UserService";

const AddOwnerDialog = ({open, close, owner, companyId, handleSubmit}) => {

  const [exsistingUser, setExsistingUser] = useState(!owner);

  const [avilibleUsers, setAvilibleUsers] = useState([]);

  useEffect(() => {
    getUsersWithoutRoles("SYSTEM_ADMIN", companyId).then(res => {
      setAvilibleUsers(res.data);
    })
  }, []);

  const [companyOwner, setCompanyOwner] = useState({
    id: owner ? owner.id : null,
    companyId: companyId,
    name: owner ? owner.name : "",
    email: owner ? owner.email : "",
    phoneNumber: owner ? owner.phoneNumber : "",
    userId: "null",
    password: "",
    secondPassword: "",
  });

  const updateName = (name) => {
    setCompanyOwner(prv => {
      return {
        ...prv,
        name: name
      }
    })
  }

  const updateEmail = (email) => {
    setCompanyOwner(prv => {
      return {
        ...prv,
        email: email
      }
    })
  }

  const updatePhoneNumber = (phoneNumber) => {
    setCompanyOwner(prv => {
      return {
        ...prv,
        phoneNumber: phoneNumber
      }
    })
  }

  const updateUserId = (userId) => {
    setCompanyOwner(prv => {
      return {
        ...prv,
        userId: userId
      }
    })
  }

  const updatePassword = (password) => {
    setCompanyOwner(prv => {
      return {
        ...prv,
        password: password
      }
    })
  }

  const updateSecondPassword = (secondPassword) => {
    setCompanyOwner(prv => {
      return {
        ...prv,
        secondPassword: secondPassword
      }
    })
  }

  return (
      <Dialog title={"הוסף מנהל"}
              isOpen={open}
              close={close}>

        <Box sx={{display: companyOwner.id ? "none" :  "flex", alignItems: "center", mt: "10px", mr: "15px" }}>
        <Typography>משתמש קיים:</Typography>
        <Switch checked={exsistingUser} onChange={() => setExsistingUser(prv => !prv)}/>
        </Box>

        {!exsistingUser &&
        <Box sx={{display: "flex", alignItems: "top", justifyContent: "space-between", padding: "40px"}}>
          <Box>
            <Typography>
              *שם מלא:
            </Typography>
            <TextField
                sx={{marginTop: "8px"}}
                fullWidth
                value={companyOwner.name}
                onChange={(e) => updateName(e.target.value)}
                id="nameHeb"
                variant="outlined"
            />
            <Typography sx={{marginTop: "24px"}}>
              *טלפון:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                value={companyOwner.phoneNumber}
                onChange={(e) => updatePhoneNumber(e.target.value)}
                id="name"
                variant="outlined"
            />
            <Typography sx={{marginTop: "24px"}}>
              *אימייל:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                value={companyOwner.email}
                onChange={(e) => updateEmail(e.target.value)}
                id="name"
                variant="outlined"
            />
          </Box>

          <Box sx={{marginRight: "80px"}}>
            <Typography sx={{marginBottom: "8px"}}>
              *סיסמה:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                type={"password"}
                value={companyOwner.password}
                onChange={(e) => updatePassword(e.target.value)}
                id="name"
                variant="outlined"
            />

            <Typography sx={{marginBottom: "8px"}}>
              *סיסמה בשנית:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                type={"password"}
                value={companyOwner.secondPassword}
                onChange={(e) => updateSecondPassword(e.target.value)}
                id="name"
                variant="outlined"
            />
          </Box>
        </Box>}
        {exsistingUser && avilibleUsers &&
            <Box sx={{mb: "20px", mt: "20px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <SelectCom
                id={"user"}
                width={"200px"}
                mainItem={"לא נבחר"}
                label={"משתמש"}
                contrast={"black"}
                marginLeft="8px"
                value={companyOwner.userId}
                onChange={updateUserId}
                defaultValue={"null"}>
              {avilibleUsers.map((res, i) => {
                return <MenuItem key={i} value={res.id} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            </Box>
        }

        <Box sx={{paddingBottom: "40px", textAlign: "center"}}>
          <Button
              onClick={close}
              sx={{marginRight: "24px", backgroundColor: "#F1F3F5", color: "black"}}
              variant={"contained"}>
            בטל
          </Button>
          <Button
              disabled={companyOwner.name === "" ||
                        companyOwner.email === "" ||
                        companyOwner.phoneNumber === "" ||
                        (companyOwner.password !== "" && companyOwner.password !== companyOwner.secondPassword)}
              onClick={() => handleSubmit({...companyOwner,
                password: companyOwner.password === "" ? null : companyOwner.password,
                userId: companyOwner.userId === "null" ? null : companyOwner.userId
              })}
              sx={{marginRight: "24px"}}
              variant={"contained"}>
            שמור
          </Button>
        </Box>
      </Dialog>
  );

};



export default AddOwnerDialog;
