import Dialog from "../shared/Dialog";
import {useEffect, useState} from "react";
import {Box, Button, TextField, Typography} from "@mui/material";
import * as Yup from 'yup';

import {ReactComponent as CompanySkeleton} from "../../assets/images/company_image_skeleton.svg";
import {useFormik} from "formik";
import {saveCompany} from "../../services/CompanyService";
import {useCompany} from "../../contexts/CompanyContext";
import {useMessageContext} from "../../contexts/MessageContext";
import {getErrorMessage} from "../../enums/ErrorMessages";

const AddCompanyDialog = ({open, close, company, setReload}) => {
  const [imageFile, setImageFile] = useState(null);
  const {notificationHandler} = useMessageContext();
  const {updateCompanies} = useCompany();

  const schema = Yup.object().shape({
    name: Yup.string()
        .required('שדה חובה'),
    email: Yup.string().email('אימייל לא נכון').required('שדה חובה'),
    phoneNumber: Yup.string().required('שדה חובה'),
    address: Yup.string().required('שדה חובה'),
  });

  const formik = useFormik({
  initialValues: {
    id: company ? company.id : null,
    name: company ? company.name : '',
    phoneNumber: company ? company.phoneNumber : '',
    email: company ? company.email : '',
    address: company ? company.address : '',
  },
  onSubmit: (values, {resetForm}) => {
    const res = {...values, imageUrl: imageFile}
    delete res.tmp;

    saveCompany(JSON.stringify(res), imageFile)
        .then(() => notificationHandler.success('חברה עודכנה בהצלחה'))
        .then(() => setReload(prv => !prv))
        .then(handleClose)
        .then(updateCompanies)
        .catch(e => {
          notificationHandler.error(getErrorMessage(e.response.data?.errorCode));
          formik.setFieldError("tmp","");
        });

  },
  validationSchema: schema
  });


  const handleClose = () => {
    formik.resetForm();
    close();
  }

  useEffect(() => {
    if (company?.imageUrl) {
      setImageFile(company.imageUrl)
    } else {
      setImageFile(null);
    }
  }, [open])


  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setImageFile(URL.createObjectURL(file));
    formik.setFieldValue("tmp", Math.random());
  }

  return (
      <Dialog close={handleClose} isOpen={open} title={"חברה"}>

        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "28px"}}>
          {imageFile ? <img
              width={116}
              height={116}
              src={imageFile}
              loading="eager"
          /> :  <CompanySkeleton/>}

          <Button
              variant="outlined"
              component="label"
              sx={{marginTop: "33px"}}
          >
            העלה תמונה
            <input
                accept="image/png, image/gif, image/jpeg"
                type="file"
                onChange={handleFileUpload}
                hidden
            />
          </Button>
        </Box>
        <form onSubmit={formik.handleSubmit}>
        <Box sx={{display: "flex", marginRight: "100px"}}>
          <Box>
            <Typography sx={{marginTop: "24px"}}>
              *שם חברה:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "10px", width: "280px"}}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.name}
                onChange={formik.handleChange}
                id="name"
                variant="outlined"
            />
            {formik.errors.name && formik.touched.name && <Typography sx={{color: "#F16063"}}>{formik.errors.name}</Typography>}
            <Typography sx={{marginTop: "24px"}}>
              *מייל:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "10px"}}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                id="email"
                variant="outlined"
            />
            {formik.errors.email && formik.touched.email && <Typography sx={{color: "#F16063"}}>{formik.errors.email}</Typography>}
          </Box>
          <Box sx={{marginRight: "80px", paddingLeft: "100px"}}>
            <Typography sx={{marginTop: "24px"}}>
              *טלפון:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "10px", width: "280px"}}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                id="phoneNumber"
                variant="outlined"
            />
            {formik.errors.phoneNumber && formik.touched.phoneNumber && <Typography sx={{color: "#F16063"}}>{formik.errors.phoneNumber}</Typography>}
            <Typography sx={{marginTop: "24px"}}>
              *מיקום:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "10px"}}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.address}
                onChange={formik.handleChange}
                id="address"
                variant="outlined"
            />
            {formik.errors.address && formik.touched.address && <Typography sx={{color: "#F16063"}}>{formik.errors.address}</Typography>}
          </Box>
        </Box>
        <Box sx={{marginTop: "20px", paddingBottom: "40px", textAlign: "center"}}>
          <Button
              onClick={handleClose}
              sx={{marginRight: "24px", backgroundColor: "#F1F3F5", color: "black"}}
              variant={"contained"}>
            בטל
          </Button>
          <Button
              disabled={!formik.dirty || !formik.isValid}
              type={"submit"}
              sx={{marginRight: "24px"}}
              variant={"contained"}>
            שמור
          </Button>
        </Box>
        </form>
      </Dialog>
  )
};

export default AddCompanyDialog;
