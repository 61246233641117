import httpService from "./HttpService";

export const sendToken = async (email) => {
  return httpService.get(`auth/forgotpassword/${email}`, null, null);
}

export const verifyToken = async (token, email) => {
  return httpService.post(`auth/forgotpassword/validate`, {
    email: email,
    token: token
  }, null);
}

export const resetPasswordWithToken = async (token, email, password) => {
  return httpService.post(`auth/forgotpassword/reset`, {
    email: email,
    token: token,
    password: password
  }, null);
}


export async function userLogin(username, password) {
  return httpService.post('auth/login', {username: username, password: password}, null);
}
