import httpService from "./HttpService";

export const getTrainer = async (id) => {
  const token = localStorage.getItem("auth");
  return await httpService.get(
      `admin/trainer/${id}`,
      null,
      token);
};


export const updateTrainer = async (updateRequest) => {
  const token = localStorage.getItem("auth");
  return await httpService.put(
      `admin/trainer`,
      updateRequest,
      null,
      token);
}

export const createTrainer = async (createRequest) => {
  const token = localStorage.getItem("auth");
  return await httpService.post(
      `admin/trainer`,
      createRequest,
      token);
}

export const promotToTrainer = async (userId) => {
  const token = localStorage.getItem("auth");
  return await httpService.put(
      `admin/trainer/` + userId,
      null,
      token);
}

export const enableTrainer = async (id) => {
  const token = localStorage.getItem("auth");
  return await httpService.put(
      `admin/trainer/enable/${id}`,
      null,
      token);
}

export const disableTrainer = async (id) => {
  const token = localStorage.getItem("auth");
  return await httpService.put(
      `admin/trainer/disable/${id}`,
      null,
      token);
}

export const deleteTrainer = async (id) => {
  const token = localStorage.getItem("auth");
  return await httpService.delete(
      `admin/trainer/${id}`,
      null,
      token);
}

export async function getTrainers(companyId) {
  const token = localStorage.getItem("auth");

  let url = `admin/trainer`;

  if (companyId !== "null" && companyId !== undefined){
    url += `?companyId=${companyId}`
  }

  return await httpService.get(
      url,
      null,
      token);
}
