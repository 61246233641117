import {Box, Divider, Paper, TextField, Typography} from "@mui/material";
import {ReactComponent as TemplateIcon} from "../../assets/images/templates.svg";
import {ReactComponent as EditIcon} from "../../assets/images/editProfile.svg";
import {experienceLevels} from "../../enums/ExperienceLevels";
import {targetMuscles} from "../../enums/TargetMuscles";
import {EquipmentTypes} from "../../enums/EquipmentType";
import {mainMuscles} from "../../enums/MainMuscles";
import {SecondaryMuscles} from "../../enums/SecondaryMuscles";
import {TemplateGoals} from "../../enums/TemplateGoals";

const TemplateProfileSection = ({template, setProfileEdit}) => {

  return (
      <Paper sx={{backgroundColor: "#FCFCFD", border: "1.5px solid #F2F4F7",
        flexGrow: 0,
        borderRadius: "4px"}}>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Box sx={{display: "flex", alignItems: "center", marginRight: "8px"}}>
            <TemplateIcon/>
            <Typography sx={{fontWeight: 600,
              fontSize: "20px",
              lineHeight: "27.3px",
              color: "#4F535C",
              padding: "10px"}}>שם פרופיל</Typography>
          </Box>
          <Box sx={{marginLeft: "8px", cursor: "pointer"}}
          onClick={() => setProfileEdit(true)}
          >
            <EditIcon/>
          </Box>
        </Box>
        <Divider/>
        <Box sx={{marginTop: "20px",
          paddingBottom: "45px",
          marginRight: "40px",
          marginLeft: "40px",
          display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
          <Box sx={{display: "flex"}}>
            <Box sx={{minWidth: "166px"}}>
                <Typography sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "25px",
                  color: "#717784"
                }}>פעיל:</Typography>
                <Typography sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "25px",
                  color: "#717784"
                }}>שם תוכנית:</Typography>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>שם תוכנית באנגלית:</Typography>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>מגדר:</Typography>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>רמת מתאמן:</Typography>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#717784"
              }}>מטרה:</Typography>
            </Box>
            <Box>
                <Typography sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "25px",
                  color: "#1E1E1E"
                }}>{template.enabled ? "כן" : "לא"}</Typography>
                <Typography sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "25px",
                  color: "#1E1E1E"
                }}>{template.nameHeb ? template.nameHeb : "לא מוגדר"}</Typography>
                <Typography sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "25px",
                  color: "#1E1E1E"
                }}>{template.name ? template.name : "לא מוגדר"}</Typography>
                <Typography sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "25px",
                  color: "#1E1E1E"
                }}>
                  {template.gender === "FEMALE" && "נקבה"}
                  {template.gender === "MALE" && "זכר"}
                  {!template.gender && "אין מידע"}
                </Typography>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{template.experienceLevel ? experienceLevels.find(lvl => lvl.value === template.experienceLevel)?.name : "לא מוגדר"}</Typography>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "25px",
                color: "#1E1E1E"
              }}>{TemplateGoals[template.goals] || "לא מוגדר"}</Typography>
            </Box>
          </Box>
          <Box sx={{display: "flex"}}>
          <Box sx={{minWidth: "166px"}}>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#717784"
            }}>שריר ראשי:</Typography>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#717784"
            }}>שריר ספציפי:</Typography>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#717784"
            }}>שריר ספציפי 2:</Typography>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#717784"
            }}>ציוד קיים:</Typography>
          </Box>
          <Box>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#1E1E1E"
            }}>{template.mainMuscle ? mainMuscles.find(tm => tm.value === template.mainMuscle)?.name : "לא מוגדר"}</Typography>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#1E1E1E"
            }}>{template.secondaryMuscle ? SecondaryMuscles.find(tm => tm.value === template.secondaryMuscle)?.name : "לא מוגדר"}</Typography>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#1E1E1E"
            }}>{template.secondaryMuscle2 ? SecondaryMuscles.find(tm => tm.value === template.secondaryMuscle2)?.name : "לא מוגדר"}</Typography>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              color: "#1E1E1E"
            }}>{EquipmentTypes.find(et => et.value === template.equipmentType)?.name }</Typography>
          </Box>
          </Box>
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "25px",
              marginBottom: "4px",
              color: "#717784"
            }}>תיאור באנגלית:</Typography>
          <TextField multiline disabled value={template.description}/>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              marginTop: "8px",
              lineHeight: "25px",
              marginBottom: "4px",
              color: "#717784"
            }}>תיאור:</Typography>
            <TextField multiline disabled value={template.descriptionHeb}/>
            <Typography sx={{
              fontSize: "16px",
              fontWeight: 600,
              marginTop: "8px",
              lineHeight: "25px",
              marginBottom: "4px",
              color: "#717784"
            }}>פציעות/הערות:</Typography>
            <TextField multiline disabled value={template.notes}/>
          </Box>
        </Box>
      </Paper>
  );
};

export default TemplateProfileSection;
