import {createTheme} from '@mui/material/styles';

const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      light: '#4F535C',
      main: '#57837E',
      dark: '#425466',
      contrastText: '#FFFFFF',
      error: '#F16063'
    },
    secondary: {
      light: '#E7E9EE',
      main: '#E7E9EE',
      dark: '#E7E9EE',
      contrastText: '#FFFFFF',
      error: '#F16063'
    },
  },
  typography: {
    fontFamily: [
      'Assistant',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default theme;
