import {Divider, Step, StepLabel, Stepper, useMediaQuery} from "@mui/material";
import {RTL} from "../cache/CacheRtl";
import NewClientStep1 from "./NewClientStep1";
import {useEffect, useState} from "react";
import NewClientStep2 from "./NewClientStep2";
import NewClientStep3 from "./NewClientStep3";
import Dialog from "./shared/Dialog";
import {createTrainee} from "../services/TraineeService";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {getErrorMessage} from "../enums/ErrorMessages";
import {useMessageContext} from "../contexts/MessageContext";
import {getUsersWithoutRoles} from "../services/UserService";

const NewClient = ({newClientOpen, closeNewClient}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

  let navigate = useNavigate();

  const {notificationHandler} = useMessageContext();

  const [availableUsers, setAvailableUsers] = useState([]);

  useEffect(() => {
    getUsersWithoutRoles("TRAINEE", null).then(res => {
      setAvailableUsers(res.data);
    })
  }, []);


  const selectUser = (user) => {
    console.log(user.company);
    updateField(user === "null" ? '' : user.name, "name");
    updateField(user === "null" ? '' : user.email, "email");
    updateField(user === "null" ? 'null' : user.gender, "gender");
    updateField(user === "null" ? '' : user.phoneNumber, "phoneNumber");
    updateField(user === "null" ? '' : user.companyId, "companyId");
    updateField(user, "existingUser");
  }

  const [trainee, setTrainee] = useState({
    name: "",
    email: "",
    gender: "null",
    phoneNumber: "",
    dateOfBirth: undefined,
    existingUser: "null",
  });

  const updateField = (value, keyName) => {
    setTrainee(prv => {
      return {
        ...prv, [keyName]: value
      };
    })
  }

  const nextStep = () => {
    setCurrentStep(prv => {
      return prv+1;
    })
  }

  const prvStep = () => {
    setCurrentStep(prv => {
      return prv-1;
    })
  }

  if (isSmallScreen){
    closeNewClient();
  }

  const handleSubmit = () => {
    const body = {
      userId: trainee.existingUser === "null" ? null : trainee.existingUser.id,
      name: trainee.name,
      email: trainee.email,
      password: trainee.password,
      phoneNumber: trainee.phoneNumber,
      gender: trainee.gender !== "null" ? trainee.gender : null,
      dateOfBirth: moment(trainee.dateOfBirth.$d).unix(),
      expirationDate: trainee.expirationDate ?  moment(trainee.expirationDate.$d).unix() : null,
      bodyWeight: trainee.bodyWeight,
      height: trainee.height,
      templateId: trainee.templateId,
      trainerId : trainee.trainerId ? trainee.trainerId : null,
    }

    setTrainee({});

    createTrainee(body, trainee.companyId ? trainee.companyId : null)
        .then(r => navigate(`/app/clients/${r.data.id}`))
        .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)));
  }

  return (
      <Dialog isOpen={newClientOpen} close={closeNewClient} title={"הוסף לקוח חדש"}>
        <RTL>
             <Stepper activeStep={currentStep} connector={null} sx={{display: "flex",justifyContent: "center", height: "60px"}}>
               <Step key={1} completed={currentStep > 0}>
                 <StepLabel>פרטים אישיים</StepLabel>
               </Step>
               <Step key={2} completed={currentStep > 1} sx={{marginLeft: "90px"}}>
                 <StepLabel>מידע</StepLabel>
               </Step>
               <Step key={3} sx={{marginLeft: "90px"}}>
                 <StepLabel>סיכום ויצירה</StepLabel>
               </Step>
             </Stepper>
             </RTL>
        <Divider/>
        {currentStep === 0 && <NewClientStep1 availableUsers={availableUsers} selectUser={selectUser} trainee={trainee} updateField={updateField} nextStep={nextStep} closeNewClient={closeNewClient}/>}
        {currentStep === 1 && <NewClientStep2 trainee={trainee} updateField={updateField} nextStep={nextStep} prvStep={prvStep} closeNewClient={closeNewClient}/>}
        {currentStep === 2 && <NewClientStep3 handleSubmit={handleSubmit} trainee={trainee} updateField={updateField}  prvStep={prvStep} closeNewClient={closeNewClient}/>}
      </Dialog>
  );
};

export default NewClient;
